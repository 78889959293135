import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { HOME } from '../../utils/icons';

export const SocialMediaButton = styled.a`
  height: 40px;
  line-height: 40px;
  min-width: 40px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  backface-visibility: hidden;
  transition: box-shadow 0.2s ease, border-color 0.2s ease, opacity 0.3s;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px;
  font-size: 0;
  border-radius: 4px;
  opacity: 1;
  margin: 6px;
  background-color: ${({ color }) => color || 'white'};
  &:hover {
    box-shadow: none;
    border-color: transparent;
    background-color: black;
  }
`;

export const SocialMediaIcon = styled.span`
  background: rgba(255, 255, 255, 0.1);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 12px;
  transition: all 0.2s ease;
  z-index: 2;
`;

export const SocialMediaText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  padding: 0 10px 0 44px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  box-sizing: border-box;
  transition: all 0.2s ease;
  backface-visibility: hidden;
  position: relative;
  z-index: 2;
  color: #fff;
`;

export const SocialMediaIconsContainer = styled.div`
  display: flex;
  margin: 10px 0px;
`;

const NotFound = () => {
  const history = useHistory();
  const handleLinkClick = useCallback(() => {
    history.push('/');
  }, []);

  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
          <h2>Page not found</h2>
          <div>
            <div>
              <SocialMediaButton color="darkorchid" onClick={handleLinkClick}>
                <SocialMediaIcon>
                  <HOME />
                </SocialMediaIcon>
                <SocialMediaText>Go To Home</SocialMediaText>
              </SocialMediaButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
