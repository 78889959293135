import { remove } from 'ramda';
import {
  AUTH_RESET_CART,
  AUTH_ADD_TO_CART,
  AUTH_REMOVE_FROM_CART,
  AUTH_SET_TO_CART,
} from '../actions/authcart';

const initialState = {
  cart: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_RESET_CART:
      return {
        ...state,
        cart: [],
      };
    case AUTH_ADD_TO_CART:
      return {
        ...state,
        cart: action.payload
          ? [...state.cart, action.payload]
          : [...state.cart],
      };
    case AUTH_REMOVE_FROM_CART:
      return {
        ...state,
        cart: remove(action.payload, 1, state.cart) || null,
      };
    case AUTH_SET_TO_CART:
      return {
        ...state,
        cart: action.payload || [],
      };
    default:
      return state;
  }
}

export default reducer;
