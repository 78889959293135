import {
  SET_LESSONS,
  SET_LAST_WATCHED_LESSON,
  SET_CURRENT_LESSON,
  UPDATE_LESSON_WATCH_TIME,
  REMOVE_NEW_FROM_LESSON,
  TOGGLE_PLAYER_RENDERING,
} from '../actions/lessons';

const initialState = {
  allLessons: [],
  lastWatchedLesson: null,
  currentLesson: null,
  showPlayer: true,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LESSONS:
      return {
        ...state,
        allLessons: action.payload,
      };
    case SET_LAST_WATCHED_LESSON:
      return {
        ...state,
        lastWatchedLesson: action.payload,
      };
    case SET_CURRENT_LESSON:
      return {
        ...state,
        currentLesson: action.payload,
      };
    case UPDATE_LESSON_WATCH_TIME:
      const lesson = action.payload;
      const updatedLessons = state.allLessons.map((section) => {
        if (section._id === lesson.section._id) {
          const updatedLessons = section.lessons;
          const lessonIndex = updatedLessons.findIndex(
            (x) => x._id === lesson._id,
          );
          updatedLessons[lessonIndex] = lesson;
          return {
            ...section,
            lessons: updatedLessons,
          };
        }
        return section;
      });
      return {
        ...state,
        allLessons: updatedLessons,
      };
    case REMOVE_NEW_FROM_LESSON:
      const { lesson: newLesson, sectionIndex, lessonIndex } = action.payload;
      const allOldLessons = state.allLessons;
      if (allOldLessons[sectionIndex]) {
        allOldLessons[sectionIndex].lessons[lessonIndex] = newLesson;
      }
      return {
        ...state,
        allLessons: allOldLessons,
      };
    case TOGGLE_PLAYER_RENDERING:
      return {
        ...state,
        showPlayer: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
