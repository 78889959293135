import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./routes/home";
import About from "./routes/about";
import Login from "./components/Login";
import ContactUs from "./routes/contactUs";
import AllQueries from "./routes/allQueries";
import AllUsers from "./routes/allUsers";
import MyProfile from "./routes/myProfile";
import PrivacyPolicy from "./routes/privacyPolicy";
import Performance from "./routes/performance";
import FAQS from "./routes/faqs";
import RefundPolicy from "./routes/refundPolicy";
import Disclaimer from "./routes/disclaimer";
import TermsConditions from "./routes/termsConditions";
import ResetPassword from "./routes/resetPassword";
import NotFound from "./routes/notFound";
import Dashboard from "./routes/dashboard";
import AdminDashboard from "./routes/adminDashboard";
import Cart from "./routes/cart";
import Authcart from "./routes/authcart";
import Courses from "./routes/courses";
import Checkout from "./routes/checkout";
import Lessons from "./routes/lessonsByCourse";
import ReviewLessonQA from "./routes/reviewLessonQA/reviewLessonQA";
import Curriculum from "./routes/curriculum";
import AllLiveQaUsers from "./routes/allLiveQAUsers";
import ThankYou from "./routes/thankYou";
import AccountCreated from "./routes/accountCreated";
import Notifications from "./routes/notifications";
import AllRatings from "./routes/allRatings";
import PaymentStatus from "./routes/paymentStatus";
import LandingPage from "./routes/landingPage";
import Webinar from "./routes/webinar";
import WebinarRegister from "./routes/webinarRegister";
import RegisterThankYou from "./routes/registerThankYou";
import WebinarRegistered from "./routes/webinarRegistered";
import PaymentStatusWebinar from "./routes/paymentStatusWebinar";
import WebinarRecording from "./routes/webinarRecording";
import Enquiry from "./routes/enquiry";

function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={() =>
          localStorage.getItem("authToken") ? <Dashboard /> : <Home />
        }
      />
      <Route exact path="/login" component={() => <Login />} />
      <Route exact path="/about-us" component={() => <About />} />
      <Route exact path="/contact-us" component={() => <ContactUs />} />
      <Route exact path="/enroll" component={() => <Enquiry />} />
      <Route exact path="/privacy-policy" component={() => <PrivacyPolicy />} />
      <Route exact path="/faqs" component={() => <FAQS />} />
      <Route exact path="/our-performance" component={() => <Performance />} />
      <Route
        exact
        path="/reset-password"
        component={() =>
          !localStorage.getItem("authToken") ? (
            <ResetPassword />
          ) : (
            <Redirect to="/" />
          )
        }
      />
      {/* <Route
        exact
        path="/cart"
        component={() =>
          !localStorage.getItem("authToken") ? <Cart /> : <Redirect to="/" />
        }
      /> */}
      <Route exact path="/disclaimer" component={() => <Disclaimer />} />
      <Route exact path="/refund-policy" component={() => <RefundPolicy />} />
      <Route
        exact
        path="/courses"
        component={() =>
          !localStorage.getItem("authToken") ? <Courses /> : <Redirect to="/" />
        }
      />
      <Route
        exact
        path="/curriculum/:slug"
        component={() =>
          !localStorage.getItem("authToken") ? (
            <Curriculum />
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route
        exact
        path="/terms-and-conditions"
        component={() => <TermsConditions />}
      />
      <Route
        exact
        path="/auth-cart"
        component={() =>
          localStorage.getItem("authToken") ? <Authcart /> : <Redirect to="/" />
        }
      />
      <Route
        exact
        path="/all-queries"
        component={() =>
          localStorage.getItem("authToken") ? (
            <AllQueries />
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route
        exact
        path="/all-users"
        component={() =>
          localStorage.getItem("authToken") ? <AllUsers /> : <Redirect to="/" />
        }
      />
      <Route
        exact
        path="/all-ratings"
        component={() =>
          localStorage.getItem("authToken") ? (
            <AllRatings />
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route
        exact
        path="/all-live-qa-users"
        component={() =>
          localStorage.getItem("authToken") ? (
            <AllLiveQaUsers />
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route exact path="/thank-you" component={() => <ThankYou />} />
      <Route
        exact
        path="/account-created"
        component={() => <AccountCreated />}
      />
      <Route
        exact
        path="/webinar-registered"
        component={() => <WebinarRegistered />}
      />
      <Route exact path="/notifications" component={() => <Notifications />} />
      <Route
        exact
        path="/profile"
        component={() =>
          localStorage.getItem("authToken") ? (
            <MyProfile />
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route
        exact
        path="/dashboard"
        component={() =>
          localStorage.getItem("authToken") ? (
            <Dashboard />
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route
        exact
        path="/courses/:slug"
        component={() =>
          localStorage.getItem("authToken") ? <Lessons /> : <Redirect to="/" />
        }
      />
      <Route
        exact
        path="/admin-dashboard"
        component={() =>
          localStorage.getItem("authToken") ? (
            <AdminDashboard />
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route
        exact
        path="/review-qa"
        component={() =>
          localStorage.getItem("authToken") ? (
            <ReviewLessonQA />
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route
        exact
        path="/checkout"
        component={() =>
          !localStorage.getItem("authToken") ? (
            <Checkout />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />
      <Route
        exact
        path="/payment-status"
        component={() =>
          !localStorage.getItem("authToken") ? (
            <PaymentStatus />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />
      <Route
        exact
        path="/payment-status-webinar"
        component={() =>
          !localStorage.getItem("authToken") ? (
            <PaymentStatusWebinar />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />
      {/* <Route
        exact
        path="/landing-page"
        component={() => (!localStorage.getItem('authToken') ? (
          <LandingPage />
        ) : (
          <Redirect to="/dashboard" />
        ))}
      /> */}
      <Route
        exact
        path="/landing-page"
        component={() =>
          !localStorage.getItem("authToken") ? (
            <Webinar />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />
      <Route
        exact
        path="/webinar"
        component={() =>
          !localStorage.getItem("authToken") ? (
            <Webinar />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />
      <Route
        exact
        path="/webinar-registration"
        component={() =>
          !localStorage.getItem("authToken") ? (
            <WebinarRegister />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />
      <Route
        exact
        path="/webinar-register-success"
        component={() =>
          !localStorage.getItem("authToken") ? (
            <RegisterThankYou />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />
      <Route
        exact
        path="/webinar-recording"
        component={() => <WebinarRecording />}
      />
      <Route path="/404" component={() => <NotFound />} />
      <Redirect to="/404" />
    </Switch>
  );
}

export default Routes;
