import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { ImageDiv } from '../FeaturedOnSection';
import { ColoredContainer } from './Common';
import logo1 from '../../assets/LOGOS-01.svg';
import logo2 from '../../assets/LOGOS-02.svg';
import logo3 from '../../assets/LOGOS-03.svg';
import logo4 from '../../assets/LOGOS-04.svg';
import logo5 from '../../assets/LOGOS-05.svg';
import logo6 from '../../assets/LOGOS-06.svg';
import logo7 from '../../assets/LOGOS-07.svg';
import logo8 from '../../assets/LOGOS-08.svg';

const FeaturedOn = () => (!isMobile ? (
  <GrayColoredContainer>
    <Container>
      <TagContainer />
      <FeaturedImagesParent>
        <div>
          <Tag>Featured On</Tag>
        </div>
        <Row>
          <Col span={6}>
            <ImageDiv>
              <StyledImage src={logo1} />
            </ImageDiv>
          </Col>
          <Col span={6}>
            <ImageDiv>
              <StyledImage src={logo2} />
            </ImageDiv>
          </Col>
          <Col span={6}>
            <ImageDiv>
              <StyledImage src={logo3} />
            </ImageDiv>
          </Col>
          <Col span={6}>
            <ImageDiv>
              <StyledImage src={logo4} />
            </ImageDiv>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <ImageDiv>
              <StyledImage src={logo5} />
            </ImageDiv>
          </Col>
          <Col span={6}>
            <ImageDiv>
              <StyledImage src={logo6} />
            </ImageDiv>
          </Col>

          <Col span={6}>
            <ImageDiv>
              <StyledImage src={logo7} />
            </ImageDiv>
          </Col>
          <Col span={6}>
            <ImageDiv>
              <StyledImage src={logo8} />
            </ImageDiv>
          </Col>
        </Row>
      </FeaturedImagesParent>
    </Container>
  </GrayColoredContainer>
) : (
  <BlueColoredContainer>
    <Container
      style={{
        backgroundImage: 'linear-gradient(to right, #141e30, #243b55)',
      }}
    >
      <TagContainer>
        <MobileTag>Featured On</MobileTag>
      </TagContainer>
      <FlexBox>
        <MobileBox>
          <MobileImageDiv>
            <StyledImage src={logo1} />
          </MobileImageDiv>
        </MobileBox>
        <MobileBox>
          <MobileImageDiv>
            <StyledImage src={logo2} />
          </MobileImageDiv>
        </MobileBox>
        <MobileBox>
          <MobileImageDiv>
            <StyledImage src={logo3} />
          </MobileImageDiv>
        </MobileBox>
        <MobileBox>
          <MobileImageDiv>
            <StyledImage src={logo4} />
          </MobileImageDiv>
        </MobileBox>
      </FlexBox>
      <FlexBox>
        <MobileBox>
          <MobileImageDiv>
            <StyledImage src={logo5} />
          </MobileImageDiv>
        </MobileBox>
        <MobileBox>
          <MobileImageDiv>
            <StyledImage src={logo6} />
          </MobileImageDiv>
        </MobileBox>
        <MobileBox>
          <MobileImageDiv>
            <StyledImage src={logo7} />
          </MobileImageDiv>
        </MobileBox>
        <MobileBox>
          <MobileImageDiv>
            <StyledImage src={logo8} />
          </MobileImageDiv>
        </MobileBox>
      </FlexBox>
    </Container>
  </BlueColoredContainer>
));

export default FeaturedOn;

const GrayColoredContainer = styled(ColoredContainer)`
  background: white;
  min-height: inherit;
  padding-top: 0;
  position: relative;
  min-height: 250px;
`;

const BlueColoredContainer = styled(ColoredContainer)`
  min-height: inherit;
  padding-top: 0;
`;

const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  background-image: ${isMobile
    ? 'linear-gradient(to right,#141e30,#243b55)'
    : 'none'};
`;

const Tag = styled.div`
  width: 150px;
  text-align: center;
  background: white;
  padding: 15px;
  color: black;
  font-weight: bold;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  font-size: 15px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -55px;
`;

export const StyledImage = styled.img`
  width: 105px;
  @media (max-width: 767px) {
    width: 80px;
  }
`;

export const FeaturedImagesParent = styled.div`
  background-image: linear-gradient(to right, #141e30, #243b55);
  position: absolute;
  border-radius: 8px;
  top: -30px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  z-index: 1;
  padding: 15px 0;
`;

const MobileTag = styled.div`
  width: 150px;
  text-align: center;
  background: white;
  padding: 15px;
  color: black;
  font-weight: bold;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  font-size: 15px;
`;

const MobileBox = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  margin: 5px;
  flex-direction: column;
  flex: 1;
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const MobileImageDiv = styled(ImageDiv)`
  height: 80px;
`;
