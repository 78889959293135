import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as R from 'ramda';
import {
  Col, Row, notification, Button,
} from 'antd';
import { Container } from 'react-bootstrap';
import HtmlParser from 'react-html-parser';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { axiosAuth } from '../../api';
import HeadingUnderline from '../../components/HeadingUnderline';
import { Spacer } from '../about';

const ReviewLessonQA = () => {
  const history = useHistory();
  const user = useSelector((state) => state.common.user);
  const [loading, setLoading] = useState(false);
  const [loadingAcceptReject, setLoadingAcceptReject] = useState(false);
  const [pendingPosts, setPendingPosts] = useState([]);
  const [reason, setReason] = useState('');
  useEffect(() => {
    if (!R.isEmpty(user) && user?.userType != 'admin') {
      history.push('/');
    }
  }, [user]);

  useEffect(() => {
    setLoading(true);
    axiosAuth
      .get(API_ENDPOINTS.GET_ALL_PENDING_QAS)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          if (res?.data?.data.length > 0) {
            setPendingPosts(res?.data?.data);
          }
        } else {
          notification.error({
            message: 'Error',
            description: 'Failed to load posts. Please try again later!!',
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleAcceptReject = (item, status) => {
    setLoadingAcceptReject(item?._id);
    axiosAuth
      .post(API_ENDPOINTS.CHANGE_QA_STATUS, {
        commentId: item._id,
        status,
        notification: {
          title: status === 'approved' ? 'Your comment is approved!!' : 'Your comment is rejected!!',
          body: status === 'approved'
            ? `Course: ${item?.course?.course_name} | Section: ${item?.sectionId?.post_title} | Lesson: ${item?.lessonId?.post_title}`
            : `Reject Reason: ${reason} | Course: | Section: ${item?.sectionId?.post_title} | Lesson: ${item?.lessonId?.post_title}`,
        },
      })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          notification.success({
            message: 'Success',
            description:
              status === 'approved' ? 'QA Approved!!' : 'QA Rejected!!',
          });
          setReason(null);
          const itemIndex = pendingPosts.findIndex(
            (data) => data._id === item._id,
          );
          if (itemIndex > -1) {
            const updatedData = R.remove(itemIndex, 1, pendingPosts);
            setPendingPosts(updatedData);
          }
        } else {
          notification.error({
            message: 'Error',
            description: 'Failed to update post. Please try again later!!',
          });
        }
        setLoadingAcceptReject('');
      })
      .catch(() => {
        setLoadingAcceptReject('');
      });
  };

  return (
    <div>
      <Container>
        <Spacer />
        <HeadingUnderline title="Pending QA" />
        <Row>
          <Col span={24}>
            {loading ? (
              <>Loading...</>
            ) : (
              <>
                <Row>
                  <p style={{ marginBottom: '5px' }}>
                    Reason to Reject
                  </p>
                    &nbsp;&nbsp;
                  <Button style={{ marginBottom: '5px' }} onClick={() => setReason('')}>Clear</Button>
                  <TextArea placeholder="Enter reason to reject" rows={2} onChange={(e) => setReason(e.target.value)} value={reason} />
                </Row>
                <Spacer />
                {pendingPosts?.map((item) => {
                  const isLoading = item?._id === loadingAcceptReject;
                  return (
                    <QAItem>
                      <div>{HtmlParser(item?.content)}</div>
                      <div>
                        Posted by:
                        {' '}
                        {item?.commentedBy?.name}
                      </div>
                      <div>
                        Course:
                        {' '}
                        {item?.course?.course_name}
                        {' '}
                        | Lesson:
                        {' '}
                        {item?.lessonId?.post_title}
                        {' '}
                        | Section:
                        {' '}
                        {item?.sectionId?.post_title}
                      </div>
                      <ActionContainer>
                        <Button
                          loading={isLoading}
                          disabled={isLoading}
                          onClick={() => handleAcceptReject(item, 'approved')}
                          type="primary"
                        >
                          Approve
                        </Button>
                        <Button
                          loading={isLoading}
                          disabled={isLoading}
                          onClick={() => handleAcceptReject(item, 'reject')}
                          style={{ marginLeft: '15px' }}
                          danger
                        >
                          Reject
                        </Button>
                      </ActionContainer>
                    </QAItem>
                  );
                })}
              </>
            )}
            {!loading && pendingPosts?.length === 0 && (
              <Row justify="center">
                <p className="mb-20">No QA Found</p>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReviewLessonQA;

const QAItem = styled.div`
  border: 1px solid #d1d7dc !important;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
`;

export const ActionContainer = styled.div`
  margin-top: 15px;
`;
