import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import HeadingUnderline from '../HeadingUnderline';

const CouponModal = ({ isVisible, handleClose }) => {
  const profile = useSelector((state) => state.common.user);
  const { coupon } = profile || {};

  const copyToClipboard = () => {
    navigator.clipboard.writeText(coupon?.code);
    toast('Coupon code copied to clipboard', {
      type: toast.TYPE.SUCCESS,
    });
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={handleClose}
      footer={null}
      className="telegram-popup gift-card-modal"
    >
      <CloseCircleOutlined
        className="close-icon"
        onClick={handleClose}
        style={{ color: 'white' }}
      />
      <Container>
        <HeadingUnderline title="Congratulations" whitetext />
        <DiscountPercentage>
          {coupon?.discount}
          % discount
          <br />
        </DiscountPercentage>
        <CodeDiv>
          <YourCode>Coupon Code</YourCode>
          <CouponCodeText onClick={copyToClipboard} id="coupon-code">{coupon?.code}</CouponCodeText>
        </CodeDiv>
        <Row justify="center">
          {coupon?.isUsed
            ? <WebText>Coupon already used</WebText> : (
              <Button
                style={{ marginTop: '5px' }}
                className="signUpBtn"
                onClick={copyToClipboard}
              >
                Click to Copy
              </Button>
            )}
        </Row>
        {!coupon?.isUsed && (
        <>
          <ValidityContainer>
            <YourCode>
              Valid till:
            </YourCode>
            {' '}
            <ValidityText>
              {moment(coupon?.expiry).format('DD MMM YYYY')}
            </ValidityText>
          </ValidityContainer>
          <WebText>
            You can use this coupon code to subscribe to
            <br />
            <a target="_blank" href="https://thelogicaltraders.com" rel="noreferrer">The Logical Traders</a>
            {' '}
            platform
          </WebText>
        </>
        )}
      </Container>
    </Modal>
  );
};

export default CouponModal;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  position: relative;
`;

const DiscountPercentage = styled.div`
  padding: 20px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: linear-gradient(179.59deg, #FFF0CD 59.9%, rgba(255, 255, 255, 0) 99.64%), linear-gradient(180deg, #F9AE00 0%, rgba(255, 255, 255, 0) 68.75%), #F9AE00 !important;
  color: black;
  font-size: 25px;
  font-weight: bold;
  min-width: 230px;
  text-align: center;
`;

const CodeDiv = styled.div`
  width: 230px;
  display: flex;
  justify-content: space-between;
  box-shadow: -1px 6px 13px 0px rgb(0 0 0 / 44%);
  padding: 5px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: white;
`;

const YourCode = styled.span`
  font-weight: bold;
`;

const CouponCodeText = styled.span`
  font-weight: bold;
  color: #F9AE00;
  cursor: pointer;
`;

const ValidityContainer = styled.div`
  margin-top: 15px;
  color: white;
`;

const ValidityText = styled.span`
  color: #E00101;
  font-weight: bold;
`;

const Amount = styled.span`
  font-size: 18px;
`;

const WebText = styled.p`
  color: white;
  margin-top: 5px;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  margin-bottom: 0;
`;
