import React from 'react';
import { Col } from 'antd';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import DiscountImg from '../../assets/discount.svg';

const CardsParent = styled.div`
  display: flex;
  background: #f6effe;
  border-radius: 10px;
  margin: 0 15px 50px 15px;
  padding: 10px;
  height: 100%;
  width: 500px;
  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 40px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: inherit;
  }
`;

const CardImage = styled.img`
  height: 80px;
  width: 80px;
`;

const Flex1Div = styled.div`
  flex: 1;
`;

const CardDiscountTextParent = styled.ul`
  padding: 0;
  margin: 0;
  li:first-child {
    font-weight: 600;
    font-size: 16px;
    color: #0c0e0f;
    line-height: 15px;
    margin-top: 15px;
  }
  li {
    list-style-type: none;
  }
`;

const RedDiscountBigText = styled.span`
  font-weight: bold;
  font-size: 32px;
  color: #de4c3c;
  margin-left: 5px;
`;

const ModifiedDiscountRedText = styled(RedDiscountBigText)`
  margin-top: -20px;
  margin-left: -2px;
  line-height: 40px;
`;

const MobileRedDiscountBigText = styled(RedDiscountBigText)`
  font-size: 22px;
`;

const DiscountRedContentUL = styled.ul`
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    font-weight: 500;
    font-size: 15px;
    color: #0c0e0f;
  }
`;

const Flex1DivImage = styled(Flex1Div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Flex1DivDiscount = styled(Flex1Div)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -4px;
`;

const Flex1DivDiscountContent = styled(Flex1Div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RedText = styled.span`
  color: #de4c3c;
`;

const MobileDiscountDiv = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
`;

const MobileDiscountUL = styled.ul`
  padding: 0;
  margin-bottom: 0;
  li {
    list-style-type: none;
    font-weight: 500;
  }
`;

const DiscountCards = () => (
  <Col span={24}>
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <CardsParent>
        <Flex1DivImage>
          <CardImage src={DiscountImg} />
        </Flex1DivImage>
        {!isMobileOnly ? (
          <>
            <Flex1DivDiscount>
              <CardDiscountTextParent>
                <li>
                  Exclusive
                  {' '}
                  <RedDiscountBigText>20%</RedDiscountBigText>
                </li>
                <li>
                  <ModifiedDiscountRedText>
                    DISCOUNT
                  </ModifiedDiscountRedText>
                </li>
              </CardDiscountTextParent>
            </Flex1DivDiscount>
            <Flex1DivDiscountContent>
              <DiscountRedContentUL>
                <li>
                  for the
                  {' '}
                  <RedText>next 100</RedText>
                </li>
                <li>
                  <RedText>customers</RedText>
                  {' '}
                  only
                </li>
              </DiscountRedContentUL>
            </Flex1DivDiscountContent>
          </>
        ) : (
          <MobileDiscountDiv>
            <MobileDiscountUL>
              <li>
                Exclusive
                {' '}
                <MobileRedDiscountBigText>
                  20% DISCOUNT
                </MobileRedDiscountBigText>
              </li>
              <li>
                for the
                {' '}
                <RedText>next 100 customers</RedText>
                {' '}
                only
              </li>
            </MobileDiscountUL>
          </MobileDiscountDiv>
        )}
      </CardsParent>
    </div>
  </Col>

);

export default DiscountCards;
