import React, { useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Col, Collapse, Row } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { isUserLoggedIn } from "../../utils/helper";
import HeadingUnderline from "../../components/HeadingUnderline";
import BuyCourseButton from "../../components/BuyCourseButton";

const { Panel } = Collapse;

const Spacer = styled.div`
  height: 20px;
`;

const FAQS = ({ landingPage }) => {
  const [_, changeKey] = useState("");
  const handleFaqChange = (key) => {
    changeKey(key);
  };

  return (
    <Container>
      {!landingPage && (
        <>
          <Spacer />
          <HeadingUnderline title="FAQs" />
        </>
      )}
      <Collapse
        className="site-collapse-custom-collapse"
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        onChange={handleFaqChange}
      >
        <Panel
          className="site-collapse-custom-panel"
          header="What are the conditions for the money-back guarantee?"
          key="51"
        >
          <p>
            We offer money-back guarantee to people who take the “Profitable
            Trading Masterclass” course under either of the two following
            conditions:
          </p>
          <ol>
            <li>
              <p>
                If any user can prove that all the advanced secrets and four
                strategies that we’ve discussed in the section 8 of our course
                are not “new”, meaning they were already available on the
                Internet (or in published books) prior to 15th June, then the
                user will be entitled to get 100% of their money-back. The
                money-back guarantee will not apply if the resources shared
                (YouTube videos, prominent website links, books) to prove the
                claim that our strategies and secrets are not new, do not have a
                verifiable date of publishing or if they were published after
                15th June 2022.
              </p>
            </li>
            <li>
              <p>
                We claim that we’ve made a gross profit of over Rs. 80 lakhs on
                a capital of less than Rs. 2 lakhs in 30 months & we will prove
                our claims by providing the user with the following:
              </p>
              <ul>
                <li>
                  We will share the Profit & Loss statement of our Zerodha
                  trading account (XL2413) from 1st January 2020 to 31 Dec 2023
                  of the futures & options and commodities segment in the
                  course.
                </li>
                <li>
                  Users will also be able to download our Account Statement
                  Excel file from 1st January 2020 to 31 Dec 2023.
                </li>
                <li>
                  We will provide 360-day Zerodha verified Profit & Loss link
                </li>
              </ul>
              <br />
              If we don’t provide the aforementioned proof, the user will be
              entitled to the money back guarantee.
              <br />
              The money-back guarantee will be valid for 3 months after the date
              of purchase even after the expiry of the refund period as long as
              one of the two aforementioned conditions are met.
            </li>
          </ol>
          <p>
            The money-back guarantee will be valid for 3 months after the date
            of purchase even after the expiry of refund period as long as one of
            the two aforementioned conditions are met.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="I am a beginner in the stock market. Will this course be beneficial for me?"
          key="52"
        >
          <p>
            Yes, our course covers the basics as well as many advanced concepts.
            It is designed in such a way that even a person with zero experience
            can grasp all the topics discussed in it.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="I have taken other courses. Will this course help me?"
          key="62"
        >
          <p>
            Absolutely! The topics we’ve covered in the course can’t be found on
            any other course or even free resources available online.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="How is this course different from other stock market courses?"
          key="55"
        >
          <p>Our course is different in several ways.</p>
          <ol>
            <li>
              <p>
                It consists 4 of our advanced strategies and several indicators
                that have helped us make over Rs 40 Lakhs in the Indian stock
                market.
              </p>
            </li>
            <li>
              <p>
                It’s been created by experts who have verifiable track record of
                profitability of over 15 years in the Indian & the U.S. markets.
              </p>
            </li>
            <li>
              <p>It consists of 35 of our key trading secrets.</p>
            </li>
          </ol>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="Is this course for options trader?"
          key="56"
        >
          <p>
            Yes. The strategies covered in the course are very flexible and can
            be used for trading in futures & options.
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="What is the duration of the course?"
          key="58"
        >
          <p>
            The pre-recorded videos in the course span over 16 hours whereas to
            go through all the third-party resources that we provide in the
            course, you will need to spend over 50 hours.
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="Is the course for investors or only for traders?"
          key="60"
        >
          <p>
            We believe this course will help both investors & traders make
            better financial decision and build wealth in the stock markets.
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="I can’t login to watch the course. What should I do?"
          key="61"
        >
          <p>
            You can use the “Forgot Password” link on the login page to reset
            your password. If you’re still facing problems, you can reach out to
            us on support@thelogicaltraders.com
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="Who is this course for?"
          key="62"
        >
          <p>
            The course is designed for traders. But anyone who has an interest
            in the stock markets and wants to shorten their learning curve can
            take it.
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="What is the validity of the course?"
          key="63"
        >
          <p>
            You can access the course for 12 months since the date of the
            purchase.
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="How can I buy the course?"
          key="64"
        >
          <p>
            You can click on any of the “Become A Profitable Trader Now!” button
            on the website and then proceed to the checkout page. You will then
            need to create an account by putting in your credentials and
            complete the payment process to get access to the course.
          </p>
        </Panel>
      </Collapse>
      <Spacer />
      {!isUserLoggedIn() && !landingPage && (
        <Row justify="center" className="blogs-brief">
          <Col span="24">
            <h5 className="text-center">
              Got your doubts cleared? Great. Join us today.
            </h5>
          </Col>
          <Col className="text-center" span="24">
            <BuyCourseButton />
          </Col>
        </Row>
      )}
      {!landingPage && (
        <>
          <Spacer />
          <Spacer />
          <h6>
            <em>
              Still have doubts? Feel free to{" "}
              <a href="mailto:support@thelogicaltraders.com?subject=Query">
                contact us by email
              </a>
              , or drop us a text on WhatsApp by clicking the button on the
              bottom right corner of the page.
            </em>
          </h6>
          <Spacer />
        </>
      )}
    </Container>
  );
};

export default FAQS;
