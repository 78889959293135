import React from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { addToCart } from "../../actions/cart";
import { courseAddedToCart } from "../../utils/helper";
import { COURSE_PLAN_DETAILS } from "../BuyCourseButton";

const ActionButton = () => {
  const coursesInCart = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCourseClick = () => {
    // const isCourseInCart = coursesInCart.findIndex(
    //   (x) => x._id === COURSE_PLAN_DETAILS?._id,
    // );
    // if (isCourseInCart < 0) {
    //   const localCartData = localStorage.getItem('cartData');
    //   const validCartData = localCartData ? JSON.parse(localCartData) : [];
    //   const localCartDataIndex = validCartData.findIndex(
    //     (x) => x._id === COURSE_PLAN_DETAILS._id,
    //   );
    //   if (localCartDataIndex < 0) {
    //     validCartData.push(COURSE_PLAN_DETAILS);
    //   }
    //   localStorage.setItem('cartData', JSON.stringify(validCartData));
    //   dispatch(addToCart(COURSE_PLAN_DETAILS));
    //   if (isCourseInCart === -1) {
    //     courseAddedToCart(COURSE_PLAN_DETAILS.course_name);
    //   }
    // }
    // history.push('/cart?header=false');
    history.push("/enroll");
  };
  return <></>;
  return (
    <button
      onClick={handleCourseClick}
      type="button"
      className={`button-85 ${isMobile ? "button-85-mobile" : ""}`}
    >
      Become A Profitable Trader Now!
    </button>
  );
};

export default ActionButton;
