import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import HeadingUnderline from "../../components/HeadingUnderline";
import refundBadge from "../../assets/refund-badge.png";

const Spacer = styled.div`
  height: 20px;
`;

const RefundPolicy = () => (
  <>
    <Helmet>
      <title>The Logical Traders offer a 2-day Refund</title>
      <meta
        name="description"
        content="The Logical Traders Course refund policy, The Logical Traders refund, The Logical Traders review, The Logical Traders money back guarantee,"
      />
    </Helmet>
    <Container>
      <Spacer />
      <HeadingUnderline title="Refund policy" />
      {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={refundBadge} alt="refund" height="150px" />
      </div> */}
      <Spacer />
      <p>
        If you purchase a course from our website, you are entitled to a refund
        within 48 hours from the time of purchase UNLESS you decide to view (or
        unlock) any of the locked videos in the course for which you are asking
        for a refund. If you decide to unlock the locked videos in any course,
        for any purpose, you will not be eligible for a refund for that
        particular course. Whenever you decide to view any of our locked videos
        for the first time, you will get a pop up dialog box asking you for
        confirmation to unlock the entire course. If you click on "Yes", the
        entire course will be unlocked and you will not be eligible for a refund
        thereafter.
      </p>
      {/* <p>
        We will not be refunding the convenience fees & GST charged by your
        bank or merchant (in case of EMI or any other mode of payment).
      </p> */}
      <Spacer />
      <h5>
        <strong>MONEY-BACK T&C</strong>
      </h5>
      <p>
        We offer money-back guarantee to people who take the “Profitable Trading
        Masterclass” course under either of the two following conditions:
      </p>
      <p>
        1) If any user can prove that all the advanced secrets and four
        strategies that we’ve discussed in the section 8 of our course are not
        “new”, meaning they were already available on the Internet (or in
        published books) prior to 15th June, then the user will be entitled to
        get 100% of their money-back. The money-back guarantee will not apply if
        the resources shared (YouTube videos, prominent website links, books) to
        prove the claim that our strategies and secrets are not new, do not have
        a verifiable date of publishing or if they were published after 15th
        June 2022.
      </p>
      <p>
        2) We claim that we’ve made a gross profit of over Rs. 80 lakhs on a
        capital of less than Rs. 2 lakhs in 30 months & we will prove our claims
        by providing the user with the following:
      </p>
      <ul>
        <li>
          We will share the Profit & Loss statement of our Zerodha trading
          account (XL2413) from 1st January 2020 to 31 Dec 2023 of the futures &
          options and commodities segment in the course.{" "}
        </li>
        <li>
          Users will also be able to download our Account Statement Excel file
          from 1st January 2020 to 31 Dec 2023.
        </li>
        <li>We will provide 360-day Zerodha verified Profit & Loss link</li>
      </ul>
      <p>
        If we don’t provide the aforementioned proof, the user will be entitled
        to the money back guarantee.
      </p>
      <p>
        The money-back guarantee will be valid for 3 months after the date of
        purchase even after the expiry of the refund period as long as one of
        the two aforementioned conditions are met.
      </p>
      <p>
        The money-back guarantee will be valid for 3 months after the date of
        purchase even after the expiry of the refund period as long as one of
        the two aforementioned conditions are met.
      </p>
      <hr />
      <h6>
        <em>
          If you have any other queries about our refund policy,{" "}
          <a href="mailto:support@thelogicaltraders.com?subject=Query">
            contact us by email
          </a>{" "}
          or drop us a text on WhatsApp by clicking the button on the
          bottom-right corner of the screen.
        </em>
      </h6>
      <Spacer />
    </Container>
  </>
);

export default RefundPolicy;
