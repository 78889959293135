/* eslint-disable import/prefer-default-export */
export const TECHNICAL_ANALYSIS_CONTENT = [
  {
    section: 'INTRODUCTION',
    lessons: [
      {
        title: 'Welcome to the Course',
        time: '1:03 min',
        resources: 0,
      },
      {
        title: 'How to Make the Most of this Course',
        time: '5:21 min',
        resources: 1,
      },
    ],
  },
  {
    section: 'BASICS OF STOCK MARKET',
    lessons: [
      {
        title: 'What is a Stock?',
        time: '11:34 min',
        resources: 3,
      },
      {
        title: 'What is a Stock Market?',
        time: '8:29 min',
        resources: 0,
      },
      {
        title: 'What is a Stock Exchange?',
        time: '1:40 min',
        resources: 1,
      },
      {
        title: 'What is a Stock Broker?',
        time: '2:23 min',
        resources: 0,
      },
      {
        title: 'Types of Orders',
        time: '14:09 min',
        resources: 0,
      },
      {
        title: 'Some Basic Stock Market Terminologies',
        time: '23:00 min',
        resources: 0,
      },
      {
        title: 'Taxes on Selling Stocks',
        time: '4:07 min',
        resources: 0,
      },
    ],
  },
  {
    section: 'TRADING VS INVESTING',
    lessons: [
      {
        title: 'Technical Analysis v/s Fundamental Analysis Part 1',
        time: '7:35 min',
        resources: 0,
      },
      {
        title: 'Technical Analysis v/s Fundamental Analysis Part 2',
        time: '6:59 min',
        resources: 0,
      },
      {
        title: 'Types of Traders',
        time: '2:59 min',
        resources: 0,
      },
      {
        title: 'Why We Don\'t Prefer Intraday Trading',
        time: '4:46 min',
        resources: 2,
      },
      {
        title: 'More Evidence in Favour of Swing Trading',
        time: '3:43 min',
        resources: 0,
      },
      {
        title: 'Market Cycles',
        time: '14:45 min',
        resources: 2,
      },
      {
        title: 'Inefficient Markets',
        time: '3:05 min',
        resources: 0,
      },
      {
        title: 'What to Expect?',
        time: '5:35 min',
        resources: 0,
      },
      {
        title: 'SECRET #1',
        time: '10:17 min',
        resources: 0,
      },
      {
        title: 'SECRET #2',
        time: '4:34 min',
        resources: 0,
      },
      {
        title: 'Some Famous Examples of SECRET #2',
        time: '7:41 min',
        resources: 0,
      },
    ],
  },
  {
    section: 'RISK MANAGEMENT, REDUCING COSTS, TRADING EDGE',
    lessons: [
      {
        title: 'Common Trading Mistakes: Part 1',
        time: '4:43 min',
        resources: 0,
      },
      {
        title: 'Common Trading Mistakes: Part 2',
        time: '7:16 min',
        resources: 0,
      },
      {
        title: 'Common Trading Mistakes: Part 3',
        time: '5:37 min',
        resources: 0,
      },
      {
        title: 'Why Mutual Funds are Bad',
        time: '10:36 min',
        resources: 2,
      },
      {
        title: '"Smart" Money isn\'t Always Right & Trading Edge',
        time: '14:19 min',
        resources: 3,
      },
      {
        title: 'Gambling in the Stock Markets',
        time: '7:33 min',
        resources: 0,
      },
      {
        title: 'More Signs of Gambling in the Stock Market',
        time: '7:53 min',
        resources: 0,
      },
      {
        title: 'Expected Value',
        time: '9:04 min',
        resources: 0,
      },
      {
        title: 'Key Risk Management Tips',
        time: '8:33 min',
        resources: 0,
      },
      {
        title: 'Diversification in Trading',
        time: '4:31 min',
        resources: 0,
      },
      {
        title: 'Losing Streak',
        time: '5:10 min',
        resources: 0,
      },
      {
        title: 'Risk of Ruin',
        time: '3:07 min',
        resources: 0,
      },
      {
        title: 'Winning Streak',
        time: '7:02 min',
        resources: 0,
      },
      {
        title: 'Concept of R Multiple',
        time: '3:51 min',
        resources: 0,
      },
      {
        title: 'Trade like a Bond Investor',
        time: '6:10 min',
        resources: 0,
      },
      {
        title: 'Be Wary of Scam Artists',
        time: '14:24 min',
        resources: 0,
      },
    ],
  },
  {
    section: 'SUPPORT, RESISTANCE, VOLUME & CANDLESTICKS',
    lessons: [
      {
        title: 'Support & Resistance',
        time: '10:51 min',
        resources: 0,
      },
      {
        title: 'Psychological Importance of Round Numbers',
        time: '3:09 min',
        resources: 0,
      },
      {
        title: 'Example of Support & Resistance',
        time: '7:14 min',
        resources: 0,
      },
      {
        title: 'SECRET #3, #4, #5, #6 & #7',
        time: '12:29 min',
        resources: 0,
      },
      {
        title: 'Volume',
        time: '5:16 min',
        resources: 0,
      },
      {
        title: 'Getting Started with Candlesticks',
        time: '8:36 min',
        resources: 0,
      },
      {
        title: 'Narrow Range Candles & SECRET #8',
        time: '6:43 min',
        resources: 0,
      },
      {
        title: 'Hammer Candlestick Patterns',
        time: '5:08 min',
        resources: 0,
      },
      {
        title: 'Engulfing Candlestick Patterns',
        time: '2:54 min',
        resources: 0,
      },
      {
        title: 'Marubozu Candlestick Patterns',
        time: '1:28 min',
        resources: 0,
      },
      {
        title: 'Three White Soldiers & Three Black Crows',
        time: '1:16 min',
        resources: 0,
      },
      {
        title: 'Piercing Line & Dark Cloud Cover',
        time: '3:11 min',
        resources: 0,
      },
      {
        title: 'Bullish & Bearish Harami Candlesticks',
        time: '1:54 min',
        resources: 0,
      },
      {
        title: 'Continuation Candlestick Patterns',
        time: '6:46 min',
        resources: 0,
      },
      {
        title: 'Other Candlestick Patterns',
        time: '0:39 min',
        resources: 1,
      },
    ],
  },
  {
    section: 'CONFLUENCE, TRENDS, TREND LINES, MOVING AVERAGE, GAPS & ATR',
    lessons: [
      {
        title: 'Concept of Confluence',
        time: '3:55 min',
        resources: 0,
      },
      {
        title: 'SECRET #9 & #10',
        time: '4:01 min',
        resources: 0,
      },
      {
        title: 'The Most Important Stock Market Movie',
        time: '5:09 min',
        resources: 0,
      },
      {
        title: 'SECRET #11 & #12',
        time: '3:31 min',
        resources: 2,
      },
      {
        title: 'Using Secrets in a Live Trade',
        time: '5:56 min',
        resources: 0,
      },
      {
        title: 'What is a Trend?',
        time: '2:28 min',
        resources: 0,
      },
      {
        title: 'Trading with the Trend',
        time: '8:21 min',
        resources: 0,
      },
      {
        title: 'SECRET #13 & #14',
        time: '5:27 min',
        resources: 0,
      },
      {
        title: 'Trend Lines',
        time: '4:04 min',
        resources: 0,
      },
      {
        title: 'How to Draw a Trendline',
        time: '4:21 min',
        resources: 0,
      },
      {
        title: 'How I Made 40% in 2 Months in 2022',
        time: '18:32',
        resources: 1,
      },
      {
        title: 'SECRET #15 With an Example',
        time: '5:27 min',
        resources: 0,
      },
      {
        title: 'Consolidation & SECRET #16',
        time: '5:59 min',
        resources: 0,
      },
      {
        title: 'My Losing Streak',
        time: '7:37 min',
        resources: 0,
      },
      {
        title: 'Four Stages of a Trend: Stage 1 & Stage 2',
        time: '12:03 min',
        resources: 0,
      },
      {
        title: 'SECRET #17 and Our Stock Selection Checklist',
        time: '4:37 min',
        resources: 0,
      },
      {
        title: 'How to Select Stocks for Trading',
        time: '7:21 min',
        resources: 1,
      },
      {
        title: 'Stage 3 of a Trend and SECRET #18',
        time: '6:29 min',
        resources: 0,
      },
      {
        title: 'Stage 4 of a Trend',
        time: '2:39 min',
        resources: 0,
      },
      {
        title: 'Base Formation in Stage 2 & Stage 3',
        time: '11:31 min',
        resources: 0,
      },
      {
        title: 'Gaps & Its Four Types',
        time: '5:39 min',
        resources: 0,
      },
      {
        title: 'Using Average True Range & Putting Stoploss',
        time: '3:39 min',
        resources: 0,
      },
      {
        title: 'Moving Averages & SECRET #19',
        time: '7:48 min',
        resources: 0,
      },
      {
        title: 'Four Stages of a Trend: Stage 1 & Stage 2',
        time: '12:03 min',
        resources: 0,
      },
      {
        title: 'Trend Identification & Moving Average Crossover',
        time: '5:46 min',
        resources: 0,
      },
      {
        title: 'Example of Using Moving Averages',
        time: '3:13 min',
        resources: 0,
      },
      {
        title: '30 Week Moving Average',
        time: '1:09 min',
        resources: 0,
      },
      {
        title: 'Kaufman’s Adaptive Moving Average',
        time: '4:32 min',
        resources: 0,
      },
    ],
  },
  {
    section: 'INDICATORS & CHART PATTERNS',
    lessons: [
      {
        title: 'MACD Indicator',
        time: '6:04 min',
        resources: 0,
      },
      {
        title: 'Relative Strength Index (RSI), SECRET #20 & #21',
        time: '8:17 min',
        resources: 0,
      },
      {
        title: 'Bollinger Bands',
        time: '9:24 min',
        resources: 0,
      },
      {
        title: 'Chart Patterns',
        time: '2 min',
        resources: 0,
      },
      {
        title: 'Symmetrical Triangle, SECRET #22 & #23',
        time: '10:56 min',
        resources: 0,
      },
      {
        title: 'Bullish & Bearish Pennants',
        time: '3:37 min',
        resources: 0,
      },
      {
        title: 'Bull Flag & Bear Flag',
        time: '5:08 min',
        resources: 0,
      },
      {
        title: 'Wedges & SECRET #24',
        time: '5:28 min',
        resources: 0,
      },

      {
        title: 'The Holy Grail Pattern & SECRET #25',
        time: '3:29 min',
        resources: 0,
      },
      {
        title: 'Ascending & Descending Triangles',
        time: '2:11 min',
        resources: 0,
      },
      {
        title: 'SECRET #26 with Example',
        time: '5:21 min',
        resources: 0,
      },
      {
        title: 'Double Top & Bottom',
        time: '3:31 min',
        resources: 0,
      },
      {
        title: 'SECRET #27',
        time: '6:55 min',
        resources: 1,
      },
      {
        title: 'Triple Top & Triple Bottom',
        time: '1:16 min',
        resources: 0,
      },
      {
        title: 'Cup and Handle & SECRET #28 with Example',
        time: '6:29 min',
        resources: 0,
      },
      {
        title: 'Head & Shoulders and Inverted Head & Shoulders',
        time: '4:24 min',
        resources: 0,
      },
      {
        title: 'SECRET #29 with Example',
        time: '3:40 min',
        resources: 0,
      },
      {
        title: 'Volatility Contraction Pattern',
        time: '6:02 min',
        resources: 0,
      },
      {
        title: '3 Week Tight Close & SECRET #30 with Example',
        time: '4:05 min',
        resources: 0,
      },
      {
        title: 'Stage 3 of a Trend and SECRET #18',
        time: '6:29 min',
        resources: 0,
      },
      {
        title: 'Channels',
        time: '6:30 min',
        resources: 0,
      },
      {
        title: 'Diamond Pattern',
        time: '5:18 min',
        resources: 0,
      },
      {
        title: 'Megaphone',
        time: '2:14 min',
        resources: 0,
      },
      {
        title: 'Bump & Run Reversal',
        time: '4:51 min',
        resources: 0,
      },
    ],
  },
  {
    section: 'ADVANCED STRATEGIES, INDICATORS & CONCEPTS, AND BONUS SECRETS',
    highlight: true,
    lessons: [
      {
        title: 'Overextended Moves & Bonus SECRET #31',
        time: '3:07 min',
        resources: 0,
      },
      {
        title: 'Example of SECRET #31',
        time: '2:26 min',
        resources: 0,
      },
      {
        title: 'Bonus SECRET #32',
        time: '4:01 min',
        resources: 0,
      },
      {
        title: 'Bonus SECRET #33',
        time: '3:49 min',
        resources: 0,
      },
      {
        title: 'Relative Strength',
        time: '8:59 min',
        resources: 0,
      },
      {
        title: 'Spread Charts',
        time: '13:56 min',
        resources: 0,
      },
      {
        title: 'Risk on, Risk off',
        time: '11:14 min',
        resources: 0,
      },
      {
        title: 'Correlation Coefficient',
        time: '15:34 min',
        resources: 0,
      },

      {
        title: 'Intermarket Analysis',
        time: '2:07 min',
        resources: 0,
      },
      {
        title: 'Nifty IT Strategy (IT Stocks, Futures & Options)',
        time: '19:25 min',
        resources: 0,
        highlight: true,
      },
      {
        title: 'SECRET #34 & Nifty and Bank Nifty Strategy (Positional & Intraday, Futures & Options)',
        time: '5:21 min',
        resources: 0,
        highlight: true,
      },
      {
        title: 'Gold & Silver Strategy, And Secret #35 (Commodities Futures & Options)',
        time: '23:36 min',
        resources: 0,
        highlight: true,
      },
      {
        title: 'Crude Oil Strategy (Commodities Futures & Options)',
        time: '27:25 min',
        resources: 0,
        highlight: true,
      },
    ],
  },
];
