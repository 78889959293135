/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Rating from 'react-rating';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import moment from 'moment';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { Spacer } from '../about';
import {
  CenteredContainer,
  FeatureList,
  FeatureListInnerUl,
  FeatureListItem,
  FeatureListItemInnerUlLI,
  OverlayDiv,
  PlanCard,
  PlanCardContent,
  PlanCardHeader,
  PriceText,
} from '../../styledComponents';
import { axiosAuth } from '../../api';
import { CenteredDiv, CourseImage } from '../courses/styled';
import { authAddToCart, authRemoveFromCart } from '../../actions/authcart';
import HeadingUnderline from '../../components/HeadingUnderline';
import { courseAddedToCart, manipulateTime } from '../../utils/helper';
import CouponButton from '../../components/CouponButton';
import greyStar from '../../assets/grey-star.png';
import yellowStar from '../../assets/yellow-star.png';
import CourseCounter, { getExpiryDate } from './CourseCounter';
import { COURSE_PLAN_DETAILS } from '../../components/BuyCourseButton';
import { FREE_COURSE } from '../../constant';

export const CouponCode = styled.div`
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border: ${({ theme }) => `5px solid ${theme.borders.light}`};
  width: 400px;
  margin: 0 auto 35px auto;
  font-weight: bold;
  height: 125px;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    width: 350px;
  }
`;

const RedText = styled.span`
  color: #e00101;
`;

const Dashboard = () => {
  const [allCourses, setAllCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const coursesInCart = useSelector((state) => state.authcart.cart);
  const userState = useSelector((state) => state.common.user);
  const user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : {};
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    axiosAuth.get(API_ENDPOINTS.ALL_AUTHENTICATED_COURSES).then((res) => {
      if (res?.data?.status && res?.data?.data) {
        setAllCourses(res?.data?.data);
        setLoading(false);
      }
    });
  }, []);

  const isPsychologyTradingPurchased = userState?.purchasedCourses?.find(
    (x) => x.slug === FREE_COURSE.slug && !x.isRefunded,
  );

  const momentDate = manipulateTime(userState?.coupon?.expiry);
  const isExpired = momentDate.isBefore(moment());

  const handleCourseClick = (courseData, isCourseInCart) => {
    if (isCourseInCart > -1) {
      history.push('/auth-cart');
      return;
    }
    dispatch(authAddToCart(courseData));
    if (isCourseInCart == -1) {
      courseAddedToCart(courseData.course_name, user?.email ?? '');
      axiosAuth
        .post(API_ENDPOINTS.ADD_TO_CART, { courseId: courseData._id })
        .then(() => {
          history.push('/auth-cart');
        });
    }
  };

  const expiringInDays = momentDate.diff(moment(), 'days');
  const countDownDate = new Date(getExpiryDate(userState?.createdAt)).getTime();
  const finalTime = countDownDate - new Date().getTime();

  return (
    <Container>
      <Spacer />
      <HeadingUnderline title="All Courses" />
      {userState?.coupon?.code && !userState?.coupon?.isUsed && !isExpired ? (
        <>
          <CouponCode>
            <span>Congrats, you've unlocked a coupon</span>
            <CouponButton />
            <RedText>
              {expiringInDays === 0
                ? 'Expiring Today'
                : `Expiring in ${expiringInDays} day(s)`}
            </RedText>
          </CouponCode>
          <Spacer />
        </>
      ) : (
        <>
          <Spacer />
        </>
      )}
      {/* {finalTime > 0 && !isPsychologyTradingPurchased && <CourseCounter />} */}
      <Row>
        {loading ? (
          <Col span={24}>
            <CenteredDiv>
              <LoadingOutlined
                style={{ fontSize: 30, color: '#f9ae00' }}
                spin
              />
            </CenteredDiv>
          </Col>
        ) : allCourses?.length ? (
          allCourses.map((course, index) => {
            const isCourseInCart = coursesInCart.findIndex(
              (x) => x._id === course._id,
            );
            // Temp comment
            // const description = (course?.locked_content || '')
            //   .replaceAll('\r\n\r\n\r\n', '<br/><br/>')
            //   .replaceAll('\r\n\r\n', '<br/><br/>')
            //   .replaceAll('&nbsp;', '');
            return (
              <Col
                key={course._id}
                xs={24}
                sm={12}
                md={12}
                lg={8}
                className="plan-card"
              >
                <PlanCard
                  onClick={() => {
                    if (course?.isPurchased) {
                      history.push(`/courses/${course.slug}`);
                    }
                  }}
                  isLocked={course.is_locked}
                >
                  {course.is_locked && (
                    <OverlayDiv>{ReactHtmlParser('')}</OverlayDiv>
                  )}
                  <PlanCardHeader>
                    {course?.course_name ?? 'Course'}
                  </PlanCardHeader>
                  <PlanCardContent>
                    <CourseImage src={course?.course_image ?? ''} />
                    <Spacer />
                    <CenteredContainer>
                      <FeatureList>
                        <FeatureListItem>
                          Course Details:
                          <FeatureListInnerUl>
                            <FeatureListItemInnerUlLI>
                              {course?.course_description || 'Description'}
                            </FeatureListItemInnerUlLI>
                          </FeatureListInnerUl>
                        </FeatureListItem>
                      </FeatureList>
                      <FeatureList>
                        <FeatureList>
                          <FeatureListItem>
                            Lectures:&nbsp;
                            {course?.course_lessons}
                            &nbsp;|&nbsp;Duration:&nbsp;
                            {course?.course_duration}
                            <FeatureListInnerUl>
                              <FeatureListItemInnerUlLI>
                                Validity:&nbsp;
                                {course?.course_validity}
                              </FeatureListItemInnerUlLI>
                            </FeatureListInnerUl>
                          </FeatureListItem>
                        </FeatureList>
                      </FeatureList>
                    </CenteredContainer>
                    {course?.isPurchased ? (
                      <PriceText>&nbsp;</PriceText>
                    ) : index === 1 ? (
                      <PriceDiv>
                        {/* <StrikedText>
                          &#8377;
                          {course?.base_amount}
                        </StrikedText>
                        {' '} */}
                        <NewPriceText>
                          &#8377;
                          {course?.course_amount}
                        </NewPriceText>
                      </PriceDiv>
                    ) : (
                      <PriceDiv>
                        <StrikedText>
                          &#8377;
                          {course?.base_amount}
                        </StrikedText>
                        {' '}
                        <NewPriceText>
                          &#8377;
                          {course?.course_amount}
                        </NewPriceText>
                      </PriceDiv>
                    )}
                    {course?.rating?.rating ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '5px',
                        }}
                      >
                        <StarBox>
                          <RatingsCircle>
                            {course?.rating?.rating}
                          </RatingsCircle>
                          <Rating
                            readonly
                            start={0}
                            stop={5}
                            step={1}
                            initialRating={course?.rating?.rating}
                            emptySymbol={(
                              <img
                                alt="empty-star"
                                src={greyStar}
                                style={{ width: '20px' }}
                              />
                            )}
                            fullSymbol={(
                              <img
                                alt="filled-star"
                                src={yellowStar}
                                style={{ width: '20px' }}
                              />
                            )}
                          />
                          &nbsp;
                          <div style={{ fontSize: '10px', paddingTop: '5px' }}>
                            (
                            <strong>
                              {course?.rating?.usersCount}
                              &nbsp;Ratings
                            </strong>
                            )
                          </div>
                        </StarBox>
                      </div>
                    ) : (
                      <div style={{ height: '30px', width: '5px' }} />
                    )}
                    <CenteredContainer>
                      {course?.isPurchased ? (
                        <Button
                          onClick={() => history.push(`/courses/${course.slug}`)}
                          className="get-started-btn"
                          size="large"
                        >
                          Show Lectures
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleCourseClick(course, isCourseInCart)}
                          className="get-started-btn"
                          size="large"
                        >
                          {isCourseInCart > -1 ? 'Go to Cart' : 'Add to Cart'}
                        </Button>
                      )}
                    </CenteredContainer>
                  </PlanCardContent>
                </PlanCard>
              </Col>
            );
          })
        ) : (
          <Col span={24}>
            <h4 style={{ textAlign: 'center' }}>No course found</h4>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Dashboard;

export const StarBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  color: white;
  padding: 5px 20px 10px 20px;
  position: relative;
  border-radius: 18px;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const RatingsCircle = styled.div`
  background: #f9ae00;
  position: absolute;
  left: -30px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  top: -7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: black;
`;

export const StrikedText = styled(PriceText)`
  text-decoration: line-through;
  text-decoration-line: line-through;
  text-decoration-color: red;
  margin-right: 5px;
`;

export const NewPriceText = styled(PriceText)`
  font-size: 18px;
`;

export const PriceDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
