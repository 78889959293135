import {
  Col, Collapse, Row, Tabs,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  ContainerOutlined,
  LoadingOutlined,
  StarFilled,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { axiosAuth } from '../../api';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { CenteredDiv } from '../courses/styled';
import HeadingUnderline from '../../components/HeadingUnderline';
import { Spacer } from '../about';
import BuyCourseButton from '../../components/BuyCourseButton';
import tick from '../../assets/tick.svg';
import { TECHNICAL_ANALYSIS_CONTENT } from './constant';

const { Panel } = Collapse;

const benefits = [
  'Improve the success rate of your trades',
  'Take trades with massive profit potential',
  'Avoid fake breakouts',
  'Make massive profits even if your accuracy is less than 50%',
  'Minimize your losses and drawdowns',
  'Deal with losing streaks',
  'Pick winning stocks based on our checklist',
];

const { TabPane } = Tabs;

export const ModifiedCollapse = styled(Collapse)`
  h6 {
    font-weight: bold;
    margin-bottom: 0;
  }
  .ant-collapse-header {
    display: flex;
    align-items: center;
  }
`;

export const SectionHeadingDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const LessonDiv = styled(SectionHeadingDiv)`
  p {
    margin-bottom: 0 !important;
  }
  padding-left: 25px;
  margin-bottom: 5px;
`;

export const MainContent = styled.div`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const MainContentText = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const CourseImage = styled.img`
  margin-right: 20px;
  width: 300px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ParentDetails = styled.h5`
  color: #979197;
  font-size: 16px;
  font-weight: bold;
`;

export const BenefitsList = styled.ul`
  padding-left: 0px;
  li {
    list-style-type: none;
    margin-bottom: 10px;
    text-indent: -20px;
    margin-left: 20px;
  }
`;

export const Tick = styled.img`
  height: 18px;
  width: inherit !important;
  margin-right: 8px;
  @media (max-width: 1024px) {
    height: inherit;
  }
`;

export const HowToUse = styled.ul`
  padding-left: 0px;
  li {
    margin-bottom: 10px;
    list-style-type: none;
    text-indent: -20px;
    margin-left: 20px;
  }
`;

const Curriculum = () => {
  const params = useParams();
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, toggleTab] = useState('1');

  useEffect(() => {
    setLoading(true);
    axiosAuth
      .get(`${API_ENDPOINTS.GET_COURSE_BY_SLUG}/${params?.slug ?? ''}`)
      .then((res) => {
        setLoading(false);
        if (res?.data?.data) {
          setCourseData(res?.data?.data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [params]);

  return (
    <Container>
      <Spacer />
      <HeadingUnderline title="Our Course Curriculum" />
      {loading ? (
        <Row>
          <Col span={24}>
            <CenteredDiv>
              <LoadingOutlined
                style={{ fontSize: 30, color: '#f9ae00' }}
                spin
              />
            </CenteredDiv>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={24}>
            <MainContent>
              <CourseImage
                alt={courseData?.course_name}
                src={courseData?.course_image}
              />
              <MainContentText>
                <h2>{courseData?.course_name}</h2>
                <ParentDetails>Instructor: Ayush Singh</ParentDetails>
                <ParentDetails>Language: English</ParentDetails>
                <ParentDetails>
                  Validity:
                  {' '}
                  {courseData?.course_validity}
                </ParentDetails>
                <Spacer />
                <BuyCourseButton squareButton showPrice />
              </MainContentText>
            </MainContent>
          </Col>
          <Spacer />
          <Col span={24}>
            <Tabs
              style={{ marginTop: '20px' }}
              activeKey={activeTab}
              defaultActiveKey="1"
              onChange={toggleTab}
            >
              <TabPane tab="Content" key="1">
                <ModifiedCollapse ghost>
                  {TECHNICAL_ANALYSIS_CONTENT.map((x) => (
                    <Panel
                      header={(
                        <SectionHeadingDiv>
                          <ContainerOutlined
                            style={{ marginRight: '10px', color: '#F9AE00' }}
                          />
                          <h6
                            style={{ color: x.highlight ? '#F9AE00' : 'black' }}
                          >
                            {x.section}
                          </h6>
                        </SectionHeadingDiv>
                      )}
                      key={x.section}
                    >
                      {x.lessons.map((l) => (
                        <LessonDiv key={l.title}>
                          {l.highlight ? (
                            <StarFilled
                              style={{ marginRight: '10px', color: '#F9AE00' }}
                            />
                          ) : (
                            <VideoCameraOutlined
                              style={{ marginRight: '10px', color: '#F9AE00' }}
                            />
                          )}
                          <p
                            style={{
                              color: l.highlight ? '#F9AE00' : 'black',
                              fontWeight: l.highlight ? 'bold' : 'inherit',
                            }}
                          >
                            {l.title}
                          </p>
                        </LessonDiv>
                      ))}
                    </Panel>
                  ))}
                </ModifiedCollapse>
              </TabPane>
              <TabPane tab="Benefits" key="2">
                <Spacer />
                <h4>
                  <b>Our secrets will help you</b>
                </h4>
                <BenefitsList>
                  {benefits.map((x) => (
                    <li>
                      <Tick src={tick} />
                      {x}
                    </li>
                  ))}
                </BenefitsList>
              </TabPane>
              <TabPane tab="How to Use" key="3">
                <Spacer />
                <h4>
                  <b>How to access the course</b>
                </h4>
                <HowToUse>
                  <li>
                    <Tick src={tick} />
                    After purchase, your account will be created on our website
                    and "Profitable Trading Masterclass" will be added to your
                    account.
                  </li>
                  <li>
                    <Tick src={tick} />
                    You can access the course videos anytime you want by logging
                    in over
                    {' '}
                    <Link to="/login">here</Link>
                  </li>
                  <li>
                    <Tick src={tick} />
                    Our application works perfectly on desktop, mobile or tablet
                    so you can watch it on any device.
                  </li>
                </HowToUse>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      )}
      <Spacer />
      <Spacer />
    </Container>
  );
};
export default Curriculum;
