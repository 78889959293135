import { SET_NOTIFICATION_COUNT } from '../actions/notification';

const initialState = {
  count: 0,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION_COUNT:
      return {
        ...state,
        count: action.payload || 0,
      };
    default:
      return state;
  }
}

export default reducer;
