import React, { useEffect, useState } from 'react';
import {
  Button, notification, Table, Tag,
} from 'antd';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import Lightbox from 'react-image-lightbox';
import { axiosAuth } from '../../api';

const QueriesContainer = styled.div`
  margin-top: 40px;
`;

const AllQueries = ({ common }) => {
  const user = common?.user?.userType || '';
  const [allQueriesList, setQueriesList] = useState([]);
  const [loading, setLoading] = useState([]);
  const [updateId, setUpdatingId] = useState([]);
  const [imageLightBox, setImage] = useState([]);
  const [openLightBox, toggleLightBox] = useState(false);

  const getAllQueries = () => {
    setLoading(true);
    axiosAuth
      .get('contactus/allQueries')
      .then((res) => {
        if (res?.data?.data) {
          setQueriesList(res.data.data.reverse());
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleMarkAsResolved = (id) => {
    setUpdatingId(id);
    axiosAuth
      .get(`contactus/changeQueryStatus?active=false&id=${id}`)
      .then((res) => {
        notification.success({
          message: 'Success',
          description: 'Query marked as resolved',
        });
        setUpdatingId(null);
        getAllQueries();
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: 'Failed to mark query as resolved',
        });
        setUpdatingId(null);
      });
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      key: 'sno',
      render: (sno, data, index) => index + 1,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Contact',
      dataIndex: 'contactNo',
      key: 'contactNo',
      render: (contact) => contact ?? '--',
    },
    {
      title: 'Description',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image) => (image ? (
        <img
          onClick={() => {
            toggleLightBox(true);
            setImage(image);
          }}
          height="80px"
          width="80px"
          src={image}
        />
      ) : (
        '--'
      )),
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD MMM YYYY hh:mm'),
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (active) => (
        <Tag color={active ? 'red' : 'green'}>
          {active ? 'Active' : 'Resolved'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: '_id',
      key: '_id',
      render: (_id, data) => (data?.active ? (
        <Button
          loading={_id == updateId}
          onClick={() => handleMarkAsResolved(_id)}
          type="primary"
        >
          Mark as resolved
        </Button>
      ) : (
        <></>
      )),
    },
  ];

  useEffect(() => {
    if (user == 'admin') {
      getAllQueries();
    }
  }, [user]);

  return (
    <QueriesContainer>
      {user == 'admin' ? (
        <Container>
          <h4>All Queries</h4>
          <Table
            loading={loading}
            dataSource={allQueriesList}
            columns={columns}
            pagination={{
              pageSize: '50',
              pageSizeOptions: ['50', '100', '200'],
            }}
          />
        </Container>
      ) : (
        <></>
      )}
      {openLightBox && (
        <Lightbox
          mainSrc={imageLightBox}
          onCloseRequest={() => toggleLightBox(false)}
        />
      )}
    </QueriesContainer>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

export default connect(mapStateToProps, null)(AllQueries);
