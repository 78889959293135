export const SET_LESSONS = 'SET_LESSONS';
export const SET_LAST_WATCHED_LESSON = 'SET_LAST_WATCHED_LESSON';
export const SET_CURRENT_LESSON = 'SET_CURRENT_LESSON';
export const UPDATE_LESSON_WATCH_TIME = 'UPDATE_LESSON_WATCH_TIME';
export const REMOVE_NEW_FROM_LESSON = 'REMOVE_NEW_FROM_LESSON';
export const TOGGLE_PLAYER_RENDERING = 'TOGGLE_PLAYER_RENDERING';

export const setLessons = (lessons) => ({
  type: SET_LESSONS,
  payload: lessons,
});

export const setLastWatchedVideo = (lesson) => ({
  type: SET_LAST_WATCHED_LESSON,
  payload: lesson,
});

export const setCurrentVideo = (lesson) => ({
  type: SET_CURRENT_LESSON,
  payload: lesson,
});

export const updateLessonWatchTime = (lesson) => ({
  type: UPDATE_LESSON_WATCH_TIME,
  payload: lesson,
});

export const removeNewFromLesson = (sectionIndex, lessonIndex, lesson) => ({
  type: REMOVE_NEW_FROM_LESSON,
  payload: { lesson, sectionIndex, lessonIndex },
});

export const togglePlayerRendering = (value) => ({
  type: TOGGLE_PLAYER_RENDERING,
  payload: value,
});
