import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { setModalType } from "../../actions/common";
import ForgotPassword from "../ForgotPassword";
import Login from "../Login";

const Auth = ({ setAuthModalType, common }) => {
  return (
    <div>
      {/* Common modal for auth */}
      <Modal
        backdrop='static'
        show={common.authModalType !== null}
        onHide={() => setAuthModalType(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {common.authModalType === "login" ? "Login" : "Forgot Password"}
          </Modal.Title>
        </Modal.Header>
        {common.authModalType === "login" ? <Login /> : <ForgotPassword />}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthModalType: (type) => dispatch(setModalType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
