const API_ENDPOINTS = {
  GET_ALL_UNAUTHENTICATED_COURSES: 'learning/courses/allUnAuthCourses',
  SIGN_UP: 'learning/auth/signup',
  USER_EXIST: 'learning/user/isUserExists',
  LOGIN: 'learning/auth/login',
  GET_USER_PROFILE: 'learning/user/profile',
  ALL_AUTHENTICATED_COURSES: 'learning/courses/allAuthCourses',
  PURCHASE_COURSE: 'learning/courses/purchaseCourse',
  ASSIGN_COURSE: 'learning/courses/assign',
  ALL_USERS: 'learning/user/all',
  CHANGE_USER_STATUS: 'learning/user/changeUserStatus',
  LESSONS_BY_SLUG: 'learning/lessons/getLessonsByCourse',
  SYNC_COURSES: 'learning/courses/syncCourses',
  SYNC_LESSONS_BY_COURSE: 'learning/lessons/syncLessons',
  UPDATE_WATCH_TIME: 'learning/courses/addWatchTime',
  MODIFY_REFUND_PERIOD: 'learning/user/makeRefundablePeriodOver',
  GET_RESOURCES_BY_LESSON_ID: 'learning/resources/byId',
  ADD_RESOURCE_FOR_LESSON: 'learning/resources/create',
  DELETE_RESOURCE_FOR_LESSON: 'learning/resources/delete',
  CREATE_QA_FOR_LESSON: 'learning/comments/create',
  GET_APPROVED_QA_BY_LESSON_ID: 'learning/comments/getApprovedComments',
  DELETE_QA_BY_LESSON_ID: 'learning/comments/delete',
  ADD_REPLY_TO_LESSON: 'learning/comments/reply/create',
  DELETE_REPLY_UNDER_QA: 'learning/comments/reply/delete',
  GET_ALL_PENDING_QAS: 'learning/comments/getPendingComments',
  CHANGE_QA_STATUS: 'learning/comments/changeCommentStatus',
  ADD_TO_CART: 'learning/cart/add',
  REMOVE_FROM_CART: 'learning/cart/remove',
  GET_CART: 'learning/cart',
  MAKE_REFUND: 'learning/user/makeRefund',
  USER_UPDATE: 'learning/user/update',
  CHANGE_PASSWORD: 'learning/user/changePassword',
  FORGOT_PASSWORD: 'learning/user/forgetPassword',
  RESET_PASSWORD: 'learning/user/resetPassword',
  LOGOUT: 'learning/auth/logout',
  CHANGE_BAN_STATUS: 'learning/user/changeUserBannedStatus',
  GENERATE_COUPON_CODE: 'learning/coupon/generateCode',
  GET_COUPON_CONFIG: 'learning/coupon/config',
  CREATE_UPDATE_COUPON_CONFIG: 'learning/coupon/config/createAndUpdate',
  GET_COURSE_BY_SLUG: 'learning/courses',
  NEWSLETTER_SUBSCRIBE: 'newsletter/subscribe',
  LIVE_QA_ENROLL: 'qa/enroll',
  ALL_LIVE_QA_SUBS: 'qa-enroll/all',
  MARK_AS_READ: '/learning/notification/markAsRead',
  GET_ALL_NOTIFICATION: '/learning/notification/all',
  BROADCAST_NOTIFICATION: '/learning/notification/broadcastNotification',
  SUBMIT_RATING: '/learning/rating/create',
  ALL_RATINGS: '/learning/rating/all',
  GET_RATING_CONFIG: '/learning/adminRating',
  CREATE_UPDATE_RATINGS_CONFIG: '/learning/adminRating/createAndUpdate',
  PAYMENT_STATUS: 'payment/status',
  PAYMENT_STATUS_WEBINAR: 'payment/status/webinar',
  ADMIN_ANALYTICS: '/learning/analytics',
  FB_EVENT_TRIGGER: '/learning/user/fb-event',
  WEBINAR_SIGNUP: '/webinar/register',
  WEBINAR_CONFIG: '/webinar/config',
  VERIFY_COUPON: '/cart/coupon',
  GET_RECORDING: '/recording',
};

export default API_ENDPOINTS;
