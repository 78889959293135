import { Col, Row } from 'antd';
import React from 'react';
import { Container } from 'react-bootstrap';
import { isMobile, isMobileOnly } from 'react-device-detect';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { ColoredContainer } from './Common';
import bannerImg from '../../assets/banner-bg.png';
import { Spacer } from '../../routes/about';
import ActionButton from './ActionButton';

const VideoTestimonial = () => (
  <WhiteColoredContainer>
    <Container>
      {!isMobile ? (
        <>
          <Row>
            <Col sm={0} md={0} lg={2} />
            <Col sm={24} md={24} lg={6}>
              <TextContainer>
                <TextContent>
                  Even Our Clients Are Sharing Their Profit & Loss Statement
                </TextContent>
              </TextContainer>
            </Col>
            <Col sm={24} md={24} lg={7}>
              <div className="react-video-player-custom bordered">
                <ReactPlayer
                  url="https://vimeo.com/737791200"
                  controls
                  width={isMobileOnly ? '320px' : '300px'}
                  height={isMobileOnly ? '180px' : '168.75px'}
                  pip
                  loop
                  playsinline
                />
              </div>
            </Col>
            <Col sm={24} md={24} lg={7}>
              <div className="react-video-player-custom bordered">
                <ReactPlayer
                  url="https://vimeo.com/739527689"
                  controls
                  width={isMobileOnly ? '320px' : '300px'}
                  height={isMobileOnly ? '180px' : '168.75px'}
                  pip
                  loop
                  playsinline
                />
              </div>
            </Col>
            <Col sm={0} md={0} lg={2} />
          </Row>
          <Row>
            <Col span={2} />
            <Col span={20}>
              <Spacer />
              <Spacer />
              <ActionButton />
            </Col>
            <Col span={2} />
          </Row>
        </>
      ) : (
        <Row>
          <Col sm={24} md={24} lg={6}>
            <TextContainer>
              <TextContent>
                Even Our Clients Are Sharing Their Profit & Loss Statement
              </TextContent>
            </TextContainer>
          </Col>
          <Col sm={24} md={24}>
            <EmptyBox />
          </Col>
          <Col sm={24} md={24} lg={7}>
            <div className="react-video-player-custom bordered">
              <ReactPlayer
                url="https://vimeo.com/737791200"
                controls
                width={isMobileOnly ? '320px' : '300px'}
                height={isMobileOnly ? '180px' : '168.75px'}
                pip
                loop
                playsinline
              />
            </div>
          </Col>
          <Col sm={24} md={24}>
            <EmptyBox />
          </Col>
          <Col sm={24} md={24} lg={7}>
            <div className="react-video-player-custom bordered">
              <ReactPlayer
                url="https://vimeo.com/739527689"
                controls
                width={isMobileOnly ? '320px' : '300px'}
                height={isMobileOnly ? '180px' : '168.75px'}
                pip
                loop
                playsinline
              />
            </div>
          </Col>
        </Row>
      )}
    </Container>
  </WhiteColoredContainer>
);

export default VideoTestimonial;

const WhiteColoredContainer = styled(ColoredContainer)`
  background: #fafafa;
  min-height: inherit;
  padding: 40px 20px;
`;

const TextContainer = styled.div`
  background-image: url(${bannerImg});
  min-width: 100px;
  border-radius: 15px;
  height: 100%;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: ${isMobile ? '100%' : 'inherit'};
  text-align: ${isMobile ? 'center' : 'inherit'};
`;

const TextContent = styled.div`
  font-size: 20px;
  color: #f9ae00;
  font-family: GilroyBold;
`;

const EmptyBox = styled.div`
  min-width: 1px;
  min-height: 20px;
`;
