import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { Button } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { axiosAuth } from '../../api';
import {
  AlreadyAccountText,
  LoginText,
  ResetPasswordContainer,
  SignUpStepOneParent,
} from '../../styledComponents';
import HeadingUnderline from '../../components/HeadingUnderline';
import API_ENDPOINTS from '../../api/apiEndpoints';

const ResetPassword = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [joinUsLoading, setJoinUsLoading] = useState(false);
  const [errors, setErrors] = useState({
    password: null,
    confirmPassword: null,
  });

  const joinUsDisabled = useMemo(() => !password || !confirmPassword || password !== confirmPassword, [password, confirmPassword]);

  const validateError = (name, value) => {
    if (name === 'password') {
      let modifiedErrors = {
        ...errors,
        [name]: !value,
      };
      if (name === 'password' && value) {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/;
        modifiedErrors = {
          ...errors,
          [name]: passwordRegex.test(value) ? false : 1,
        };
      }
      setErrors(modifiedErrors);
    } else {
      let errorType = false;
      if (!value) {
        errorType = 1;
      } else if (password !== value) {
        errorType = 2;
      }
      const modifiedErrors = {
        ...errors,
        [name]: errorType,
      };
      setErrors(modifiedErrors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      setPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
    validateError(name, value);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateError(name, value);
  };

  const handleLinkClick = useCallback((path) => {
    history.push(path);
  }, []);

  const handleJoinUsClick = useCallback(() => {
    const token = new URLSearchParams(search).get('token');
    setJoinUsLoading(true);
    axiosAuth
      .post(API_ENDPOINTS.RESET_PASSWORD, {
        key: token,
        password,
      })
      .then((res) => {
        setJoinUsLoading(false);
        if (res?.status == 200 && res?.data?.code == 200) {
          toast('Password reset success.', {
            type: toast.TYPE.SUCCESS,
          });
          handleLinkClick('/login');
        } else {
          toast(
            'Failed to reset password. Please try again or contact admin for futher support!',
            { type: toast.TYPE.ERROR },
          );
        }
      });
  }, [joinUsDisabled]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' && !joinUsDisabled) {
        handleJoinUsClick();
      }
    },
    [joinUsDisabled],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [joinUsDisabled]);

  return (
    <div>
      <HeadingUnderline title="Reset Password" />
      <ResetPasswordContainer className="leadform">
        <SignUpStepOneParent>
          <Form.Group>
            <Form.Label className="required">Password</Form.Label>
            <Form.Control
              value={password}
              name="password"
              className="custom-field-focus"
              onBlur={handleBlur}
              onChange={handleInputChange}
              type="password"
              placeholder="Enter password"
              autoComplete="off"
              isInvalid={errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password === 1
                ? 'Password must be of 8-20 characters, at least one letter and one number'
                : 'Password is required!!'}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label className="required">Confirm Password</Form.Label>
            <Form.Control
              value={confirmPassword}
              name="confirmPassword"
              className="custom-field-focus"
              onBlur={handleBlur}
              onChange={handleInputChange}
              type="password"
              placeholder="Confirm password"
              isInvalid={errors.confirmPassword}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword === 1
                ? 'Confirm password is required!!'
                : 'Passwords do no match!!'}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            disabled={joinUsDisabled}
            onClick={handleJoinUsClick}
            className="signUpBtn"
            style={{ marginTop: '15px' }}
            loading={joinUsLoading}
            size="large"
            block
          >
            Submit
          </Button>
          <AlreadyAccountText onClick={() => handleLinkClick('/login')}>
            Already have an account?
            {' '}
            <LoginText>Login Now</LoginText>
          </AlreadyAccountText>
        </SignUpStepOneParent>
      </ResetPasswordContainer>
    </div>
  );
};

export default ResetPassword;
