import { Button } from 'antd';
import React, { useState, useCallback } from 'react';
import { slide as Hamburger } from 'react-burger-menu';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { logout, setModalType } from '../../actions/common';
import LessonsList from '../../routes/lessonsByCourse/LessonsList';
import {
  CapitalizedText,
  MobileNavbarItem,
} from '../../styledComponents';
import { logoutUserHandler } from '../../utils/helper';
import BuyCourseButton from '../BuyCourseButton';

const HamburgerMenu = ({ logoutUser }) => {
  const location = useLocation();
  const tokenIsExist = useSelector((state) => state.common.authToken);
  const [isMenuOpen, toggleMenu] = useState(false);
  const user = useSelector((state) => state.common.user);
  const history = useHistory();
  const allLessons = useSelector((state) => state.lessons.allLessons);
  const handleLinkClick = useCallback((path) => {
    toggleMenu(false);
    history.push(path);
  }, []);

  const handleLogoutClick = () => {
    logoutUserHandler(logoutUser, history);
  };

  const isLessonsPage = location.pathname === '/courses/technical-analysis' || location.pathname === '/courses/trading-psychology';
  return (
    <div className={isLessonsPage ? 'lessons-sidebar' : ''}>
      <Hamburger
        onOpen={() => toggleMenu(true)}
        onClose={() => toggleMenu(false)}
        isOpen={isMenuOpen}
      >
        {!isLessonsPage ? (
          <>
            {tokenIsExist && (
              <CapitalizedText style={{ marginBottom: '20px' }}>
                <strong>{user?.name || ''}</strong>
              </CapitalizedText>
            )}
            {user?.userType === 'admin' && (
              <MobileNavbarItem
                onClick={() => handleLinkClick('/admin-dashboard')}
                className="mt-10"
              >
                Dashboard
              </MobileNavbarItem>
            )}
            {tokenIsExist && (
              <MobileNavbarItem
                className="mt-10"
                onClick={() => handleLinkClick('/')}
              >
                Courses
              </MobileNavbarItem>
            )}
            {tokenIsExist && (
              <MobileNavbarItem
                className="mt-10"
                onClick={() => handleLinkClick('/profile')}
              >
                My Profile
              </MobileNavbarItem>
            )}
            {tokenIsExist ? (
              <MobileNavbarItem onClick={() => handleLogoutClick()}>
                Logout
              </MobileNavbarItem>
            ) : (
              <>
                <MobileNavbarItem
                  onClick={() => handleLinkClick('/')}
                  className="mt-10"
                >
                  Home
                </MobileNavbarItem>
                {/* Temp out of scope */}
                {/* <MobileNavbarItem
                  onClick={() => handleLinkClick('/courses')}
                  className="mt-10"
                >
                  Courses
                </MobileNavbarItem> */}
                {/* <MobileNavbarItem
                  onClick={() => handleLinkClick('/curriculum/technical-analysis')}
                  className="mt-10"
                >
                  Course Content
                </MobileNavbarItem> */}
                <MobileNavbarItem
                  onClick={() => handleLinkClick('/cart')}
                  className="mt-10"
                >
                  Cart
                </MobileNavbarItem>
                <Button
                  className="signUpBtn"
                  onClick={() => handleLinkClick('/login')}
                >
                  Login
                </Button>
                <br />
                <br />
                <BuyCourseButton flatButton />
              </>
            )}
          </>
        ) : (
          <LessonsList allLessons={allLessons} toggleMenu={toggleMenu} />
        )}
      </Hamburger>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setModalType: (type) => dispatch(setModalType(type)),
  logoutUser: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(HamburgerMenu);
