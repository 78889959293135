import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const ColoredContainer = styled.div`
  background-image: linear-gradient(to right, #141e30, #243b55);
  min-height: 100vh;
  padding: ${isMobile ? '15px 0 30px 0px' : '0 0 30px 0'};
`;

export const SectionHeadingText = styled.div`
  padding-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  text-align: ${isMobile ? 'center' : 'inherit'};
`;
