import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import HeadingUnderline from '../../components/HeadingUnderline';

const StyledSpan = styled.span`
  font-weight: 400;
  font-size: 14px;
`;

const Spacer = styled.div`
  height: 20px;
`;

const SmallSpacer = styled.div`
  height: 10px;
`;

const UnderlinedHeader = styled.h5``;

const PrivacyPolicy = () => (
  <Container>
    <Spacer />
    <HeadingUnderline title="Privacy Policy" />
    <p>
      The Logical Traders realizes the importance of maintaining your
      privacy. We value your privacy and appreciate your trust in us. This
      policy describes how we treat the user information that we collect on
      {' '}
      <a href="https://course.thelogicaltraders.com">
        course.thelogicaltraders.com
      </a>
      {' '}
      and other offline sources.
    </p>
    <Spacer />
    <h5><strong>RESTRICTING THE COLLECTION OF PERSONAL DATA</strong></h5>
    <p>
      We don’t sell, lease or share your personal information with any third
      party except to comply with the law, develop our products, or protect
      our rights. The policy applies to both current and former visitors to
      our website and our customers.
    </p>
    <Spacer />
    <h5><strong>CONSENT</strong></h5>
    <p>
      By visiting and/or using our website, you agree to this privacy policy.
    </p>
    <Spacer />
    <h5><strong>INFORMATION WE COLLECT</strong></h5>
    <br />
    <h6>
      <strong>Contact Information:</strong>
      {' '}
      <StyledSpan>
        We might collect your name, email ID, mobile number, age, gender, and
        internet protocol addresses.
      </StyledSpan>
    </h6>
    <SmallSpacer />
    <h6>
      <strong>Payment and Billing Information:</strong>
      {' '}
      <StyledSpan>
        We might collect your billing name and payment method. We NEVER
        collect your credit or debit card number or expiry date or other
        details pertaining to your credit or debit card.
      </StyledSpan>
    </h6>
    <p>
      Credit card and debit card information will be obtained and processed by
      our digital payment partner Razorpay.
    </p>
    <SmallSpacer />
    <h6>
      <strong>Log Files:</strong>
      {' '}
      <StyledSpan>
        We use log files to record the information of visitors when they visit
        course.thelogicaltraders.com. We use log files to collect information like
        Internet Protocol Addresses, Browser Type, and Internet Service
        Provider, date and time stamp, number of clicks, and so on.
      </StyledSpan>
    </h6>
    <SmallSpacer />
    <h6>
      <strong>Tracking:</strong>
      {' '}
      <StyledSpan>
        We use tracking tools like Facebook pixel, Google global site tag,
        Google analytics, browser cookies and other required tags for
        collecting information about your usage of our website. This helps us
        improve the performance of our website and to re-market visitors on
        different ad platforms who have visited our website in the past.
      </StyledSpan>
    </h6>
    <Spacer />
    <p>
      Website visitors who don’t want to have cookies placed on their
      computers should set their browsers to refuse cookies before visiting
      course.thelogicaltraders.com. Some features on course.thelogicaltraders.com may not
      function properly without the aid of cookies.
    </p>
    <p>
      Opening our emails and or clicking on links can be tracked by our email
      marketing software and/or other tools that we use to check the response
      to our emails.
    </p>
    <Spacer />
    <h5><strong>PRIVACY POLICY CHANGES</strong></h5>
    <p>
      Although most changes are likely to be minor, we may change our Privacy
      Policy from time to time, and it’s in our sole discretion. We encourage
      visitors to frequently check the Privacy Policy page for any changes.
      Your continued use of course.thelogicaltraders.com after any change in this
      policy will constitute your acceptance of such a change.
    </p>
    <Spacer />
    <h5><strong>CONTACT</strong></h5>
    <p>
      Any visitor who requests a quote, fills our lead form or newsletter
      form, or provides us their details in any other way is thereby giving us
      the right to contact them about our services, show them remarketing ads,
      or email them to let them know about our services.
    </p>
    <Spacer />
  </Container>
);

export default PrivacyPolicy;
