import React, { useMemo } from 'react';
import { Steps } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { MdOutlinePayment } from 'react-icons/md';
import { AiOutlineUserAdd } from 'react-icons/ai';
import styled from 'styled-components';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';

const { Step } = Steps;

const Stepper = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const headerParam = params.get('header');
  const codeParam = params.get('coupon-code');
  const currentStep = useMemo(() => {
    if (pathname === '/cart') {
      return 0;
    }

    if (pathname === '/checkout') {
      return 1;
    }

    return 0;
  }, [pathname]);

  const handleCartClick = () => {
    let queryParams = {};
    if (headerParam) {
      queryParams = { ...queryParams, header: false };
    }
    if (codeParam) {
      queryParams = { ...queryParams, 'coupon-code': codeParam };
    }
    const queryString = new URLSearchParams(queryParams).toString();
    history.push(`/cart${queryString ? `?${queryString}` : ''}`);
  };

  return (
    <CustomStepper current={currentStep} type="navigation" size="small">
      <Step
        onClick={handleCartClick}
        status="finish"
        title="Cart"
        style={{ cursor: 'pointer' }}
        icon={<ShoppingCartOutlined />}
      />
      <Step status="finish" title="Checkout" icon={<AiOutlineUserAdd />} />
      <Step status="process" title="Payment" icon={<MdOutlinePayment />} />
    </CustomStepper>
  );
};

export default Stepper;

const CustomStepper = styled(Steps)`
  .ant-steps-item-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .ant-steps-icon {
    font-size: 25px !important;
    color: #f9ae00 !important;
  }

  .ant-steps-item-content {
    margin-top: 5px;
  }

  .ant-steps-item::after {
    top: 60%;
    left: 94%;
  }

  .ant-steps-item::before {
    background-color: #f9ae00 !important;
  }
`;
