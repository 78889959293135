import React, { useEffect, useState } from 'react';
import {
  Button, Col, notification, Row, Spin,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as R from 'ramda';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import './LessonComments.scss';
import { useSelector } from 'react-redux';
import HtmlParser from 'react-html-parser';
import { LoadingOutlined, SendOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { CenteredDiv } from '../courses/styled';
import { StyledColFlex, StyledDeleteIcon } from './LessonResources';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { axiosAuth } from '../../api';

const LessonQA = ({ activeKey }) => {
  const params = useParams();
  const [editorData, setEditorData] = useState(EditorState.createEmpty());
  const user = useSelector((state) => state.common.user);
  const [allQAs, setAllQAs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [QaLoading, setQaLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [QaLoadMoreLoading, setQaLoadMoreLoading] = useState(false);
  const [replyBoxId, setReplyBoxId] = useState(null);
  const [replyContent, setReplyContent] = useState('');
  const [isReplyAddLoading, setReplyAddLoading] = useState(false);

  const currentLesson = useSelector((state) => state.lessons.currentLesson);

  useEffect(() => {
    if (currentLesson && currentLesson?._id && activeKey === '1') {
      // Resetting the states
      setHasMore(false);
      setOffset(0);
      setReplyContent('');
      setReplyBoxId(null);
      setAllQAs([]);
      //   Starting the process
      setQaLoading(true);
      axiosAuth
        .get(
          `${API_ENDPOINTS.GET_APPROVED_QA_BY_LESSON_ID}/${currentLesson?._id}?offset=0&limit=20`,
        )
        .then((res) => {
          if (res?.data?.status && res?.data?.data?.length) {
            setAllQAs(res?.data?.data);
            if (res?.data?.data?.length === 20) {
              setHasMore(true);
              setOffset(20);
            }
          } else {
            setAllQAs([]);
          }
          setQaLoading(false);
        });
    }
  }, [currentLesson, activeKey]);

  const loadMoreQuestions = () => {
    setQaLoadMoreLoading(true);
    axiosAuth
      .get(
        `${API_ENDPOINTS.GET_APPROVED_QA_BY_LESSON_ID}/${currentLesson?._id}?offset=${offset}&limit=20`,
      )
      .then((res) => {
        if (res?.data?.status && res?.data?.data?.length) {
          setAllQAs([...allQAs, ...res?.data?.data]);
          if (res?.data?.data?.length < 20) {
            setHasMore(false);
          } else {
            setOffset(offset + 20);
          }
        } else {
          setHasMore(false);
        }
        setQaLoadMoreLoading(false);
      });
  };

  const handleQaCreate = () => {
    setLoading(true);
    axiosAuth
      .post(API_ENDPOINTS.CREATE_QA_FOR_LESSON, {
        content: draftToHtml(
          convertToRaw(editorData.getCurrentContent()),
        ).toString(),
        images: [],
        sectionId: currentLesson?.section?._id,
        lessonId: currentLesson?._id,
        courseSlug: params.slug,
      })
      .then((res) => {
        if (res?.data?.status) {
          setEditorData(EditorState.createEmpty());
          setLoading(false);
          notification.success({
            message:
              user?.userType === 'admin'
                ? 'Submitted successfully'
                : 'Question submitted successfully and is under review!!',
          });
          if (user?.userType === 'admin') {
            setAllQAs(R.insert(0, res?.data?.data, allQAs));
          }
        } else {
          notification.error({
            message:
              'Failed to submit question. Please try again or contact admin!!',
          });
        }
      });
  };

  const userCanDelete = (questionById) => {
    if (user?.userType === 'admin' || questionById === user?._id) {
      return true;
    }
    return false;
  };

  const userCanDeleteReply = (replyUserId) => {
    if (user?.userType === 'admin' || replyUserId === user?._id) {
      return true;
    }
    return false;
  };

  const handleQADelete = (id, index) => {
    if (
      window.confirm(
        'Deleting this question will also delete all the replies. Are you sure want to delete the question?  ',
      )
    ) {
      axiosAuth
        .delete(`${API_ENDPOINTS.DELETE_QA_BY_LESSON_ID}/${id}`)
        .then((res) => {
          if (res?.data?.status) {
            notification.success({
              message: 'Question deleted successfully!!',
            });
            setAllQAs(R.remove(index, 1, allQAs));
          } else {
            notification.error({
              message:
                'Failed to delete question. Please try again or contact admin!!',
            });
          }
        });
    }
  };

  const handleQAReplyDelete = (replyId, replyIndex, qaId, index) => {
    if (window.confirm('Are you sure want to delete the reply?')) {
      axiosAuth
        .delete(`${API_ENDPOINTS.DELETE_REPLY_UNDER_QA}/${qaId}/${replyId}`)
        .then((res) => {
          if (res?.data?.status) {
            const data = JSON.parse(JSON.stringify(allQAs[index]));
            const newReplies = R.remove(replyIndex, 1, data.reply);
            setAllQAs(R.update(index, { ...data, reply: newReplies }, allQAs));
            notification.success({
              message: 'Reply deleted successfully!!',
            });
          } else {
            notification.error({
              message:
                'Failed to delete reply. Please try again or contact admin',
            });
          }
        });
    }
  };

  const handleReplyClick = (id) => {
    if (replyBoxId == id) {
      setReplyBoxId(null);
      return;
    }
    setReplyBoxId(id);
  };

  const handleAddReply = (id, qaIndex) => {
    setReplyAddLoading(true);
    axiosAuth
      .post(API_ENDPOINTS.ADD_REPLY_TO_LESSON, {
        content: replyContent,
        images: [],
        commentId: id,
      })
      .then((res) => {
        if (res?.data?.status) {
          setAllQAs(R.update(qaIndex, res?.data?.data, allQAs));
          setReplyContent('');
          notification.success({
            message: 'Reply added successfully',
          });
        } else {
          notification.error({
            message: 'Failed to add reply. Please try again or contact admin!!',
          });
        }
        setReplyAddLoading(false);
      });
  };

  if (QaLoading) {
    return (
      <CenteredDiv>
        <LoadingOutlined style={{ fontSize: 30, color: '#f9ae00' }} spin />
      </CenteredDiv>
    );
  }

  return (
    <div>
      {user?.userType === 'admin' || !user?.isBanned ? (
        <Row>
          <Col span={24}>
            <Editor
              editorState={editorData}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(editorState) => {
                setEditorData(editorState);
              }}
              placeholder="Please write your question here. Once you submit the question, it will be under the review phase."
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'fontSize',
                  'list',
                  'textAlign',
                  'history',
                ],
              }}
            />
          </Col>

          <StyledColFlex span={24}>
            <Button
              loading={loading}
              disabled={loading}
              onClick={handleQaCreate}
              type="primary"
            >
              Submit
            </Button>
          </StyledColFlex>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <h5>You have been banned from commenting.</h5>
          </Col>
        </Row>
      )}
      <div>
        {allQAs.map((item, index) => {
          const hasPermission = userCanDelete(item?.commentedBy?._id);
          const isReplyBoxOpen = replyBoxId === item?._id;
          return (
            <ModifiedCol key={item?._id}>
              <StyledRow>
                <Col xs={4} md={1}>
                  {item?.commentedBy?.profileImg ? (
                    <img
                      className="profile-image"
                      style={{
                        height: '45px',
                        width: '45px',
                        borderRadius: '50%',
                      }}
                      src={item?.commentedBy?.profileImg}
                      alt="profile"
                    />
                  ) : (
                    <div
                      className="initials-small"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span>{item?.commentedBy?.name?.charAt(0)}</span>
                    </div>
                  )}
                </Col>
                <Col xs={20} md={23}>
                  <LeftPaddedDiv>
                    <DetailsBox>
                      <UserName>{item?.commentedBy?.name ?? ''}</UserName>
                      {item?.createdAt && (
                        <DateText>
                          {moment(item?.createdAt).format('DD MMM YYYY')}
                        </DateText>
                      )}
                    </DetailsBox>

                    <ContentDiv>{HtmlParser(item?.content)}</ContentDiv>
                  </LeftPaddedDiv>
                </Col>
                <Col span={24}>
                  <ReplyTextDiv>
                    <StyledAnchor
                      href="#"
                      style={{ marginTop: '10px' }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleReplyClick(item?._id);
                      }}
                    >
                      {hasPermission
                        ? item?.reply?.length
                          ? 'See all replies'
                          : 'Reply'
                        : item?.reply?.length
                          ? isReplyBoxOpen
                            ? 'Hide all replies'
                            : 'See all replies'
                          : ''}
                    </StyledAnchor>
                  </ReplyTextDiv>
                </Col>
                {isReplyBoxOpen
                  && hasPermission
                  && (user?.userType === 'admin' || !user?.isBanned) && (
                    <Col span={24}>
                      <FlexDiv>
                        <StyledTextArea
                          placeholder="Please enter your reply"
                          allowClear
                          value={replyContent}
                          disabled={isReplyAddLoading}
                          onChange={({ target }) => {
                            setReplyContent(target?.value);
                          }}
                          autoSize={{
                            minRows: 3,
                            maxRows: 4,
                          }}
                        />
                        <Button
                          style={{ marginLeft: '5px' }}
                          type="text"
                          onClick={() => handleAddReply(item?._id, index)}
                        >
                          {isReplyAddLoading ? (
                            <Spin
                              style={{ color: 'black', fontSize: '18px' }}
                              size="small"
                            />
                          ) : (
                            <SendOutlined
                              style={{ color: 'black', fontSize: '18px' }}
                            />
                          )}
                        </Button>
                      </FlexDiv>
                    </Col>
                )}
                {isReplyBoxOpen && (
                  <Col span={24} style={{ marginTop: '10px' }}>
                    {item?.reply?.map((reply, replyIndex) => {
                      const userHasReplyPermission = userCanDeleteReply(
                        reply?.commentedBy?._id,
                      );
                      return (
                        <ReplyRow key={reply?._id}>
                          <Col xs={4} md={1}>
                            {reply?.commentedBy?.profileImg ? (
                              <img
                                className="profile-image"
                                style={{
                                  height: '45px',
                                  width: '45px',
                                  borderRadius: '50%',
                                }}
                                alt="profile-img"
                                src={reply?.commentedBy?.profileImg}
                              />
                            ) : (
                              <div
                                className="initials-small"
                                onClick={(e) => e.preventDefault()}
                                aria-hidden="true"
                              >
                                <span>
                                  {reply?.commentedBy?.name.charAt(0)}
                                </span>
                              </div>
                            )}
                          </Col>
                          <Col xs={20} md={23}>
                            <LeftPaddedDiv>
                              <DetailsBox>
                                <UserName>{reply?.commentedBy?.name}</UserName>
                                {reply?.createdAt && (
                                  <DateText>
                                    {moment(reply?.createdAt).format(
                                      'DD MMM YYYY',
                                    )}
                                  </DateText>
                                )}
                              </DetailsBox>
                              <ContentDiv>
                                {reply?.content}
                              </ContentDiv>
                            </LeftPaddedDiv>
                          </Col>
                          {userHasReplyPermission && (
                            <StyledDeleteIcon
                              onClick={() => handleQAReplyDelete(
                                reply?._id,
                                replyIndex,
                                item?._id,
                                index,
                              )}
                            />
                          )}
                        </ReplyRow>
                      );
                    })}
                  </Col>
                )}
              </StyledRow>

              {hasPermission && (
                <StyledDeleteIcon
                  onClick={() => handleQADelete(item?._id, index)}
                />
              )}
            </ModifiedCol>
          );
        })}
      </div>
      {hasMore && (
        <Row justify="center">
          <Button
            style={{ margin: '25px 0' }}
            loading={QaLoadMoreLoading}
            onClick={loadMoreQuestions}
          >
            <strong>Load More Q&A</strong>
          </Button>
        </Row>
      )}
    </div>
  );
};

export default LessonQA;

const ModifiedCol = styled.div`
  position: relative;
  min-height: 100px;
  display: flex;
  border: 1px solid #d1d7dc;
  border-radius: 5px;
  margin-bottom: 10px;
  background: white;
  padding: 10px;
  flex: 1;
  width: 100%;
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const ContentDiv = styled.div`
  padding: 10px 20px 0 0px;
`;

const ReplyTextDiv = styled.div`
  margin-top: 25px;
  padding-left: 5px;
`;

export const StyledTextArea = styled(TextArea)`
  border-radius: 8px;
  margin: 10px 0;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid #f9ae00;
  }
  .ant-input-clear-icon {
    top: 16px !important;
  }
  margin-bottom: 10px;
`;

export const StyledAnchor = styled.a`
  color: #f9ae00;
  font-weight: bold;
  &:hover,
  &:focus,
  &:active {
    color: #f9ae00;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const UserName = styled.div`
  font-weight: bold;
  font-size: 15px;
`;

export const DateText = styled.div`
  font-weight: bold;
  font-size: 10px;
  color: gray;
`;

export const DetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
`;

export const ReplyRow = styled(Row)`
  margin-bottom: 10px;
  border: 1px solid #d1d7dc !important;
  padding: 10px;
  border-radius: 5px;
`;

export const LeftPaddedDiv = styled.div`
  padding-left: 10px;
`;
