import { remove } from 'ramda';
import {
  RESET_CART,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SET_TO_CART,
  SET_COUPON_CODE,
} from '../actions/cart';

const initialState = {
  cart: [],
  coupon: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_CART:
      return {
        ...state,
        cart: [],
      };
    case ADD_TO_CART:
      return {
        ...state,
        cart: action.payload
          ? [...state.cart, action.payload]
          : [...state.cart],
      };
    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: remove(action.payload, 1, state.cart) || null,
      };
    case SET_TO_CART:
      return {
        ...state,
        cart: action.payload || [],
      };
    case SET_COUPON_CODE:
      return {
        ...state,
        coupon: action.payload || null,
      };
    default:
      return state;
  }
}

export default reducer;
