import React, { useCallback } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { GiNotebook } from 'react-icons/gi';
import RupeeBag from '../../assets/rupee-bag.png';
import gPlay from '../../assets/google-play.png';
import appStore from '../../assets/app-store.png';
import { SignUpNavItem } from '../../styledComponents';
import { getEnvValue } from '../../utils/helper';

export const SocialMediaButton = styled.a`
  height: 40px;
  line-height: 40px;
  min-width: 40px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  backface-visibility: hidden;
  transition: box-shadow 0.2s ease, border-color 0.2s ease, opacity 0.3s;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px;
  font-size: 0;
  border-radius: 4px;
  opacity: 1;
  margin: 6px;
  background-color: ${({ color }) => color || 'white'};
  &:hover {
    box-shadow: none;
    border-color: transparent;
    background-color: black;
  }
`;

export const SocialMediaIcon = styled.span`
  background: rgba(255, 255, 255, 0.1);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 12px;
  transition: all 0.2s ease;
  z-index: 2;
`;

export const SocialMediaText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  padding: 0 10px 0 44px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  box-sizing: border-box;
  transition: all 0.2s ease;
  backface-visibility: hidden;
  position: relative;
  z-index: 2;
  color: #fff;
`;

export const SocialMediaIconsContainer = styled.div`
  display: flex;
  margin: 10px 0px;
  a:first-child {
    margin-left: 0;
  }
`;

const ParentContainer = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;

const GreyText = styled.h4`
  color: grey;
  text-align: center;
  margin: 10px 0px 15px 0px;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 350px;
  }
`;

const RupeeImage = styled.img`
  height: 70px;
  width: 70px;
  margin-bottom: 10px;
`;

export const AppIconsImage = styled.img`
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

export const AppIconsContainer = styled.div`
  display: flex;
  width: 230px;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
`;

export const IconDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ThankYou = () => {
  const history = useHistory();
  const handleLinkClick = useCallback(() => {
    history.push('/');
  }, []);

  return (
    <Container>
      <ParentContainer>
        <ContentContainer>
          <RupeeImage src={RupeeBag} />
          {/* For image conversion */}
          {/* <img
            alt="purchase"
            height="1"
            width="1"
            style={{ display: 'none' }}
            src={`https://www.facebook.com/tr?id=${getEnvValue('FACEBOOK_PIXEL_ID')}&ev=Purchase&cd[currency]=INR&cd[value]=2499.00`}
          /> */}
          <Row justify="center">
            <GreyText>Thank you for registering for our course.</GreyText>
            <GreyText>
              Download our mobile app using below links to watch our course and
              get access to The Logical Traders Hub (Only available on mobile).
            </GreyText>
          </Row>
          <Row>
            <AppIconsContainer>
              <a
                href="https://play.google.com/store/apps/details?id=com.tltcourseapp"
                target="_blank"
                rel="noreferrer"
              >
                <IconDiv>
                  <p style={{ fontWeight: 'bold' }}>Android App</p>
                  <AppIconsImage src={gPlay} />
                </IconDiv>
              </a>
              <a
                href="https://apps.apple.com/us/app/the-logical-traders-course/id1663487740"
                target="_blank"
                rel="noreferrer"
              >
                <IconDiv>
                  <p style={{ fontWeight: 'bold' }}>iOS App</p>
                  <AppIconsImage src={appStore} />
                </IconDiv>
              </a>
            </AppIconsContainer>
          </Row>
          <Row justify="center">
            <GreyText>
              To just start watching the course on your current device, click on
              the button below
            </GreyText>
          </Row>
          <Row>
            <GoToDashboard
              onClick={handleLinkClick}
              className="pulse-coupon"
              isButton
            >
              <GiNotebook style={{ fontSize: '22px' }} />
              &nbsp; Start watching courses on website
            </GoToDashboard>
          </Row>
        </ContentContainer>
      </ParentContainer>
    </Container>
  );
};

export default ThankYou;

const GoToDashboard = styled(SignUpNavItem)`
  background: ${({ theme }) => theme.backgrounds.main};
  border: ${({ isButton, theme }) => isButton && `1px solid ${theme.backgrounds.main}`};
  display: flex;
  color: black !important;
  justify-content: space-evenly;
  align-items: center;
  max-width: 300px;
  height: 59px;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
`;
