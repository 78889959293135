import { CloseCircleOutlined } from '@ant-design/icons';
import { Row, Modal } from 'antd';
import React, { useState } from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import styled from 'styled-components';

const NavbarItem = styled.div`
  font-size: 16px;
  position: relative;
  display: inline-block;
  color: ${({ theme, isButton }) => (isButton ? theme.colors.black : theme.colors.lightgrey)};
  overflow: hidden;
  font-weight: 500;
  font-weight: ${({ isBold }) => isBold && 'bold !important'};
  background: ${({ theme, isButton }) => (!isButton
    ? `linear-gradient(to right, ${theme.backgrounds.main}, ${
      theme.backgrounds.main
    } 50%, ${isButton ? theme.colors.black : theme.colors.lightgrey} 50%)`
    : '')};
  background-clip: ${({ isButton }) => !isButton && 'text'};
  -webkit-background-clip: ${({ isButton }) => !isButton && 'text'};
  -webkit-text-fill-color: ${({ isButton }) => !isButton && 'transparent'};
  background-size: ${({ isButton }) => !isButton && '200% 100%'};
  background-position: ${({ isButton }) => !isButton && '100%'};
  transition: ${({ isButton }) => !isButton && 'background-position 275ms ease'};
  text-decoration: none;
  margin-right: 15px;
  border: ${({ isButton }) => isButton && '1px solid black'};
  border-radius: ${({ isButton }) => isButton && '6px'};
  padding: ${({ isButton }) => isButton && '0 20px'};
  line-height: ${({ isButton }) => isButton && '30px'};
  &:hover {
    cursor: pointer;
    background-position: 0 100%;
  }
  &:focus {
    outline: 0;
  }
`;

export const SignUpNavItem = styled(NavbarItem)`
  background: ${({ theme }) => theme.backgrounds.darkorchid};
  margin-right: 0;
  border: ${({ isButton, theme }) => isButton && `1px solid ${theme.backgrounds.darkorchid}`};
  color: ${({ theme }) => theme.colors.white};
  line-height: 40px;
  font-size: 20px;
  @media (max-width: 767px) {
    line-height: 35px !important;
    font-size: 16px !important;
    margin: 0 auto !important;
  }
`;

const JoinTelegramButton = styled(SignUpNavItem)`
  background: ${({ theme }) => theme.backgrounds.telegram};
  border: ${({ isButton, theme }) => isButton && `1px solid ${theme.backgrounds.telegram}`};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 10px;
  }
`;

const TelegramButton = ({ customStyle, justify }) => {
  const [isVisible, toggleModal] = useState(false);
  const handleToggleModal = () => {
    toggleModal(!isVisible);
  };
  return (
    <>
      <Modal
        visible={isVisible}
        onCancel={handleToggleModal}
        footer={null}
        className="telegram-popup"
      >
        <CloseCircleOutlined
          className="close-icon"
          onClick={handleToggleModal}
        />
        <Row justify="center">
          <h4>Telegram Channel Details</h4>
        </Row>
        <hr style={{ marginBottom: '25px' }} />
        <Row justify="center">
          <b>Channel Link:&nbsp;&nbsp;</b>
          <a target="_blank" href="https://t.me/TheLogicalTradersFree" rel="noreferrer">
            t.me/TheLogicalTradersFree (Click To Join)
          </a>
        </Row>
        <Row justify="center">
          <b>Channel Name:&nbsp;&nbsp;</b>
          TheLogicalTradersFree
        </Row>
        <Row justify="center" style={{ marginTop: '25px' }}>
          <p>
            <b>Note:&nbsp;&nbsp;</b>
            <span style={{ color: 'red' }}>
              The above channel link may not work for Jio users. Please search
              the channel name
              {' '}
              <span style={{ color: '#0088cc', fontWeight: 'bold' }}>
                "TheLogicalTradersFree"
              </span>
              {' '}
              on Telegram and join manually.
            </span>
          </p>
        </Row>
      </Modal>

      <Row justify={justify || 'center'} style={{ ...customStyle }}>
        <JoinTelegramButton
          className="pulse-telegram"
          isButton
          onClick={() => handleToggleModal()}
        >
          <FaTelegramPlane style={{ fontSize: '22px', marginRight: '10px' }} />
          JOIN TELEGRAM CHANNEL
        </JoinTelegramButton>
      </Row>
    </>
  );
};

export default TelegramButton;
