import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Row, Col } from "antd";
import { isMobileOnly, isTablet } from "react-device-detect";
import { StarFilled } from "@ant-design/icons";
import HeadingUnderline from "../HeadingUnderline";
import ayush from "../../assets/ayush-expert.png";
import harsh from "../../assets/harsh-expert.png";
import tick from "../../assets/yellow-tick.png";
import tipranksBadge from "../../assets/tipranksBadge.svg";

export const TextData = styled.ul`
  margin-bottom: 0px;
  li {
    margin-bottom: 20px;
    text-indent: -38px;
    margin-left: 38px;
    color: white;
    list-style-type: none;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    @media (max-width: 991px) {
      text-align: center;
    }
    @media (max-width: 767px) {
      text-align: left;
    }
  }
  @media (max-width: 991px) {
    padding-right: 0;
    margin-top: 20px;
    padding-left: 0;
  }
`;

export const TipRanksText = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  color: white;
`;

export const TipRanksTextMobile = styled.div`
  margin-top: 100px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  color: white;
  @media (max-width: 767px) {
    text-align: center !important;
  }
`;

export const TipRanks = styled.div`
  margin-top: 60px;
  margin-bottom: 35px;
  background-color: white;
  border: 3px solid #f9ae00;
  background: black;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  @media (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const TipRanksLogo = styled.img`
  position: absolute;
  height: 150px;
  top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 1024px) {
    top: -31px;
  }
  @media (max-width: 991px) {
    height: 170px;
  }
  @media (max-width: 767px) {
    top: -60px;
    height: 150px;
  }
`;

export const StarsRating = styled.div`
  margin-top: 10px;
  svg{
    margin-top: -7px;
  }
}
`;

const GreenTick = styled.img`
  margin-right: 10px;
  width: 20px;
`;

const ImageParent = styled.div`
  display: flex;
  justify-content: space-evenly;
  img {
    width: 160px;
    height: 175px;
  }
`;

const List = styled.ul`
  padding-left: 15px;
  margin-bottom: 0;
  li {
    margin-left: 32px;
    text-indent: -31px;
    list-style-type: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 50px;
  }
`;

const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

const CoveringDiv = styled.div`
  position: absolute;
  background: white;
  height: 100px;
  border-radius: 50%;
  width: 120px;
  margin-left: 6px;
  right: ${isTablet ? "30px" : "85px"};
  top: -20px;
  @media (max-width: 767px) {
    position: absolute;
    right: 85px;
    top: -32px;
  }
`;

const Experts = ({ hideHeading }) => (
  <Container>
    {!hideHeading && (
      <Row justify="center">
        <Col>
          <HeadingUnderline title="About The Creators" />
        </Col>
      </Row>
    )}
    <Row>
      <Col md={10} xs={24}>
        <ImageParent>
          <img src={ayush} alt="Ayush" />
          <img src={harsh} alt="Harsh" />
        </ImageParent>
      </Col>
      <Col md={14} xs={24}>
        <ContentDiv>
          <List>
            <li>
              <GreenTick src={tick} alt="tick" />
              Verified profitable track record in the Indian stock market.
            </li>
            <li>
              <GreenTick src={tick} alt="tick" />
              Over 15 years of track record of success in Indian & US markets.
            </li>
            <li>
              <GreenTick src={tick} alt="tick" />
              Ranked among top 5 financial bloggers in US markets.
            </li>
            <li>
              <GreenTick src={tick} alt="tick" />
              Over 3,000 articles published and over 2 crore page views
              garnered.
            </li>
          </List>
        </ContentDiv>
      </Col>
    </Row>
    <Row justify="center" style={{ width: "100%" }}>
      <Col span={20}>
        <TipRanks>
          <Row>
            <Col xs={0} sm={0} md={8}>
              {!isMobileOnly && (
                <>
                  <CoveringDiv />
                  <TipRanksLogo src={tipranksBadge} />
                </>
              )}
            </Col>
            <Col
              md={16}
              className={isMobileOnly && "text-center position-relative"}
            >
              {isMobileOnly && (
                <>
                  <CoveringDiv />
                  <TipRanksLogo src={tipranksBadge} />
                </>
              )}
              {!isMobileOnly ? (
                <TipRanksText>
                  Voted in Top 10 Financial Bloggers of Wall Street in 2014,
                  2020 & 2021.
                  <br />
                  <div style={{ display: "flex" }}>
                    <StarsRating>
                      Awarded
                      <StarFilled
                        style={{
                          color: "#F9AE00",
                          marginTop: "-5px",
                          marginLeft: "5px",
                        }}
                      />
                      <StarFilled
                        style={{
                          color: "#F9AE00",
                          marginTop: "-5px",
                        }}
                      />
                      <StarFilled
                        style={{
                          color: "#F9AE00",
                          marginTop: "-5px",
                        }}
                      />
                      <StarFilled
                        style={{
                          color: "#F9AE00",
                          marginTop: "-5px",
                        }}
                      />
                      <StarFilled
                        style={{
                          color: "#F9AE00",
                          marginTop: "-5px",
                          marginRight: "5px",
                        }}
                      />
                      Rating for International Stock Market Performance.
                    </StarsRating>
                  </div>
                </TipRanksText>
              ) : (
                <TipRanksTextMobile>
                  Voted in Top 10 Financial Bloggers of Wall Street in 2014,
                  2020 & 2021.
                  <br />
                  <div style={{ display: "flex" }}>
                    <StarsRating>
                      Awarded
                      <span>
                        <StarFilled
                          style={{
                            color: "#F9AE00",
                            marginTop: "-5px",
                            marginLeft: "5px",
                          }}
                        />
                        <StarFilled
                          style={{
                            color: "#F9AE00",
                            marginTop: "-5px",
                          }}
                        />
                        <StarFilled
                          style={{
                            color: "#F9AE00",
                            marginTop: "-5px",
                          }}
                        />
                        <StarFilled
                          style={{
                            color: "#F9AE00",
                            marginTop: "-5px",
                          }}
                        />
                        <StarFilled
                          style={{
                            color: "#F9AE00",
                            marginTop: "-5px",
                            marginRight: "5px",
                          }}
                        />
                      </span>
                      Rating for International Stock Market Performance.
                    </StarsRating>
                  </div>
                </TipRanksTextMobile>
              )}
            </Col>
          </Row>
        </TipRanks>
      </Col>
    </Row>
  </Container>
);

export default Experts;
