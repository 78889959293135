import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import {
  Button, Col, Divider, List, Row,
} from 'antd';
import styled from 'styled-components';
import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Spacer } from '../about';
import HeadingUnderline from '../../components/HeadingUnderline';
import { PayContainer } from '../../styledComponents';
import PayByRazorPay from '../../components/RazorPay';
import { triggerGAEvent } from '../../utils/helper';
import { axiosAuth } from '../../api';
import { baseUrl, SafeCheckoutImg } from '../checkout';
import {
  authResetCart,
  authRemoveFromCart,
  authSetToCart,
} from '../../actions/authcart';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { SET_USER_DATA } from '../../actions/common';
import { FREE_COURSE } from '../../constant';
import { getExpiryDate } from '../dashboard/CourseCounter';
import { COURSE_PLAN_DETAILS } from '../../components/BuyCourseButton';
import { CourseDetailTextStrike } from '../cart';
import { theme } from '../../utils/theme';

const GrayRow = styled(Row)`
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
  position: relative;
  margin-bottom: 10px;
`;

const FullWidthImage = styled.img`
  width: 100%;
  border-radius: 8px;
`;

export const CourseDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 10px;
`;

export const CourseDetailText = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export const CourseDetailSubText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #f9ae00;
`;

export const CoursePrice = styled(CourseDetails)`
  align-items: flex-end;
`;

export const CloseCircleOutlinedIcon = styled(CloseCircleOutlined)`
  font-size: 25px;
  position: absolute;
  right: 10px;
  z-index: 999;
  &:hover {
    cursor: pointer;
  }
`;

export const NoItemsFoundText = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AuthCart = () => {
  const childRef = useRef();
  const history = useHistory();
  const coursesInCart = useSelector((state) => state.authcart.cart);
  const userState = useSelector((state) => state.common.user);
  const [loadingCart, setLoadingCart] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : {};
  const dispatch = useDispatch();

  useEffect(() => {
    setLoadingCart(true);
    axiosAuth
      .get(API_ENDPOINTS.GET_CART)
      .then((res) => {
        if (res?.data?.data?.length) {
          dispatch(authSetToCart(res?.data?.data[0]?.courses ?? []));
          setLoadingCart(false);
        }
      })
      .catch(() => {
        setLoadingCart(false);
      });
  }, []);

  const handleCourseRemove = (index, data) => {
    axiosAuth.post(API_ENDPOINTS.REMOVE_FROM_CART, { courseId: data._id });
    dispatch(authRemoveFromCart(index));
  };

  const countDownDate = new Date(getExpiryDate(userState?.createdAt)).getTime();
  const finalTime = countDownDate - new Date().getTime();

  const paymentDetails = useMemo(() => {
    const discountedCoursesInCart = JSON.parse(JSON.stringify(coursesInCart));
    // if (finalTime > 0) {
    //   const psychologyCourseIndex = coursesInCart.findIndex(
    //     (x) => x.slug === FREE_COURSE.slug,
    //   );
    //   discountedCoursesInCart = R.update(
    //     psychologyCourseIndex,
    //     { ...coursesInCart[psychologyCourseIndex], course_amount: 499 },
    //     coursesInCart,
    //   );
    // }

    const totalPrice = R.pipe(
      R.map(R.prop('course_amount')),
      R.sum,
    )(discountedCoursesInCart);
    const allCoursesName = R.map(
      R.prop('course_name'),
      discountedCoursesInCart,
    );
    return {
      amount: parseInt(`${totalPrice}00`, 10),
      description: allCoursesName.join(', '),
    };
  }, [coursesInCart, finalTime]);

  const totalPrice = R.pipe(R.map(R.prop('course_amount')), R.sum)(coursesInCart);

  // if (finalTime > 0) {
  //   let discountedCoursesInCart = JSON.parse(JSON.stringify(coursesInCart));
  //   const psychologyCourseIndex = coursesInCart.findIndex(
  //     (x) => x.slug === FREE_COURSE.slug,
  //   );
  //   discountedCoursesInCart = R.update(
  //     psychologyCourseIndex,
  //     { ...coursesInCart[psychologyCourseIndex], course_amount: 499 },
  //     coursesInCart,
  //   );
  //   totalPrice = R.pipe(
  //     R.map(R.prop('course_amount')),
  //     R.sum,
  //   )(discountedCoursesInCart);
  // }

  const handleCheckoutClick = () => {
    // const offerCountDownDate = new Date(
    //   getExpiryDate(userState?.createdAt),
    // ).getTime();
    // const finalOfferTime = offerCountDownDate - new Date().getTime();
    // if (finalOfferTime < 1200 && totalPrice < 500) {
    //   toast('Offer expired. Refreshing to get latest price', {
    //     type: toast.TYPE.ERROR,
    //   });
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 2000);
    //   return;
    // }
    setLoading(true);
    const emailAddress = user?.email?.split('@');
    axiosAuth
      .post('/order/create', {
        amount: paymentDetails.amount,
        name: user?.name,
        email: user?.email,
        entity: 'course',
      })
      .then((res) => {
        const data = {
          category: 'Order',
          action: 'Order Created',
          label: `${emailAddress[0] || ''} - ${user?.name || ''} - ${
            user?.contactNo || ''
          } - ${emailAddress[1] || ''}`,
          value: `${user?.name ?? ''}-${user?.contactNo ?? ''}-${
            res?.data?.data?.orderId
          }`,
        };
        triggerGAEvent(data);
        childRef.current.handlePayment(res?.data?.data?.orderId);
      })
      .catch(() => {
        toast('Failed to complete payment process. Please try again', {
          type: toast.TYPE.ERROR,
        });
        const data = {
          category: 'Order',
          action: 'Order Creation Failed',
          label: `${emailAddress[0] || ''} - ${user?.name || ''} - ${
            user?.contactNo || ''
          } - ${emailAddress[1] || ''}`,
          value: `${user?.name || ''} ${user?.contactNo || ''}`,
        };
        triggerGAEvent(data);
        setLoading(false);
      });
  };

  const handleLinkClick = (path) => {
    history.push(path);
  };

  const handlePaymentSuccess = (paymentResponse = {}) => {
    if (paymentResponse.razorpay_payment_id) {
      const purchasedCourseDetails = coursesInCart.map((x) => ({
        amount: x.course_amount,
        courseId: x._id,
        startDate: moment().format('YYYY/MM/DD'),
        isRefundPeriodOver: false,
        course_name: x.course_name,
        slug: x.slug,
        txnId: paymentResponse.razorpay_payment_id,
      }));
      axiosAuth
        .post(API_ENDPOINTS.PURCHASE_COURSE, purchasedCourseDetails)
        .then((innerRes) => {
          if (innerRes?.data?.status) {
            toast('Purchase Completed', { type: toast.TYPE.SUCCESS });
            axiosAuth.get(API_ENDPOINTS.GET_USER_PROFILE).then((res) => {
              if (res?.data?.data?._id) {
                localStorage.setItem('user', JSON.stringify(res.data.data));
                dispatch({
                  type: SET_USER_DATA,
                  payload: { user: res?.data?.data ?? null },
                });
              }
            });
            dispatch(authResetCart());
            const removeAllCoursesFromCart = coursesInCart.map((course) => axiosAuth.post(API_ENDPOINTS.REMOVE_FROM_CART, {
              courseId: course._id,
            }));
            Promise.all(removeAllCoursesFromCart)
              .then(() => {
                window.location.replace('/dashboard');
              })
              .catch((_) => {
                handleLinkClick('/dashboard');
              });
          }
        });
    }
  };

  if (loadingCart) {
    return (
      <Container>
        <Spacer />
        <HeadingUnderline title="Cart" />
        <CenteredDiv>
          <LoadingOutlined
            style={{
              fontSize: 40,
              color: theme.backgrounds.main,
            }}
            spin
          />
          <Spacer />
          <p>Loading your cart. Please wait...</p>
        </CenteredDiv>
      </Container>
    );
  }

  return (
    <Container>
      <Spacer />
      <HeadingUnderline title="Cart" />
      {coursesInCart?.length ? (
        <>
          <List
            itemLayout="horizontal"
            dataSource={coursesInCart}
            renderItem={(item, index) => {
              const isPsychologyCourseInCart = item.slug === FREE_COURSE.slug;
              return (
                <GrayRow key={index}>
                  <CloseCircleOutlinedIcon
                    onClick={() => handleCourseRemove(index, item)}
                  />
                  <Col md={6}>
                    <FullWidthImage src={item.course_image} />
                  </Col>
                  <Col md={12}>
                    <CourseDetails>
                      <CourseDetailText>
                        {item.course_name ?? ''}
                      </CourseDetailText>
                      <CourseDetailSubText>
                        Lectures:
                        {' '}
                        {item.course_lessons}
                        {' '}
                        | Duration:
                        {' '}
                        {item.course_duration}
                        {' '}
                        | Validity:
                        {' '}
                        {item.course_validity}
                      </CourseDetailSubText>
                    </CourseDetails>
                  </Col>
                  <Col md={6}>
                    <CoursePrice>
                      <div style={{ display: 'flex' }}>
                        {/* <CourseDetailTextStrike>
                          &#8377;
                          {item.base_amount || '3125'}
                        </CourseDetailTextStrike>
                        &nbsp;&nbsp; */}
                        <CourseDetailText>
                          &#8377;
                          {item.course_amount}
                        </CourseDetailText>
                      </div>
                      <CourseDetailSubText>
                        Inclusive of all charges
                      </CourseDetailSubText>
                      {/* <CourseDetailSubText>
                        <TelegramColor>
                          20% Off - &#8377;
                          {Number(item.base_amount || "3125") -
                            Number(item.course_amount)}
                        </TelegramColor>

                      </CourseDetailSubText> */}
                    </CoursePrice>
                  </Col>
                </GrayRow>
              );
            }}
          />
          <Divider />
          <Row>
            <Col md={6}>
              <CourseDetailText>Total</CourseDetailText>
            </Col>
            <Col md={12} />
            <Col md={6}>
              <CoursePrice>
                <CourseDetailText>
                  &#8377;&nbsp;
                  {totalPrice}
                </CourseDetailText>
              </CoursePrice>
            </Col>
          </Row>
          <Divider />
          <ActionButtonContainer>
            <Button
              onClick={handleCheckoutClick}
              className="signUpBtn"
              loading={loading}
              disabled={loading}
              size="large"
            >
              Complete Purchase
            </Button>
          </ActionButtonContainer>
          <Spacer />
          <Spacer />
          <Divider />
          <PayContainer>
            <PayByRazorPay
              ref={childRef}
              description={paymentDetails.description}
              amount={paymentDetails.amount}
              handlePaymentSuccess={handlePaymentSuccess}
              name={user?.name}
              contact={user?.contactNo}
              email={user?.email}
              setLoading={setLoading}
            />
            <Row>
              <Container>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <SafeCheckoutImg src={`${baseUrl}/gsc.png`} />
                </div>
              </Container>
            </Row>
          </PayContainer>
        </>
      ) : (
        <NoItemsFoundText>No items in the cart</NoItemsFoundText>
      )}
    </Container>
  );
};

export default AuthCart;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
