import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, Col, Row, Table, Tag,
} from 'antd';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import Modal from 'antd/lib/modal/Modal';
import ReactExport from 'react-export-excel';
import Search from 'antd/lib/input/Search';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { numberWithCommas } from '../../utils/helper';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { axiosAuth } from '../../api';
import { AnalyticsBox } from '../adminDashboard';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const UsersContainer = styled.div`
  margin-top: 40px;
  padding: 0 20px;
`;

const AllUsers = ({ common }) => {
  const user = common?.user?.userType || '';
  const [allUsersList, setUsersList] = useState([]);
  const [allAdminUsersList, setAdminUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seeViewModal, toggleViewModal] = useState(false);
  const [userLoading, setUserLoading] = useState(null);
  const [userProfitableLoading, setUserProfitableLoading] = useState(null);
  const [viewData, setViewData] = useState({});
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const history = useHistory();
  const [refundLoading, setRefundLoading] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);

  const getUsers = () => {
    axiosAuth
      .get(`${API_ENDPOINTS.ALL_USERS}?limit=20&offset=${currentPage * 20}`)
      .then((res) => {
        if (res?.data?.data) {
          const finalData = res?.data?.data.sort((a, b) => moment(b.createdAt).diff(a.createdAt));
          setUsersList(finalData.filter((item) => item.userType == 'normal'));
          setAdminUsersList(
            finalData.filter((item) => item.userType == 'admin'),
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleStatusChange = useCallback(
    (email, oldStatus) => {
      setUserLoading(email);
      axiosAuth
        .post(API_ENDPOINTS.CHANGE_USER_STATUS, {
          email,
          active: !oldStatus,
        })
        .then((res) => {
          getUsers();
          setUserLoading(null);
        })
        .catch((err) => {
          setUserLoading(null);
        });
    },
    [allUsersList],
  );

  const handleBannedStatusChange = useCallback(
    (email, oldStatus) => {
      setUserProfitableLoading(email);
      axiosAuth
        .post(API_ENDPOINTS.CHANGE_BAN_STATUS, {
          isBanned: !oldStatus,
          email,
        })
        .then((res) => {
          getUsers();
          setUserProfitableLoading(null);
        })
        .catch((err) => {
          setUserProfitableLoading(null);
        });
    },
    [allUsersList],
  );

  const getAnalytics = () => {
    axiosAuth.get(API_ENDPOINTS.ADMIN_ANALYTICS).then((res) => {
      setAnalyticsData(res?.data?.data ?? null);
    });
  };

  useEffect(() => {
    const localuser = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : {};
    if (localuser?.userType !== 'admin') {
      history.push('/');
      return;
    }
    setLoading(true);
    getUsers();
    getAnalytics();
  }, []);

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      key: 'sno',
      render: (sno, data, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email, data) => {
        const isTltUser = data.purchasedCourses.find(
          (x) => x.userType === 'subscription',
        );
        return (
          <div style={{ position: 'relative' }}>
            {email}
            {isTltUser && (
              <div style={{ position: 'absolute', top: '-25px' }}>
                <Tag color="#f50">Subscription User</Tag>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Contact',
      dataIndex: 'contactNo',
      key: 'contactNo',
      render: (contactNo) => <span>{contactNo}</span>,
    },
    {
      title: 'Courses',
      dataIndex: 'purchasedCourses',
      render: (purchasedCourses) => {
        const isRefunded = purchasedCourses.find((p) => p.isRefunded === true);
        return (
          <div style={{ position: 'relative' }}>
            {purchasedCourses?.length
              ? purchasedCourses?.map((x) => x?.course_name || '').join(', ')
              : 'N/A'}
            {isRefunded && (
              <div style={{ position: 'absolute', top: '-25px' }}>
                <Tag color="#f50">Refund issued</Tag>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (createdAt) => moment(createdAt).format('DD-MM-YYYY hh:mm'),
    },
    {
      title: 'Actions',
      dataIndex: 'purchasedCourses',
      key: 'purchasedCourses',
      width: 250,
      render: (purchasedCourses, data, index) => (
        <div style={{ display: 'flex', maxWidth: '250px', flexWrap: 'wrap' }}>
          <Button
            onClick={() => handleStatusChange(data.email, data.active)}
            loading={userLoading === data.email}
            type={data.active ? 'primary' : 'danger'}
            style={{ marginRight: '5px', marginBottom: '5px' }}
          >
            {data.active ? 'Deactivate' : 'Activate'}
          </Button>
          <Button
            onClick={() => handleBannedStatusChange(data.email, data.isBanned)}
            loading={userProfitableLoading === data.email}
            type={!data.isBanned ? 'success' : 'danger'}
            style={{
              marginRight: '5px',
              marginBottom: '5px',
              background: !data.isBanned && 'green',
              color: !data.isBanned && 'white',
            }}
          >
            {data.isBanned ? 'Remove Ban' : 'Ban User'}
          </Button>
          <Button
            onClick={() => {
              toggleViewModal(true);
              setViewData(data);
            }}
          >
            View Details
          </Button>
        </div>
      ),
    },
  ];

  const handleIssueRefund = (data) => {
    setRefundLoading(data?._id);
    axiosAuth
      .post(API_ENDPOINTS.MAKE_REFUND, {
        purchasedCourseId: data._id,
        email: data.email,
      })
      .then((res) => {
        setRefundLoading(null);
        toast('Refunded successfully!!', {
          type: toast.TYPE.SUCCESS,
        });
      })
      .catch(() => {
        toast('Failed to refund. Please try again!!', {
          type: toast.TYPE.ERROR,
        });
        setRefundLoading(null);
      });
  };

  const handlePageChange = () => {
    setLoadingMore(true);
    axiosAuth
      .get(
        `${API_ENDPOINTS.ALL_USERS}?limit=20&offset=${(currentPage + 1) * 20}`,
      )
      .then((res) => {
        if (res?.data?.data) {
          if (res?.data?.data?.length < 20) {
            setHasMore(false);
          }
          setCurrentPage(currentPage + 1);
          const finalData = [...res?.data?.data, ...allUsersList].sort((a, b) => moment(b.createdAt).diff(a.createdAt));
          setUsersList(finalData.filter((item) => item.userType == 'normal'));
          setAdminUsersList(
            finalData.filter((item) => item.userType == 'admin'),
          );
        }
        setLoadingMore(false);
      })
      .catch(() => {
        setLoadingMore(false);
      });
  };

  const handleShowAllClick = () => {
    setLoadingMore(true);
    axiosAuth
      .get(`${API_ENDPOINTS.ALL_USERS}?limit=10000&offset=0`)
      .then((res) => {
        if (res?.data?.data) {
          setCurrentPage(currentPage + 1);
          const finalData = [...res?.data?.data].sort((a, b) => moment(b.createdAt).diff(a.createdAt));
          setUsersList(finalData.filter((item) => item.userType === 'normal'));
          setAdminUsersList(
            finalData.filter((item) => item.userType === 'admin'),
          );
          setHasMore(false);
        }
        setLoadingMore(false);
      })
      .catch(() => {
        setLoadingMore(false);
      });
  };

  const { purchasedCourses = [] } = viewData || {};
  const modifiedPurchasedCourses = purchasedCourses.map((item) => ({
    ...item,
    email: viewData.email,
  }));
  const purchasedCoursesColumns = [
    {
      title: 'Course Name',
      dataIndex: 'course_name',
      key: 'course_name',
    },
    {
      title: 'Paid Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => <div>{numberWithCommas(amount)}</div>,
    },
    {
      title: 'Transaction Id',
      dataIndex: 'txnId',
      key: 'txnId',
      render: (txnId) => <div>{txnId}</div>,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate) => (
        <div>{moment(startDate).format('DD MMM YYYY')}</div>
      ),
    },
    {
      title: 'Is Refund Period Over',
      dataIndex: 'isRefundPeriodOver',
      key: 'isRefundPeriodOver',
      render: (isRefundPeriodOver) => (
        <div>{isRefundPeriodOver ? 'Yes' : 'No'}</div>
      ),
    },
    {
      title: 'Refunded',
      dataIndex: 'isRefunded',
      key: 'isRefunded',
      render: (isRefunded) => <div>{isRefunded ? 'Yes' : 'No'}</div>,
    },
    {
      title: 'Action',
      render: (_, data) => (
        <div>
          <Button
            loading={refundLoading === data?._id}
            onClick={() => handleIssueRefund(data)}
            disabled={refundLoading === data?._id}
            danger
          >
            Issue Refund
          </Button>
        </div>
      ),
    },
  ];

  const filteredUsers = search
    ? allUsersList.filter(
      (item) => (item.name || '').toLowerCase().includes(search.toLowerCase())
          || (item.email || '').toLowerCase().includes(search.toLowerCase())
          || (item.contactNo || 0).toString().includes(search.toLowerCase()),
    )
    : allUsersList;

  return (
    <Container>
      <UsersContainer>
        <ExcelFile
          filename="All Course Users"
          element={(
            <Button type="primary" className="export-users">
              Export Users Excel
            </Button>
          )}
        >
          <ExcelSheet data={allUsersList} name="All Users">
            <ExcelColumn label="Name" value="name" />
            <ExcelColumn label="Email" value="email" />
            <ExcelColumn label="Contact" value="contactNo" />
            <ExcelColumn label="event_time" value="createdAt" />
          </ExcelSheet>
        </ExcelFile>
        <Modal
          title="Details"
          visible={seeViewModal}
          onCancel={() => toggleViewModal(false)}
          footer={null}
          maskClosable={false}
        >
          <h4>General Details</h4>
          <p>
            Name:
            {' '}
            {viewData?.name}
          </p>
          <p>
            Email:
            {' '}
            {viewData?.email}
          </p>
          <h4>Purchased Courses Details</h4>
          <Table
            dataSource={modifiedPurchasedCourses ?? []}
            columns={purchasedCoursesColumns}
            pagination={false}
            style={{ overflowY: 'scroll' }}
          />
        </Modal>
        {user == 'admin' ? (
          <>
            <Row className="mb-10">
              <Col span={8}>
                <Search
                  placeholder="input search text"
                  onChange={({ target }) => setSearch(target.value)}
                />
              </Col>
            </Row>

            <h4>User Analytics</h4>
            <Row style={{ marginBottom: '15px' }}>
              <AnalyticsBox
                color="green"
                count={analyticsData?.normalUserCount || 0}
                text="Active Course Users"
              />
              <AnalyticsBox
                color="green"
                count={analyticsData?.subscriptionUserCount || 0}
                text="Total Subscription Users"
              />
              <AnalyticsBox
                color="green"
                count={analyticsData?.refundedUserCount || 0}
                text="Total Refunds"
              />
            </Row>

            <h4>
              All Users
              {' '}
              <Button
                disabled={loadingMore}
                loading={loadingMore}
                onClick={handleShowAllClick}
              >
                Show All
              </Button>
            </h4>
            <Table
              loading={loading}
              dataSource={filteredUsers}
              columns={columns}
              style={{ overflowY: 'scroll' }}
              pagination={false}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '15px 0 25px 0',
              }}
            >
              <Button
                loading={loadingMore}
                disabled={loadingMore || !hasMore}
                type="primary"
                onClick={handlePageChange}
              >
                {!hasMore ? "That's all folks" : 'Load More'}
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
        {user == 'admin' ? (
          <>
            <h4>Admin Users</h4>
            <Table
              loading={loading}
              dataSource={allAdminUsersList}
              columns={columns}
              style={{ overflowY: 'scroll' }}
              pagination={{
                pageSizeOptions: [10, 20, 30],
                defaultPageSize: 10,
                showSizeChanger: true,
              }}
            />
          </>
        ) : (
          <></>
        )}
      </UsersContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

export default connect(mapStateToProps, null)(AllUsers);
