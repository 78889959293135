import { Col, Row } from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { Spacer } from '../../routes/about';
import FAQS from '../../routes/faqs';
import { StyledResultText } from './AboutCourse';
import ActionButton from './ActionButton';
import { ColoredContainer } from './Common';

const CourseFaqs = () => (
  <GrauColoredContainer>
    <WhiteStyledResultText>FAQs</WhiteStyledResultText>
    <Row justify="center">
      <SupportText>
        We&apos;ve tried our best to answer all the frequently asked questions. If
        you still have any doubts, please feel free to email us at
        <a href="mailto:support@thelogicaltraders.com">
          &nbsp;support@thelogicaltraders.com
        </a>
        {' '}
        & we will get back to you ASAP.
      </SupportText>
    </Row>
    <FAQS landingPage />
    {!isMobile && (
      <Row>
        <Col span={3} />
        <Col span={18}>
          <Spacer />
          <Spacer />
          <ActionButton />
        </Col>
        <Col span={3} />
      </Row>
    )}
  </GrauColoredContainer>
);

export default CourseFaqs;

const GrauColoredContainer = styled(ColoredContainer)`
  background: #fafafa;
  min-height: inherit;
`;

const WhiteStyledResultText = styled(StyledResultText)`
  color: black;
`;

const SupportText = styled.p`
  width: ${isMobile ? '100%' : '60%'};
  margin: 0 auto;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 20px;
`;
