import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { axiosAuth } from '../../api';
import { Spacer } from '../about';

const UsersContainer = styled.div`
  margin-top: 40px;
  padding: 0 20px;
`;

const AllRatings = ({ common }) => {
  const user = common?.user?.userType || '';
  const [allRatingsList, setRatingsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const getUsers = () => {
    axiosAuth
      .get(API_ENDPOINTS.ALL_RATINGS)
      .then((res) => {
        if (res?.data?.data) {
          setRatingsList(res?.data?.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const userData = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : {};
    if (userData?.userType !== 'admin') {
      history.push('/');
      return;
    }
    setLoading(true);
    getUsers();
  }, []);

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      key: 'sno',
      render: (sno, data, index) => index + 1,
    },
    {
      title: 'User',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (createdBy) => <span>{createdBy?.name ?? '-'}</span>,
    },
    {
      title: 'Course',
      dataIndex: 'courseSlug',
      key: 'courseSlug',
      render: (courseSlug) => <span>{courseSlug ?? '-'}</span>,
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      render: (rating) => <span>{parseFloat(rating / 20, 10)}</span>,
    },
    {
      title: 'Comment',
      dataIndex: 'review',
      key: 'review',
      render: (review) => <span>{review}</span>,
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (createdAt) => moment(createdAt).format('DD-MM-YYYY hh:mm'),
    },
  ];

  return (
    <Container>
      <UsersContainer>
        {user === 'admin' ? (
          <>
            <h4>All Ratings</h4>
            <Table
              loading={loading}
              dataSource={allRatingsList}
              columns={columns}
              style={{ overflowY: 'scroll' }}
              pagination={{
                pageSizeOptions: [100, 200, 500],
                defaultPageSize: 500,
                showSizeChanger: true,
              }}
            />
          </>
        ) : (
          <></>
        )}
      </UsersContainer>
      <Spacer />
      <Spacer />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

export default connect(mapStateToProps, null)(AllRatings);
