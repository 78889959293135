/* eslint-disable react/prop-types */
import { Button, Col, Row } from 'antd';
import React from 'react';
import { Container } from 'react-bootstrap';
import { isMobile, isTablet } from 'react-device-detect';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { Spacer } from '../../routes/about';
import {
  handleVideoPlayTracking,
} from '../../utils/helper';
import BuyCourseButton from '../BuyCourseButton';

const HomeBackground = styled.div`
  width: auto;
  height: ${isTablet ? '800px' : '500px'};
  background-position: center;
  background-repeat: repeat;
  display: flex;
  align-items: center;
  justify-content: ${isTablet ? 'center' : 'inherit'};
  flex-direction: ${isTablet ? 'column' : 'row'};
  @media (max-width: 767px) {
    padding: 10px;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const MainHeadingText = styled.h1`
  color: white;
  font-size: 28px;
`;

const BlackText = styled.span`
  color: black;
  font-size: 28px;
  font-weight: bold;
`;

const BlackBoldText = styled(BlackText)`
  color: black;
  font-weight: normal;
  font-size: 20px;
`;

const Flex1Div = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const SubHeading = styled.h4``;

const ReturnsText = styled.h4`
  font-size: 18px;
  margin-top: 10px;
`;

const VideoDiv = styled(Flex1Div)`
  justify-content: center;
  align-items: center;
  display: flex;
`;

const ButtonsDiv = styled.div`
  display: flex;
  margin: 20px 0;
`;

const ProofButton = styled(Button)`
  background: white;
  border: 2.5px solid #9932CC;
  box-sizing: border-box;
  box-shadow: 0px 4.42006px 3.86756px rgba(0, 0, 0, 0.35);
  border-radius: 30px;
  margin-right: 25px;
  color: #9932CC;
  font-weight: bold;
  height: 44px;
  font-size: 16px;
  width: 200px;
  &:hover, &:active, &:focus {
    background: white !important;
    border: 2.5px solid #9932CC !important;
    color: #9932CC !important;
    box-shadow: 0px 6.42006px 5.86756px rgba(0, 0, 0, 0.35) !important;
  }
  @media (max-width: 767px) {
    width: 250px;
  }
`;

const MobileMainHeadingText = styled(MainHeadingText)`
  text-align: center;
  font-size: 25px;
  margin-bottom: 40px;
`;

const MobileSubHeading = styled(SubHeading)`
  text-align: center;
  margin-bottom: 30px;
  color: white;
`;

const MobileButtonContainer = styled.div`
  height: 80px;
  margin-bottom: 40px;
  button {
    margin-right: 10px;
  }
`;

const HomeBg = ({ executeScroll }) => {
  const Content = () => (
    <Row>
      <Col span={24}>
        <HomeBackground>
          <Row style={{ width: '100%', height: '100%' }}>
            <Col style={{ height: '100%' }} xs={24} sm={24} md={12}>
              <Flex1Div>
                <MainHeadingText>
                  KNOW THE 33 TRADING
                  <br />
                  SECRETS WE USED TO TURN
                  <br />
                  {' '}
                  <BlackText>&#8377;1.55 LAKH to &#8377;31.43 LAKH</BlackText>
                  <br />
                </MainHeadingText>
                <SubHeading>
                  India’s Only Trading Course Made by Experts
                  <br />
                  Who Show Their
                  {' '}
                  <BlackBoldText><strong>Long-Term Performance</strong></BlackBoldText>
                </SubHeading>
                <ReturnsText>
                  <BlackBoldText>2020 Returns: 1497.74%</BlackBoldText>
                  <br />
                  <BlackBoldText>2021 Returns: 119.20%</BlackBoldText>
                </ReturnsText>
                <ButtonsDiv>
                  <BuyCourseButton />
                  <ProofButton onClick={executeScroll}>See Proof of Returns</ProofButton>
                </ButtonsDiv>
              </Flex1Div>
            </Col>
            <Col style={{ height: '100%' }} xs={24} sm={24} md={12}>
              <VideoDiv>
                <div className="react-video-player-custom">
                  <ReactPlayer
                    url="https://vimeo.com/670118315"
                    controls
                    width={isMobile ? '400px' : '480px'}
                    height={isMobile ? '225px' : '270px'}
                    pip
                    loop
                    // onPlay={() => handleVideoPlayTracking(1)}
                  />
                </div>
              </VideoDiv>
            </Col>
          </Row>
        </HomeBackground>
      </Col>
    </Row>
  );

  const MobileContent = () => (
    <Row>
      <Col span={24}>
        <HomeBackground>
          <MobileMainHeadingText>
            KNOW THE 33 TRADING
            <br />
            SECRETS WE USED TO TURN
            <br />
            <BlackText>&#8377;1.55 LAKH to &#8377;31.43 LAKH</BlackText>
          </MobileMainHeadingText>
          <MobileSubHeading>
            India’s Only Trading Course Made
            <br />
            by Experts Who Show Their
            <br />
            <BlackBoldText><strong>Long-Term Performance</strong></BlackBoldText>
          </MobileSubHeading>
          <MobileButtonContainer>
            <ReturnsText>
              <BlackBoldText>2020 RETURNS: 1497.74%</BlackBoldText>
              <br />
              <BlackBoldText>2021 RETURNS: 119.20%</BlackBoldText>
            </ReturnsText>
          </MobileButtonContainer>
          <MobileButtonContainer>
            <BuyCourseButton />
            <br />
            <Spacer />
            <ProofButton onClick={executeScroll}>See Proof of Returns</ProofButton>
          </MobileButtonContainer>
        </HomeBackground>
      </Col>
    </Row>
  );

  return isMobile ? (
    <MobileContent />
  ) : (
    <Container>
      <Content />
    </Container>
  );
};

export default HomeBg;
