import React from "react";
import { isMobile } from "react-device-detect";
import { Col, Row } from "antd";
import Footer from "../../components/Footer";
import AboutCourse from "../../components/LandingPage/AboutCourse";
import BannerSection from "../../components/LandingPage/BannerSection";
import Checklist from "../../components/LandingPage/Checklist";
import FeaturedOn from "../../components/LandingPage/FeaturedOn";
import "../../fonts/Gilroy/Gilroy-Regular.ttf";
import "../../fonts/Gilroy/Gilroy-Bold.ttf";
import BetterThanOther from "../../components/LandingPage/BetterThanOther";
import OurExperts from "../../components/LandingPage/OurExperts";
import CourseFaqs from "../../components/LandingPage/CourseFaqs";
import ActionButton from "../../components/LandingPage/ActionButton";
import VideoTestimonial from "../../components/LandingPage/VideoTestimonial";
import WhatYouGet from "../../components/WhatYouGetCourse";

const LandingPage = () => (
  <div>
    <BannerSection />
    <FeaturedOn />
    {/* <VideoTestimonial /> */}
    {/* <Row className="black-bg" style={{ padding: '0 0 30px 0' }}>
      <Col span={24}>
        <WhatYouGet />
      </Col>
    </Row> */}
    <AboutCourse />
    <Checklist />
    {/* <Convince /> */}
    <BetterThanOther />
    <OurExperts />
    <CourseFaqs />
    <Footer />
    {isMobile && <ActionButton />}
  </div>
);

export default LandingPage;
