import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import '../../fonts/Gilroy/Gilroy-Regular.ttf';
import '../../fonts/Gilroy/Gilroy-Bold.ttf';
import { Container, Form } from 'react-bootstrap';
import { Button, Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { LoginContainer, LoginFormWrapper } from '../../styledComponents';
import HeadingUnderline from '../../components/HeadingUnderline';
import { getNextWebinarDate } from '../../components/Webinar/BannerSection';
import { axiosAuth } from '../../api';
import { triggerGAEvent } from '../../utils/helper';
import PayByRazorPay from '../../components/RazorPay';
import { BlackLoader, TransactionProcess } from '../checkout';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { ALL_CITIES } from '../../constant';
import WebinarFooter from '../../components/Webinar/WebinarFooter';

const WebinarRegister = () => {
  const history = useHistory();
  const [webinarConfig, setWebinarConfig] = useState(null);
  const [contact, setContact] = useState();
  const [email, setEmail] = useState('');
  const [userName, setName] = useState('');
  const [city, setCity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [errors, setErrors] = useState({ contact: null, email: null });
  const childRef = useRef();

  const localUtmMedium = localStorage.getItem('utmSource');
  const isUtmMediumFB = localUtmMedium && localUtmMedium === 'fb';
  const dateData = webinarConfig?.customWebinarDate
    ? getNextWebinarDate(webinarConfig?.customWebinarDate)
    : getNextWebinarDate();

  const getWebinarConfig = async () => {
    const webinarConfigData = await axiosAuth.get(API_ENDPOINTS.WEBINAR_CONFIG);
    if (webinarConfigData?.data?.customWebinarDate) {
      setWebinarConfig(webinarConfigData?.data);
    }
  };

  useEffect(() => {
    getWebinarConfig();
  }, []);

  const validateError = useCallback(
    (name, value) => {
      if (name === 'email' || name === 'contact' || name === 'userName') {
        let modifiedErrors = {
          ...errors,
          [name]: !value,
        };
        if (name === 'email' && value) {
          const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          modifiedErrors = {
            ...errors,
            [name]: emailRegex.test(value) ? false : 1,
          };
        }
        if (name === 'contact' && value) {
          const contactRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
          modifiedErrors = {
            ...errors,
            [name]: contactRegex.test(value) ? false : 1,
          };
        }
        setErrors(modifiedErrors);
      }
    },
    [errors],
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name: keyName, value } = e.target;
      if (keyName === 'email') {
        setEmail(value);
      } else if (keyName === 'contact') {
        setContact(value);
      } else if (keyName === 'userName') {
        setName(value);
      }
      validateError(keyName, value);
    },
    [validateError],
  );

  const handleBlur = useCallback(
    (e) => {
      const { name: keyName, value } = e.target;
      validateError(keyName, value);
    },
    [validateError],
  );

  const handlePaymentSuccess = async (paymentResponse = {}) => {
    if (paymentResponse.razorpay_payment_id) {
      setTransactionLoading(true);
      const signUpPayload = {
        email,
        contact,
        txnId: paymentResponse.razorpay_payment_id,
        attended: false,
        slotDate: dateData.slotDate,
        source: isUtmMediumFB ? 'FB' : 'GOOGLE',
        name: userName,
        city,
      };

      axiosAuth
        .post(API_ENDPOINTS.WEBINAR_SIGNUP, signUpPayload)
        .then(async (res) => {
          if (
            res.data
            && res.data.status
            && res.data.data
            && res.data.data._id
          ) {
            history.push('/webinar-registered');
          } else {
            setTransactionLoading(false);
            toast(
              res?.data?.message
                || 'Failed to register. Please contact admin via Whatsapp/Email for futher support!',
              { type: toast.TYPE.ERROR },
            );
          }
        })
        .catch(() => {
          setTransactionLoading(false);
          toast(
            'Failed to register. Please contact admin via Whatsapp/Email for futher support!',
            { type: toast.TYPE.ERROR },
          );
        });
    } else {
      setTransactionLoading(false);
      toast(
        'Failed to register. Please contact admin via Whatsapp/Email for futher support!',
        { type: toast.TYPE.ERROR },
      );
    }
  };

  const handleLogin = () => {
    setLoading(true);
    axiosAuth
      .post('/order/create', {
        amount: 49900,
        name: userName,
        email,
        entity: 'course',
      })
      .then((res) => {
        if (isUtmMediumFB) {
          localStorage.setItem(
            'tlt::course::webinar::temp::user',
            JSON.stringify({
              name: userName,
              email,
              contact,
              city,
              slotDate: dateData.slotDate,
              source: isUtmMediumFB ? 'FB' : 'GOOGLE',
            }),
          );
        }
        const emailAddress = email.split('@');
        const data = {
          category: 'Order',
          action: 'Order Created (webinar)',
          label: `${emailAddress[0] || ''} - ${contact || ''} - ${
            emailAddress[1] || ''
          } - ${res?.data?.data?.orderId}`,
          value: 499,
        };

        triggerGAEvent(data);
        childRef.current.handlePayment(res?.data?.data?.orderId);
      })
      .catch(() => {
        setLoading(false);
        toast(
          'Failed to register. Please contact admin via Whatsapp/Email for futher support!',
          { type: toast.TYPE.ERROR },
        );
      });
  };

  return (
    <div>
      <Container>
        {transactionLoading && (
          <BlackLoader>
            <Spin
              indicator={(
                <LoadingOutlined
                  style={{
                    fontSize: isMobileOnly ? 30 : 50,
                    color: '#f9ae00',
                    marginBottom: '15px',
                  }}
                  spin
                />
              )}
            />
            <TransactionProcess>
              Please wait while your transaction is in process.
              <br />
              Do not refresh your browser or press the back button.
            </TransactionProcess>
          </BlackLoader>
        )}
        <RegisterContainer>
          <HeadingUnderline title="Register" />
          <LoginFormWrapper>
            <Form.Group>
              <Form.Label className="required">Name</Form.Label>
              <Form.Control
                value={userName}
                name="userName"
                className="custom-field-focus"
                onChange={handleInputChange}
                onBlur={handleBlur}
                type="text"
                placeholder="Enter name"
                isInvalid={errors.userName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.userName ? 'Name is required!!' : ''}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">Email</Form.Label>
              <Form.Control
                value={email}
                name="email"
                className="custom-field-focus"
                onChange={handleInputChange}
                onBlur={handleBlur}
                type="text"
                placeholder="Enter email"
                isInvalid={errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email ? 'Email is required!!' : ''}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label className="required">Contact No.</Form.Label>
              <Form.Control
                value={contact}
                name="contact"
                className="custom-field-focus"
                onChange={handleInputChange}
                onBlur={handleBlur}
                type="number"
                placeholder="Enter contact number"
                isInvalid={errors.contact}
              />
              <Form.Control.Feedback type="invalid">
                {errors.contact === 1
                  ? 'Invalid contact number'
                  : 'Contact is required!!'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>City (Optional)</Form.Label>
              <Select
                onChange={(value) => setCity(value)}
                value={city}
                style={{ width: '100%' }}
                placeholder="Select city"
                showSearch
                allowClear
              >
                {ALL_CITIES.map((item) => (
                  <Select.Option value={item.name}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Group>

            <Form.Group>
              <Form.Label>Slot Details</Form.Label>
              <p>
                <strong>
                  {dateData.hardcoded || `${dateData.formatDate}, 7 PM`}
                </strong>
              </p>
            </Form.Group>

            <Form.Group>
              <Form.Label>Language</Form.Label>
              <p>
                <strong>English</strong>
              </p>
            </Form.Group>

            <Button
              disabled={loading || !email || !contact}
              onClick={handleLogin}
              loading={loading}
              className="signUpBtn"
              size="large"
              block
            >
              Pay &#8377;
              <span>499</span>
            </Button>
            <PayByRazorPay
              ref={childRef}
              description="Webinar Registration"
              amount={49900}
              handlePaymentSuccess={handlePaymentSuccess}
              name={userName}
              contact={contact}
              email={email}
              setLoading={setLoading}
              isWebinar
            />
          </LoginFormWrapper>
        </RegisterContainer>
      </Container>
      <WebinarFooter />
    </div>
  );
};

export default WebinarRegister;

const RegisterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 25px;
`;
