import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import Rating from 'react-rating';
import { Container } from 'react-bootstrap';
import * as R from 'ramda';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeadingUnderline from '../../components/HeadingUnderline';
import { Spacer } from '../about';
import { axiosAuth } from '../../api';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { CourseImage, CenteredDiv } from './styled';
import { addToCart, removeFromCart } from '../../actions/cart';
import {
  CenteredContainer,
  FeatureList,
  FeatureListInnerUl,
  FeatureListItem,
  FeatureListItemInnerUlLI,
  OverlayDiv,
  PlanCard,
  PlanCardContent,
  PlanCardHeader,
  PriceText,
} from '../../styledComponents';
import { courseAddedToCart } from '../../utils/helper';
import { StarBox, RatingsCircle } from '../dashboard';
import DiscountCards from '../../components/DiscountCards';
import greyStar from '../../assets/grey-star.png';
import yellowStar from '../../assets/yellow-star.png';

const Courses = () => {
  const [allCourses, setAllCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const coursesInCart = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    axiosAuth.get(API_ENDPOINTS.GET_ALL_UNAUTHENTICATED_COURSES).then((res) => {
      if (res?.data?.status && res?.data?.data) {
        setAllCourses(res?.data?.data);
        setLoading(false);
      }
    });
  }, []);

  const handleCourseClick = (courseData, isCourseInCart) => {
    if (isCourseInCart > -1) {
      history.push('/cart');
      return;
    }
    const localCartData = localStorage.getItem('cartData');
    let validCartData = localCartData ? JSON.parse(localCartData) : [];
    const localCartDataIndex = validCartData.findIndex(
      (x) => x._id === courseData._id,
    );
    if (localCartDataIndex < 0) {
      validCartData.push(courseData);
    } else {
      validCartData = R.remove(localCartDataIndex, 1, validCartData);
    }
    localStorage.setItem('cartData', JSON.stringify(validCartData));
    dispatch(
      isCourseInCart > -1
        ? removeFromCart(isCourseInCart)
        : addToCart(courseData),
    );
    if (isCourseInCart == -1) {
      courseAddedToCart(courseData.course_name);
    }
    if (isCourseInCart < 0) {
      history.push('/cart');
    }
  };

  return (
    <Container className="blogs-brief" style={{ marginBottom: '50px' }}>
      <Spacer />
      <HeadingUnderline title="Our Courses" />
      {/* <Spacer /> */}
      {/* <Spacer /> */}
      <Row>
        {loading ? (
          <Col span={24}>
            <CenteredDiv>
              <LoadingOutlined
                style={{ fontSize: 30, color: '#f9ae00' }}
                spin
              />
            </CenteredDiv>
          </Col>
        ) : (
          <>
            <DiscountCards />
            {allCourses.map((course) => {
              const isCourseInCart = coursesInCart.findIndex(
                (x) => x._id === course._id,
              );
              const description = (course?.locked_content ?? '').replaceAll('\r\n\r\n\r\n', '<br/><br/>').replaceAll('\r\n\r\n', '<br/><br/>').replaceAll('&nbsp;', '');
              return (
                <Col key={course._id} xs={24} sm={12} md={12} lg={8} className="plan-card">
                  <PlanCard isLocked={course.is_locked}>
                    {course.is_locked && (
                      <OverlayDiv>
                        {ReactHtmlParser(description)}
                      </OverlayDiv>
                    )}
                    <PlanCardHeader>
                      {course?.course_name ?? 'Course'}
                    </PlanCardHeader>
                    <PlanCardContent>
                      <CourseImage src={course?.course_image ?? ''} />
                      <Spacer />
                      <CenteredContainer>
                        <FeatureList>
                          <FeatureListItem>
                            Course Details:
                            <FeatureListInnerUl>
                              <FeatureListItemInnerUlLI>
                                {course?.course_description || 'Description'}
                              </FeatureListItemInnerUlLI>
                            </FeatureListInnerUl>
                          </FeatureListItem>
                        </FeatureList>
                        <FeatureList>
                          <FeatureList>
                            <FeatureListItem>
                              Lectures:&nbsp;
                              {course?.course_lessons}
                            &nbsp;|&nbsp;Duration:&nbsp;
                              {course?.course_duration}
                              <FeatureListInnerUl>
                                <FeatureListItemInnerUlLI>
                                  Validity:&nbsp;
                                  {course?.course_validity}
                                </FeatureListItemInnerUlLI>
                              </FeatureListInnerUl>
                            </FeatureListItem>
                          </FeatureList>
                        </FeatureList>
                      </CenteredContainer>
                      <PriceText style={{ visibility: !course.base_amount ? 'hidden' : 'inherit' }}>
                        <BasePrice>
                          &#8377;&nbsp;
                          {course.base_amount || course.course_amount}
                        </BasePrice>
                      </PriceText>
                      <PriceText>
                        {course?.course_amount === 'Free' ? 'FREE' : (
                          <span>
                            ₹&nbsp;
                            {course?.course_amount}
                          </span>
                        )}
                      </PriceText>
                      {course?.rating?.rating ? (
                        <div style={{
                          display: 'flex', justifyContent: 'center', marginBottom: '5px', marginTop: '5px',
                        }}
                        >
                          <StarBox>
                            <RatingsCircle>{course?.rating?.rating}</RatingsCircle>
                            <Rating
                              readonly
                              start={0}
                              stop={5}
                              step={1}
                              initialRating={course?.rating?.rating}
                              emptySymbol={<img alt="empty-star" src={greyStar} style={{ width: '20px' }} />}
                              fullSymbol={<img alt="filled-star" src={yellowStar} style={{ width: '20px' }} />}
                            />
                        &nbsp;
                            <div style={{ fontSize: '10px', paddingTop: '5px' }}>
                              (
                              <strong>
                                {course?.rating?.usersCount}
&nbsp;Ratings
                              </strong>
                              )
                            </div>
                          </StarBox>
                        </div>
                      ) : <div style={{ height: '29.5px', width: '5px' }} />}
                      <CenteredContainer>
                        <Button
                          onClick={() => handleCourseClick(course, isCourseInCart)}
                          className="get-started-btn"
                          size="large"
                          disabled={course?.course_amount === 'Free'}
                        >
                          {isCourseInCart > -1
                            ? 'Go to Cart'
                            : 'Add to Cart'}
                        </Button>
                      </CenteredContainer>
                    </PlanCardContent>
                  </PlanCard>
                </Col>
              );
            })}
          </>
        )}
      </Row>
    </Container>
  );
};

export default Courses;

const BasePrice = styled.span`
  font-size: 24px;
  text-decoration: line-through solid rgb(224, 1, 1);
`;
