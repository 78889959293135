/* eslint-disable camelcase */
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { resetCart } from '../../actions/cart';
import { setUserData } from '../../actions/common';
import { axiosAuth } from '../../api';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { BlackLoader, TransactionProcess } from '../checkout';
import { triggerGAEvent } from '../../utils/helper';

const PaymentStatus = () => {
  const [isProcessOver, setIsProcessOver] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(null);
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const status = searchParams.get('status');
  const razorpay_payment_id = searchParams.get('razorpay_payment_id');
  const email = searchParams.get('email');
  const amount = searchParams.get('amount');
  const emailAddress = (email ?? '').split('@');

  const alreadyExistProcess = () => {
    localStorage.removeItem('tlt::course::temp::user');
    localStorage.removeItem('utmSource');
    localStorage.removeItem('utmCampaign');
    setIsProcessOver(true);
    history.push('/login');
  };

  const handleLinkClick = useCallback((path) => {
    history.push(path);
  }, []);

  useEffect(() => {
    let tempUserData = localStorage.getItem('tlt::course::temp::user');
    if (tempUserData) {
      tempUserData = JSON.parse(tempUserData);
    }
    if (status === 'success' && razorpay_payment_id && tempUserData?.email) {
      axiosAuth
        .post(API_ENDPOINTS.USER_EXIST, { email })
        .then((mainres) => {
          if (mainres?.data?.data) {
            toast('Your account already exists. Please login to continue', {
              type: toast.TYPE.ERROR,
            });
            alreadyExistProcess();
          } else {
            // create account as it doesn't exists
            const signUpPayload = {
              ...tempUserData,
              userType: 'normal',
              profileImg: '',
              active: true,
              password: tempUserData.slug,
            };
            delete tempUserData.slug;
            axiosAuth.post(API_ENDPOINTS.SIGN_UP, signUpPayload).then((res) => {
              if (
                res.data
                  && res.data.status
                  && res.data.data
                  && res.data.data.user
                  && res.data.data.token
              ) {
                localStorage.setItem('authToken', res.data.data.token);
                localStorage.setItem('user', JSON.stringify(res.data.data.user));
                let cartData = localStorage.getItem('cartData');
                cartData = cartData ? JSON.parse(cartData) : [];
                const purchasedCourseDetails = cartData.map((x) => ({
                  amount: x.course_amount,
                  courseId: x._id,
                  startDate: moment().format('YYYY/MM/DD'),
                  isRefundPeriodOver: false,
                  course_name: x.course_name,
                  slug: x.slug,
                  txnId: razorpay_payment_id,
                  endDate: moment().add(12, 'months'),
                  userType: 'course',
                }));
                localStorage.removeItem('tlt::course::temp::user');
                localStorage.removeItem('utmSource');
                localStorage.removeItem('utmCampaign');
                axiosAuth
                  .post(API_ENDPOINTS.PURCHASE_COURSE, purchasedCourseDetails)
                  .then((innerRes) => {
                    if (innerRes?.data?.status) {
                      toast('Registration successful!!', { type: toast.TYPE.SUCCESS });
                      dispatch(setUserData({
                        user: innerRes?.data?.data?._id ? innerRes?.data?.data : res.data.data,
                        token: res.data.data.token,
                      }));
                      localStorage.setItem('user', JSON.stringify(innerRes?.data?.data?._id ? innerRes?.data?.data : res.data.data));
                      dispatch(resetCart());
                      localStorage.removeItem('cartData');
                      // GA event trigger
                      const data = {
                        category: 'Payment',
                        action: 'Payment Success',
                        label: `${emailAddress[0] || ''} - ${tempUserData?.name || ''} - ${tempUserData?.contactNo || ''} - ${
                          emailAddress[1] || ''
                        }`,
                        value: Number(amount),
                      };
                      triggerGAEvent(data);
                      const conversionData = {
                        category: 'Conversion',
                        action: 'Course Purchased',
                        label: 'Course Purchased',
                        value: Number(amount),
                      };
                      triggerGAEvent(conversionData);
                      handleLinkClick('/account-created');
                    }
                  }).catch(() => {
                    setIsErrorMessage('Failed to register. Please contact admin via Whatsapp/Email for futher support!');
                    setIsProcessOver(true);
                    toast('Failed to register. Please contact admin via Whatsapp/Email for futher support!',
                      { type: toast.TYPE.ERROR });
                  });
              } else {
                setIsErrorMessage('Failed to register. Please contact admin via Whatsapp/Email for futher support!');
                setIsProcessOver(true);
                toast('Failed to register. Please contact admin via Whatsapp/Email for futher support!',
                  { type: toast.TYPE.ERROR });
              }
            }).catch(() => {
              setIsErrorMessage('Failed to register. Please contact admin via Whatsapp/Email for futher support!');
              setIsProcessOver(true);
              toast('Failed to register. Please contact admin via Whatsapp/Email for futher support!',
                { type: toast.TYPE.ERROR });
            });
          }
        })
        .catch(() => {
          alreadyExistProcess();
        });
    } else {
      const data = {
        category: 'Payment',
        action: 'Payment Failed',
        label: `${emailAddress[0] || ''} - ${tempUserData?.name || ''} - ${tempUserData?.contactNo || ''} - ${
          emailAddress[1] || ''
        }`,
        value: Number(amount),
      };
      triggerGAEvent(data);
      setIsProcessOver(true);
    }
  }, [search]);

  const handleTryAgainClick = () => {
    history.push('/checkout?payment=retry');
  };

  return (
    <Container>
      {!isProcessOver ? (
        <BlackLoader>
          <Spin
            indicator={(
              <LoadingOutlined
                style={{ fontSize: isMobileOnly ? 30 : 50, color: '#f9ae00', marginBottom: '15px' }}
                spin
              />
          )}
          />
          <TransactionProcess>
            Please wait while your transaction is in process.
            <br />
            Do not refresh your browser or press the back button.
          </TransactionProcess>
        </BlackLoader>
      ) : (
        <ContentDiv>
          {status !== 'success' && !isErrorMessage ? (
            <>
              <RedText>
                Transaction Failed
              </RedText>
              <p>Please try again</p>
              <Button
                onClick={handleTryAgainClick}
                className="signUpBtn"
                size="large"
              >
                Try Again
              </Button>
            </>
          ) : (
            <>
              <RedText>
                {isErrorMessage}
              </RedText>
              <Button
                onClick={() => history.push('/contact-us')}
                className="signUpBtn"
                size="large"
              >
                Contact Us
              </Button>
            </>
          )}
        </ContentDiv>
      )}
    </Container>
  );
};

export default PaymentStatus;

const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;

const RedText = styled.h3`
  color: red;
`;
