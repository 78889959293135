import {
  CheckOutlined,
  ContainerOutlined,
  StarFilled,
  StarOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import {
  Col, Collapse, Row, Tabs,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { MdCastForEducation } from 'react-icons/md';
import { GiTakeMyMoney } from 'react-icons/gi';
import styled from 'styled-components';
import { axiosAuth } from '../../api';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { Spacer } from '../../routes/about';
import {
  CourseImage,
  LessonDiv,
  MainContent,
  MainContentText,
  ModifiedCollapse,
  SectionHeadingDiv,
} from '../../routes/curriculum';
import { TECHNICAL_ANALYSIS_CONTENT } from '../../routes/curriculum/constant';
import { ColoredContainer } from './Common';
import bannerImg from '../../assets/banner-bg.png';
import bannerRightImg from '../../assets/banner-right.png';
import ayush from '../../assets/ayush-course.jpeg';
import {
  IconTextContainer,
  InfoAnalyticsBox,
  InfoAnalyticsBoxContainer,
  MainNumber,
  // MainNumberYellow,
  Text,
  TextContainer,
} from './BannerSection';
import { theme } from '../../utils/theme';
import ActionButton from './ActionButton';
import { ResultText } from '../LandingPage/Checklist';

const { Panel } = Collapse;
const { TabPane } = Tabs;

export const MoneyBack = ({
  heading, hideFirst, secondHeading, secondSubheading,
}) => {
  if (isMobile) {
    return (
      <MobileFlexDiv>
        <TCTextMobile>T&C Apply*</TCTextMobile>
        <YellowBigTextMobile>
          {heading || '100% MONEY-BACK GUARANTEE*'}
        </YellowBigTextMobile>
        {!hideFirst && (
        <>
          <CenteredContentParent>
            <MdCastForEducation
              style={{ fontSize: '45px', color: '#F9AE00' }}
            />
            <MobileContentUl>
              <li>Guaranteed To Learn New Strategies & Concepts</li>
            </MobileContentUl>
          </CenteredContentParent>
          <Subtext>
            If you don&apos;t learn anything new from our course, you get
            your money back
          </Subtext>
        </>
        )}

        <CenteredContentParent>
          <GiTakeMyMoney style={{ fontSize: '50px', color: '#F9AE00' }} />
          <MobileContentUl>
            <li>{secondHeading ?? 'Guaranteed To Learn From Extremely Profitable Traders'}</li>
          </MobileContentUl>
        </CenteredContentParent>
        {secondSubheading ? <SubtextSecondary>{secondSubheading}</SubtextSecondary> : (
          <Subtext>
            If we don&apos;t share our profit & loss statement showing that
            we&apos;ve turned
            {' '}
            <strong>&lt;&#8377;2L to &#8377;40.73L in 32 months</strong>
            ,
            you get your money back
          </Subtext>
        )}
      </MobileFlexDiv>
    );
  }

  return (
    <MoneyBackParent>
      <FlexDiv>
        <ContentContainer>
          <TCText>T&C Apply*</TCText>
          <YellowBigText>{heading || '100% MONEY-BACK GUARANTEE*'}</YellowBigText>
          {!hideFirst && (
          <ContentParent>
            <MdCastForEducation
              style={{ fontSize: '37px', color: '#F9AE00' }}
            />
            <ContentUl>
              <li>Guaranteed To Learn New Strategies & Concepts</li>
              <li>
                If you don&apos;t learn anything new from our course,
                you get your money back
              </li>
            </ContentUl>
          </ContentParent>
          )}
          <ContentParent>
            <GiTakeMyMoney
              style={{ fontSize: '50px', color: '#F9AE00' }}
            />
            <ContentUl>
              <li>
                {secondHeading ?? 'Guaranteed To Learn From Extremely Profitable Traders'}
              </li>
              {secondSubheading ? <SubtextSecondaryLi>{secondSubheading}</SubtextSecondaryLi> : (
                <li>
                  If we don&apos;t share our profit & loss statement
                  showing that we&apos;ve turned
                  {' '}
                  <strong>
                    &lt;&#8377;2L to &#8377;40.73L in 32 months
                  </strong>
                  , you get your money back
                </li>
              )}
            </ContentUl>
          </ContentParent>
        </ContentContainer>
      </FlexDiv>
    </MoneyBackParent>
  );
};

const AboutCourse = () => {
  const [activeTab, toggleTab] = useState('1');
  const [courseData, setCourseData] = useState(null);

  useEffect(() => {
    axiosAuth
      .get(`${API_ENDPOINTS.GET_COURSE_BY_SLUG}/technical-analysis`)
      .then((res) => {
        if (res?.data?.data) {
          setCourseData(res?.data?.data);
        }
      });
  }, []);

  return (
    <GrayColoredContainer>
      <Container>
        <MainTextContainer>
          <MoneyBack />

          <MainContent>
            <StyledCourseImage alt={courseData?.course_name} src={ayush} />
            {!isMobile && (
              <MainContentText>
                <StyledInfoAnalyticsBoxContainer>
                  <StyledInfoAnalyticsBox>
                    <IconTextContainer>
                      <StarOutlined
                        style={{
                          color: theme.backgrounds.main,
                          fontSize: '30px',
                        }}
                      />
                      <TextContainer>
                        <MainNumber>91.33%</MainNumber>
                        <Text>Users Have Given 4+ Star Rating</Text>
                      </TextContainer>
                    </IconTextContainer>
                  </StyledInfoAnalyticsBox>
                </StyledInfoAnalyticsBoxContainer>
                <StyledInfoAnalyticsBoxContainer>
                  <StyledInfoAnalyticsBox>
                    <IconTextContainer>
                      <UsergroupAddOutlined
                        style={{
                          color: theme.backgrounds.main,
                          fontSize: '30px',
                        }}
                      />
                      <TextContainer>
                        <MainNumber>&lt;1%</MainNumber>
                        <Text>Users Have Opted For Refund</Text>
                      </TextContainer>
                    </IconTextContainer>
                  </StyledInfoAnalyticsBox>
                </StyledInfoAnalyticsBoxContainer>
              </MainContentText>
            )}
          </MainContent>
          <Tabs
            style={{ marginTop: '20px' }}
            activeKey={activeTab}
            defaultActiveKey="1"
            onChange={toggleTab}
            className="content-tabs"
          >
            <TabPane tab="Course Content" key="1">
              <ModifiedCollapse ghost>
                {TECHNICAL_ANALYSIS_CONTENT.map((x) => (
                  <Panel
                    header={(
                      <SectionHeadingDiv>
                        {x.highlight ? (
                          <StarFilled
                            style={{ marginRight: '10px', color: '#F9AE00' }}
                          />
                        ) : (
                          <ContainerOutlined
                            style={{ marginRight: '10px', color: 'black' }}
                          />
                        )}
                        <HeadingCurriculum
                          style={{ color: x.highlight ? '#F9AE00' : 'black' }}
                        >
                          {x.section}
                        </HeadingCurriculum>
                      </SectionHeadingDiv>
                    )}
                    key={x.section}
                  >
                    {x.lessons.map((l) => (
                      <LessonDiv key={l.title}>
                        {l.highlight ? (
                          <StarFilled
                            style={{ marginRight: '10px', color: '#F9AE00' }}
                          />
                        ) : (
                          <VideoCameraOutlined
                            style={{ marginRight: '10px', color: '#F9AE00' }}
                          />
                        )}
                        <Content
                          style={{
                            color: l.highlight ? '#F9AE00' : 'black',
                            fontWeight: l.highlight ? 'bold' : 'inherit',
                          }}
                        >
                          {l.title}
                        </Content>
                      </LessonDiv>
                    ))}
                  </Panel>
                ))}
              </ModifiedCollapse>
            </TabPane>
            {/* <TabPane tab="Course Benefits" key="2">
              <Spacer />
              Coming Soon
            </TabPane> */}
          </Tabs>
          {!isMobile && (
            <Row>
              <Col span={24}>
                <Spacer />
                <Spacer />
                <ActionButton />
              </Col>
            </Row>
          )}
        </MainTextContainer>
      </Container>
    </GrayColoredContainer>
  );
};

export default AboutCourse;

const GrayColoredContainer = styled(ColoredContainer)`
  background: white;
  padding-top: 40px;
`;

const MainTextContainer = styled.div`
  width: ${isMobile ? '100%' : '80%'};
  margin: 0 auto;
`;

const MoneyBackParent = styled.div`
  display: flex;
  align-items: center;
  background-image: url(${bannerImg});
  border-radius: 5px;
  margin-bottom: 20px;
`;

const FlexDiv = styled.div`
  flex: 1;
  min-height: 300px;
  background-image: url(${bannerRightImg});
  background-size: cover;
  border-radius: 5px;
  display: flex;
  padding-left: 15px;
  flex-direction: column;
  padding-top: 15px;
`;

export const StyledCheckCircleOutlined = styled(CheckOutlined)`
  font-size: 20px;
  margin-top: 5px;
  margin-right: 7px;
  color: #f9ae00;
`;

const StyledCourseImage = styled(CourseImage)`
  border-radius: 5px;
`;

export const HeadingText = styled.p`
  padding-top: 30px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
`;

export const StyledResultText = styled(ResultText)`
  padding: 30px 0;
  font-weight: bold;
`;

export const Box = styled.div`
  background-color: #fafafa;
  flex: 1;
  border-radius: 5px;
  padding: 24px 30px;
`;

export const HeadingSection = styled.div`
  display: flex;
`;

export const Heading = styled.div`
  font-weight: bold;
  font-size: 22px;
  flex: 1;
`;

export const IndentText = styled.div`
  padding-left: 26px;
  text-indent: -14px;
  margin-top: 15px;
  flex: 1;
  font-family: GilroyRegular;
  font-size: 16px;
  font-weight: 600;
`;

export const IconDiv = styled.div`
  height: 50px;
  width: 50px;
  background: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HiddenIconDiv = styled(IconDiv)`
  visibility: hidden;
`;

const HeadingCurriculum = styled.h6`
  font-family: GilroyBold;
  font-size: 16px;
`;

const Content = styled.p`
  font-family: GilroyRegular;
  font-size: 16px;
  font-weight: 600;
`;

const YellowBigText = styled.div`
  font-size: 30px;
  color: #f9ae00;
  font-family: GilroyBold;
  margin-bottom: 10px;
`;

const ContentParent = styled.div`
  display: flex;
`;

const ContentUl = styled.ul`
  padding-left: 14px;
  li {
    list-style-type: none;
  }
  li:first-child {
    font-size: 20px;
    font-family: GilroyBold;
  }
  li:nth-child(2) {
    font-size: 12px;
  }
`;

const ContentContainer = styled.div`
  width: 55%;
  position: relative;
`;

const MobileFlexDiv = styled.div`
  background-image: url(${bannerImg});
  min-height: 200px;
  background-size: cover;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
  margin-bottom: 20px;
  align-content: center;
  position: relative;
`;

const YellowBigTextMobile = styled(YellowBigText)`
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
`;

const MobileContentUl = styled.ul`
  padding-left: 14px;
  li {
    list-style-type: none;
  }
  li:first-child {
    font-size: 18px;
    font-weight: 500;
  }
`;

const CenteredContentParent = styled(ContentParent)`
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  line-height: 24px;
  height: 60px;
`;

const Subtext = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const SubtextSecondary = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  font-size: 18px;
`;

const SubtextSecondaryLi = styled.li`
  font-size: 16px !important;
`;

export const StyledInfoAnalyticsBox = styled(InfoAnalyticsBox)`
  margin-bottom: 0;
  display: flex;
`;

export const StyledInfoAnalyticsBoxContainer = styled(
  InfoAnalyticsBoxContainer,
)`
  display: flex;
  margin-bottom: 2px;
`;

const TCText = styled.span`
  position: absolute;
  bottom: -12px;
  right: -85px;
  font-size: 6px;
  color: white;
`;

const TCTextMobile = styled(TCText)`
  bottom: 5px;
  right: 5px;
  color: gray;
`;
