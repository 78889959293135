import { Container } from "react-bootstrap";
import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { isMobileOnly, isMobile, isTablet } from "react-device-detect";
import {
  CommentOutlined,
  DatabaseOutlined,
  EyeOutlined,
  PlaySquareOutlined,
  StarOutlined,
  UsergroupAddOutlined,
  VerifiedOutlined,
} from "@ant-design/icons";
import { theme } from "../../utils/theme";
import "./index.scss";
import { ColoredContainer } from "./Common";
import ActionButton from "./ActionButton";
import { Spacer } from "../../routes/about";
import bannerMain from "../../assets/bannerMain.png";

const BannerSection = () => (
  <ColoredContainer>
    <Container>
      <MainContainer>
        <HeadingContainer>
          <BoldHeadingBlackText>
            {/* Learn To{" "}
            <BoldHeadingYellowText>Trade Profitably</BoldHeadingYellowText> In
            Bull & Bear Markets */}
            Learn The 6 Strategies I Used To Turn{" "}
            <BoldHeadingYellowText>&lt;&#8377;2 Lakhs</BoldHeadingYellowText>
            &nbsp;To&nbsp;
            <BoldHeadingYellowText>&#8377;81.13 Lakhs</BoldHeadingYellowText>
            &nbsp;in&nbsp;
            <BoldHeadingYellowText>4 Years</BoldHeadingYellowText>
          </BoldHeadingBlackText>
          <SubHeadingWhiteText>
            I will show you my{" "}
            <SubHeadingYellowText>
              Zerodha Account Statement&nbsp;
            </SubHeadingYellowText>
            to prove profitability or you&nbsp;
            <SubHeadingYellowText>get your money back!*</SubHeadingYellowText>
          </SubHeadingWhiteText>
        </HeadingContainer>
        {isMobile ? (
          <>
            <div
              className={`react-video-player-custom item-center ${
                isTablet ? "video-margin" : ""
              }`}
            >
              {/* <ReactPlayer
                url="https://vimeo.com/670118315"
                controls
                width={isMobileOnly ? "350px" : "480px"}
                height={isMobileOnly ? "197px" : "270px"}
                pip
                loop
                playsinline
              /> */}
              <StyledImg src={bannerMain} />
            </div>
            <Spacer />
            <InfoBox>
              {/* <InfoAnalyticsContainer>
                <InfoAnalyticsBoxContainer>
                  <InfoAnalyticsBox>
                    <IconTextContainer>
                      <UsergroupAddOutlined
                        style={{
                          color: theme.backgrounds.main,
                          fontSize: "30px",
                        }}
                      />
                      <TextContainer>
                        <MainNumber>
                          1045
                          <MainNumberYellow>+</MainNumberYellow>
                        </MainNumber>
                        <Text>Users Joined The Course</Text>
                      </TextContainer>
                    </IconTextContainer>
                  </InfoAnalyticsBox>
                </InfoAnalyticsBoxContainer>
                <InfoAnalyticsBoxContainer>
                  <InfoAnalyticsBox>
                    <IconTextContainer>
                      <StarOutlined
                        style={{
                          color: theme.backgrounds.main,
                          fontSize: "30px",
                        }}
                      />
                      <TextContainer>
                        <MainNumber>
                          4.61
                          <MainNumberYellow>/</MainNumberYellow>5
                        </MainNumber>
                        <Text>Average User Rating</Text>
                      </TextContainer>
                    </IconTextContainer>
                  </InfoAnalyticsBox>
                </InfoAnalyticsBoxContainer>
              </InfoAnalyticsContainer> */}

              <BlackInfoAnalyticsContainer>
                <Flex>
                  <InfoAnalyticsBoxContainer>
                    <TransparentInfoAnalyticsBox>
                      <IconTextContainer>
                        <VerifiedOutlined
                          style={{
                            color: theme.backgrounds.main,
                            fontSize: "30px",
                          }}
                        />
                        <TextContainer>
                          <Text>4 Year Verified P&L Statement</Text>
                        </TextContainer>
                      </IconTextContainer>
                    </TransparentInfoAnalyticsBox>
                  </InfoAnalyticsBoxContainer>
                  <InfoAnalyticsBoxContainer>
                    <TransparentInfoAnalyticsBox>
                      <IconTextContainer>
                        <DatabaseOutlined
                          style={{
                            color: theme.backgrounds.main,
                            fontSize: "30px",
                          }}
                        />
                        <TextContainer>
                          <Text>6 Advanced Strategies</Text>
                        </TextContainer>
                      </IconTextContainer>
                    </TransparentInfoAnalyticsBox>
                  </InfoAnalyticsBoxContainer>
                </Flex>
                <Flex>
                  <TransparentInfoAnalyticsBox>
                    <IconTextContainer>
                      <UsergroupAddOutlined
                        style={{
                          color: theme.backgrounds.main,
                          fontSize: "30px",
                        }}
                      />
                      <TextContainer>
                        <Text>1045+ Users Joined The Course</Text>
                      </TextContainer>
                    </IconTextContainer>
                  </TransparentInfoAnalyticsBox>
                  <InfoAnalyticsBoxContainer>
                    <TransparentInfoAnalyticsBox>
                      <IconTextContainer>
                        <CommentOutlined
                          style={{
                            color: theme.backgrounds.main,
                            fontSize: "30px",
                          }}
                        />
                        <TextContainer>
                          <Text>Doubt Clearing Session</Text>
                        </TextContainer>
                      </IconTextContainer>
                    </TransparentInfoAnalyticsBox>
                  </InfoAnalyticsBoxContainer>
                </Flex>
              </BlackInfoAnalyticsContainer>
            </InfoBox>
          </>
        ) : (
          <>
            <InfoVideoContainer>
              <InfoBox>
                {/* <InfoAnalyticsContainer>
                  <InfoAnalyticsBoxContainer>
                    <InfoAnalyticsBox>
                      <IconTextContainer>
                        <UsergroupAddOutlined
                          style={{
                            color: theme.backgrounds.main,
                            fontSize: "30px",
                          }}
                        />
                        <TextContainer>
                          <MainNumber>
                            1045
                            <MainNumberYellow>+</MainNumberYellow>
                          </MainNumber>
                          <Text>Users Joined The Course</Text>
                        </TextContainer>
                      </IconTextContainer>
                    </InfoAnalyticsBox>
                  </InfoAnalyticsBoxContainer>
                  <InfoAnalyticsBoxContainer>
                    <InfoAnalyticsBox>
                      <IconTextContainer>
                        <StarOutlined
                          style={{
                            color: theme.backgrounds.main,
                            fontSize: "30px",
                          }}
                        />
                        <TextContainer>
                          <MainNumber>
                            4.61
                            <MainNumberYellow>/</MainNumberYellow>5
                          </MainNumber>
                          <Text>Average User Rating</Text>
                        </TextContainer>
                      </IconTextContainer>
                    </InfoAnalyticsBox>
                  </InfoAnalyticsBoxContainer>
                </InfoAnalyticsContainer> */}

                <BlackInfoAnalyticsContainer>
                  <Flex>
                    <InfoAnalyticsBoxContainer>
                      <TransparentInfoAnalyticsBox>
                        <IconTextContainer>
                          <VerifiedOutlined
                            style={{
                              color: theme.backgrounds.main,
                              fontSize: "30px",
                            }}
                          />
                          <TextContainer>
                            <Text>4 Year Verified P&L Statement</Text>
                          </TextContainer>
                        </IconTextContainer>
                      </TransparentInfoAnalyticsBox>
                    </InfoAnalyticsBoxContainer>
                    <InfoAnalyticsBoxContainer>
                      <TransparentInfoAnalyticsBox>
                        <IconTextContainer>
                          <DatabaseOutlined
                            style={{
                              color: theme.backgrounds.main,
                              fontSize: "30px",
                            }}
                          />
                          <TextContainer>
                            <Text>6 Advanced Strategies</Text>
                          </TextContainer>
                        </IconTextContainer>
                      </TransparentInfoAnalyticsBox>
                    </InfoAnalyticsBoxContainer>
                  </Flex>
                  <Flex>
                    <InfoAnalyticsBoxContainer>
                      <TransparentInfoAnalyticsBox>
                        <IconTextContainer>
                          <UsergroupAddOutlined
                            style={{
                              color: theme.backgrounds.main,
                              fontSize: "30px",
                            }}
                          />
                          <TextContainer>
                            <Text>1045+ Users Joined The Course</Text>
                          </TextContainer>
                        </IconTextContainer>
                      </TransparentInfoAnalyticsBox>
                    </InfoAnalyticsBoxContainer>
                    <InfoAnalyticsBoxContainer>
                      <TransparentInfoAnalyticsBox>
                        <IconTextContainer>
                          <CommentOutlined
                            style={{
                              color: theme.backgrounds.main,
                              fontSize: "30px",
                            }}
                          />
                          <TextContainer>
                            <Text>Doubt Clearing Session</Text>
                          </TextContainer>
                        </IconTextContainer>
                      </TransparentInfoAnalyticsBox>
                    </InfoAnalyticsBoxContainer>
                  </Flex>
                </BlackInfoAnalyticsContainer>
              </InfoBox>
              <VideoBox>
                {/* <div className="react-video-player-custom">
                  <ReactPlayer
                    url="https://vimeo.com/670118315"
                    controls
                    width={isMobileOnly ? "350px" : "480px"}
                    height={isMobileOnly ? "197px" : "270px"}
                    pip
                    loop
                    playsinline
                  />
                </div> */}
                <StyledImg src={bannerMain} />
              </VideoBox>
            </InfoVideoContainer>
            <ActionButtonContainer>
              {/* <WhiteText>
                Register before
                {' '}
                {moment().format('MMMM DD, YYYY')}
                {' '}
                to unlock
                bonus worth &#8377;15000
              </WhiteText> */}
              <ActionButton />
            </ActionButtonContainer>
            <EmptyDiv />
          </>
        )}
      </MainContainer>
    </Container>
  </ColoredContainer>
);

export default BannerSection;

const BoldHeadingBlackText = styled.span`
  color: white;
  font-size: ${isMobile ? "25px" : "35px"};
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const BoldHeadingYellowText = styled(BoldHeadingBlackText)`
  color: ${theme.backgrounds.main};
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${isMobile ? "30px" : "40px"};
`;

const HeadingContainer = styled.div`
  margin-top: ${isMobile ? 0 : "30px"};
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${isMobile ? 0 : "0 50px"};
`;

const SubHeadingWhiteText = styled.span`
  font-size: 20px;
  color: white;
  text-align: center;
`;

const SubHeadingYellowText = styled(SubHeadingWhiteText)`
  color: ${theme.backgrounds.main};
  font-weight: bold;
`;

const InfoVideoContainer = styled.div`
  display: flex;
  min-height: 300px;
  padding: ${isMobile ? "10px 0" : "10px 40px"};
  justify-content: center;
  align-items: center;
  margin-top: ${isMobile ? 0 : "20px"};
`;

export const InfoBox = styled.div`
  flex: 1;
  background: transparent;
  min-height: 100%;
  border-radius: 5px;
  padding: ${isMobile ? "5px 0" : "0 10px"};
`;

export const InfoAnalyticsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const InfoAnalyticsBoxContainer = styled.div`
  padding: 0 10px;
  flex: 1;
`;

export const InfoAnalyticsBox = styled.div`
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  flex: 1;
  min-height: 80px;
  margin-bottom: 10px;
  padding: ${isMobile ? "10px" : "0 10px"};
`;

const VideoBox = styled.div`
  flex: 1;
  min-height: 100%;
`;

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 80px;
  justify-content: space-evenly;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: 10px;
`;

export const MainNumber = styled.span`
  font-size: 25px;
  font-weight: bold;
`;

export const MainNumberYellow = styled(MainNumber)`
  color: ${theme.backgrounds.main};
`;

const BlackInfoAnalyticsContainer = styled(InfoAnalyticsContainer)`
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  padding: 10px 0;
  margin: 10px;
  flex-direction: column;
`;

const TransparentInfoAnalyticsBox = styled(InfoAnalyticsBox)`
  background-color: transparent;
`;

const Flex = styled.div`
  display: flex;
`;

export const Text = styled.span`
  font-size: 15px;
  font-weight: bold;
`;

const ActionButtonContainer = styled.div`
  margin-top: 30px;
  padding: ${isMobile ? 0 : "0 60px"};
  margin-bottom: ${isMobile ? "30px" : 0};
`;

const EmptyDiv = styled.div`
  height: 150px;
  width: 1px;
`;

const WhiteText = styled.p`
  text-align: center;
  color: white;
  font-weight: bold;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 320px;
  object-fit: contain;
`;
