import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import * as R from "ramda";
import { Button, Divider, Row, Spin } from "antd";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { Container, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Checkbox from "antd/lib/checkbox/Checkbox";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { axiosAuth } from "../../api";
import PayByRazorPay from "../../components/RazorPay";
import {
  AlreadyAccountText,
  LoginText,
  SignUpStepOneContainer,
  SignUpStepOneParent,
  PayContainer,
} from "../../styledComponents";
import { getEnvValue, triggerGAEvent } from "../../utils/helper";
import { Spacer } from "../about";
import HeadingUnderline from "../../components/HeadingUnderline";
import API_ENDPOINTS from "../../api/apiEndpoints";
import { setUserData } from "../../actions/common";
import { addToCart, resetCart } from "../../actions/cart";
import gsc from "../../assets/gsc.png";
import Stepper from "../../components/Stepper";
import { CheckoutContainer } from "../cart";
import { FREE_COURSE, HUB } from "../../constant";
import { COURSE_PLAN_DETAILS } from "../../components/BuyCourseButton";

export const SafeCheckoutImg = styled.img`
  height: 300px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    height: 150px;
  }
`;

export const BlackLoader = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TransactionProcess = styled.h5`
  color: white;
  text-align: center;
`;

export const baseUrl = getEnvValue("IMAGE_URL");
const Checkout = ({ noMargin }) => {
  const childRef = useRef();
  const dispatch = useDispatch();
  const parentDivRef = React.createRef();
  const history = useHistory();
  const tokenIsExist = useSelector((state) => state.common.authToken);
  const cartData = useSelector((state) => state.cart.cart);
  const couponData = useSelector((state) => state.cart.coupon);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [joinUsLoading, setJoinUsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: null,
    email: null,
    password: null,
    confirmPassword: null,
  });
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    const paymentRetry = searchParams.get("payment");
    const tempUserData = localStorage.getItem("tlt::course::temp::user");
    if (paymentRetry === "retry" && tempUserData) {
      const parsedData = JSON.parse(tempUserData);
      setName(parsedData?.name ?? "");
      setEmail(parsedData?.email ?? "");
      setContact(parsedData?.contactNo ?? null);
      setPassword(parsedData?.slug ?? "");
      setConfirmPassword(parsedData?.slug ?? "");
      setTermsAgreed(true);
    }

    const tempSavedUserData = localStorage.getItem("temp-user-enroll-data");
    if (!paymentRetry && tempSavedUserData) {
      const parsedData = JSON.parse(tempSavedUserData);
      setName(parsedData?.name ?? "");
      setEmail(parsedData?.email ?? "");
      setContact(parsedData?.contact ?? null);
    }
  }, []);

  useEffect(() => {
    if (tokenIsExist) {
      history.push("/dashboard");
    }
  }, [tokenIsExist]);

  const handleLinkClick = useCallback((path) => {
    history.push(path);
  }, []);

  const validateError = useCallback(
    (name, value) => {
      if (
        name === "name" ||
        name === "email" ||
        name === "password" ||
        name === "contact"
      ) {
        let modifiedErrors = {
          ...errors,
          [name]: !value,
        };
        if (name == "email" && value) {
          const emailRegex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          modifiedErrors = {
            ...errors,
            [name]: emailRegex.test(value) ? false : 1,
          };
        }
        if (name == "contact" && value) {
          const contactRegex =
            /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
          modifiedErrors = {
            ...errors,
            [name]: contactRegex.test(value) ? false : 1,
          };
        }
        if (name == "password" && value) {
          const passwordRegex =
            /^[a-zA-Z0-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~]{8,20}$/;
          modifiedErrors = {
            ...errors,
            [name]: passwordRegex.test(value) ? false : 1,
          };
        }

        setErrors(modifiedErrors);
      } else {
        let errorType = false;
        if (!value) {
          errorType = 1;
        } else if (password !== value) {
          errorType = 2;
        }
        const modifiedErrors = {
          ...errors,
          [name]: errorType,
        };
        setErrors(modifiedErrors);
      }
    },
    [password, errors]
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name: keyName, value } = e.target;
      if (keyName === "name") {
        setName(value);
      } else if (keyName === "email") {
        setEmail(value);
      } else if (keyName === "contact") {
        setContact(value);
      } else if (keyName === "password") {
        setPassword(value);
      } else if (keyName === "confirmPassword") {
        setConfirmPassword(value);
      }
      validateError(keyName, value);
    },
    [validateError]
  );

  const handleBlur = useCallback(
    (e) => {
      const { name: userName, value } = e.target;
      validateError(userName, value);
    },
    [validateError]
  );

  const joinUsDisabled = useMemo(
    () =>
      !name ||
      !email ||
      !contact ||
      !password ||
      !confirmPassword ||
      password !== confirmPassword ||
      !termsAgreed,
    [name, email, password, confirmPassword, termsAgreed]
  );

  const paymentDetails = useMemo(() => {
    let totalPrice = R.pipe(R.map(R.prop("course_amount")), R.sum)(cartData);
    // + parseInt(HUB.base_amount, 10);
    if (couponData?._id) {
      totalPrice =
        couponData.courseOne +
        couponData.courseTwo +
        couponData.courseThree -
        couponData.courseFour;
    }
    const allCoursesName = R.map(R.prop("course_name"), cartData);
    return {
      amount: parseInt(totalPrice, 10) * 100,
      description: allCoursesName.join(", "),
    };
  }, [cartData, couponData]);

  const handleJoinUsClick = useCallback(() => {
    setJoinUsLoading(true);
    axiosAuth
      .post(API_ENDPOINTS.USER_EXIST, { email })
      .then((mainres) => {
        setJoinUsLoading(false);
        if (mainres?.data?.data) {
          toast("User already exist. Please try again with different email!", {
            type: toast.TYPE.ERROR,
          });
        } else {
          setLoading(true);
          axiosAuth
            .post("/order/create", {
              amount: paymentDetails.amount,
              name,
              email,
              entity: "course",
            })
            .then((res) => {
              const localUtmMedium = localStorage.getItem("utmSource");
              const isUtmMediumFB = localUtmMedium && localUtmMedium === "fb";
              if (isUtmMediumFB) {
                localStorage.setItem(
                  "tlt::course::temp::user",
                  JSON.stringify({
                    name,
                    email,
                    contactNo: contact,
                    slug: password,
                  })
                );
              }
              const emailAddress = email.split("@");
              const data = {
                category: "Order",
                action: "Order Created",
                label: `${emailAddress[0] || ""} - ${name || ""} - ${
                  contact || ""
                } - ${emailAddress[1] || ""} - ${res?.data?.data?.orderId}`,
                value: Number(paymentDetails.amount),
              };
              triggerGAEvent(data);
              childRef.current.handlePayment(res?.data?.data?.orderId);
            })
            .catch(() => {
              toast("Failed to complete payment process. Please try again", {
                type: toast.TYPE.ERROR,
              });
              const emailAddress = email.split("@");
              const data = {
                category: "Order",
                action: "Order Creation Failed",
                label: `${emailAddress[0] || ""} - ${name || ""} - ${
                  contact || ""
                } - ${emailAddress[1] || ""}`,
                value: Number(paymentDetails.amount),
              };
              triggerGAEvent(data);
              setLoading(false);
            });
        }
      })
      .catch(() => {
        setJoinUsLoading(false);
      });
  }, [email, contact, paymentDetails, password]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && !joinUsDisabled) {
        handleJoinUsClick();
      }
    },
    [joinUsDisabled]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [joinUsDisabled]);

  const createSubAccount = async (paymentResponse = {}) => {
    if (paymentResponse.razorpay_payment_id) {
      const endDate = new Date();
      endDate.setMonth(new Date().getMonth() + 12);
      const signUpPayload = {
        name,
        email,
        contactNo: contact,
        password,
        portfolio: "",
        experience: "",
        investorType: "",
        userType: "course",
        payment: {
          amount: 1,
          txnId: paymentResponse.razorpay_payment_id,
          startDate: new Date(),
          endDate,
          planType: "Annual",
          orderId: paymentResponse.razorpay_order_id,
        },
        active: true,
      };

      await axiosAuth.post("auth/signup", signUpPayload);
    }
  };

  const handlePaymentSuccess = async (paymentResponse = {}) => {
    if (paymentResponse.razorpay_payment_id) {
      setTransactionLoading(true);
      const signUpPayload = {
        name,
        email,
        contactNo: contact,
        password,
        userType: "normal",
        profileImg: "",
        active: true,
      };

      axiosAuth
        .post(API_ENDPOINTS.SIGN_UP, signUpPayload)
        .then(async (res) => {
          if (
            res.data &&
            res.data.status &&
            res.data.data &&
            res.data.data.user &&
            res.data.data.token
          ) {
            localStorage.setItem("authToken", res.data.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.data.user));
            if (cartData?.length === 1) {
              const purchasedCourseDetails = cartData.map((x) => ({
                amount: x.course_amount,
                courseId: x._id,
                startDate: moment().format("YYYY/MM/DD"),
                isRefundPeriodOver: false,
                course_name: x.course_name,
                slug: x.slug,
                txnId: paymentResponse.razorpay_payment_id,
                endDate: moment().add(12, "months"),
                userType: "course",
              }));
              axiosAuth
                .post(API_ENDPOINTS.PURCHASE_COURSE, purchasedCourseDetails)
                .then(async (innerRes) => {
                  if (innerRes?.data?.status) {
                    toast("Registration successful!!", {
                      type: toast.TYPE.SUCCESS,
                    });
                    dispatch(
                      setUserData({
                        user: innerRes?.data?.data?._id
                          ? innerRes?.data?.data
                          : res.data.data,
                        token: res.data.data.token,
                      })
                    );
                    localStorage.setItem(
                      "user",
                      JSON.stringify(
                        innerRes?.data?.data?._id
                          ? innerRes?.data?.data
                          : res.data.data
                      )
                    );
                    // Create the sub account
                    await createSubAccount(paymentResponse);
                    dispatch(resetCart());
                    localStorage.removeItem("cartData");
                    localStorage.removeItem("tlt::course::temp::user");
                    localStorage.removeItem("temp-user-enroll-data");
                    localStorage.removeItem("language");
                    handleLinkClick("/account-created");
                    setTransactionLoading(false);
                  }
                });
            } else {
              // assign 2nd course 1st
              const secondCourse = cartData.find(
                (x) => x.slug === FREE_COURSE.slug
              );
              const secondCoursePayload = {
                amount: secondCourse.course_amount,
                courseId: secondCourse._id,
                startDate: moment().format("YYYY/MM/DD"),
                isRefundPeriodOver: false,
                course_name: secondCourse.course_name,
                slug: secondCourse.slug,
                txnId: paymentResponse.razorpay_payment_id,
                endDate: moment().add(12, "months"),
                userType: "course",
              };
              // assign 1st course
              const firstCourse = cartData.find(
                (x) => x.slug === COURSE_PLAN_DETAILS.slug
              );
              const firstCoursePayload = {
                amount: firstCourse.course_amount,
                courseId: firstCourse._id,
                startDate: moment().format("YYYY/MM/DD"),
                isRefundPeriodOver: false,
                course_name: firstCourse.course_name,
                slug: firstCourse.slug,
                txnId: paymentResponse.razorpay_payment_id,
                endDate: moment().add(12, "months"),
                userType: "course",
              };
              const secondCourseAssigned = await axiosAuth.post(
                API_ENDPOINTS.PURCHASE_COURSE,
                [secondCoursePayload]
              );
              const firstCourseAssigned = await axiosAuth.post(
                API_ENDPOINTS.PURCHASE_COURSE,
                [firstCoursePayload]
              );
              if (
                secondCourseAssigned?.data?.status &&
                firstCourseAssigned?.data?.status
              ) {
                toast("Registration successful!!", {
                  type: toast.TYPE.SUCCESS,
                });
                dispatch(
                  setUserData({
                    user: secondCourseAssigned?.data?.data?._id
                      ? secondCourseAssigned?.data?.data
                      : res.data.data,
                    token: res.data.data.token,
                  })
                );
                localStorage.setItem(
                  "user",
                  JSON.stringify(
                    secondCourseAssigned?.data?.data?._id
                      ? secondCourseAssigned?.data?.data
                      : res.data.data
                  )
                );
                // Create the sub account
                await createSubAccount(paymentResponse);
                dispatch(resetCart());
                localStorage.removeItem("cartData");
                localStorage.removeItem("tlt::course::temp::user");
                localStorage.removeItem("temp-user-enroll-data");
                localStorage.removeItem("language");
                handleLinkClick("/account-created");
                setTransactionLoading(false);
              }
            }
          } else {
            setTransactionLoading(false);
            toast(
              res?.data?.message ||
                "Failed to register. Please contact admin via Whatsapp/Email for futher support!",
              { type: toast.TYPE.ERROR }
            );
          }
        })
        .catch(() => {
          setTransactionLoading(false);
          toast(
            "Failed to register. Please contact admin via Whatsapp/Email for futher support!",
            { type: toast.TYPE.ERROR }
          );
        });
    } else {
      setTransactionLoading(false);
      toast(
        "Failed to register. Please contact admin via Whatsapp/Email for futher support!",
        { type: toast.TYPE.ERROR }
      );
    }
  };

  const getActivePageFields = () => (
    <SignUpStepOneContainer className="leadform" noMargin>
      <Stepper />
      <Divider />
      <HeadingUnderline title="Checkout" />
      <SignUpStepOneParent>
        <form>
          <Form.Group>
            <Form.Label className="required">Name</Form.Label>
            <Form.Control
              value={name}
              name="name"
              className="custom-field-focus"
              onChange={handleInputChange}
              onBlur={handleBlur}
              type="text"
              placeholder="Enter Full Name"
              isInvalid={errors.name}
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              Name is required!!
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label className="required">Email address</Form.Label>
            <Form.Control
              value={email}
              name="email"
              className="custom-field-focus"
              onChange={handleInputChange}
              onBlur={handleBlur}
              type="email"
              placeholder="Enter email"
              isInvalid={errors.email}
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              {errors.email === 1 ? "Invalid Email" : "Email is required!!"}
            </Form.Control.Feedback>
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label className="required">Contact</Form.Label>
            <Form.Control
              value={contact}
              name="contact"
              className="custom-field-focus"
              onChange={handleInputChange}
              onBlur={handleBlur}
              type="number"
              placeholder="Enter Contact Number"
              isInvalid={errors.contact}
            />
            <Form.Control.Feedback type="invalid">
              {errors.contact === 1
                ? "Invalid contact number"
                : "Contact is required!!"}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label className="required">Password</Form.Label>
            <Form.Control
              value={password}
              name="password"
              className="custom-field-focus"
              onBlur={handleBlur}
              onChange={handleInputChange}
              type="password"
              placeholder="Enter password"
              autoComplete="off"
              isInvalid={errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password === 1
                ? "Password must be of 8-20 characters"
                : "Password is required!!"}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label className="required">Confirm Password</Form.Label>
            <Form.Control
              value={confirmPassword}
              name="confirmPassword"
              className="custom-field-focus"
              onBlur={handleBlur}
              onChange={handleInputChange}
              type="password"
              placeholder="Confirm password"
              isInvalid={errors.confirmPassword}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword === 1
                ? "Confirm password is required!!"
                : "Passwords do no match!!"}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Checkbox
              checked={termsAgreed}
              onChange={() => setTermsAgreed(!termsAgreed)}
            >
              I agree to accept the{" "}
              <Link to="/terms-and-conditions">Terms & Conditions,</Link>{" "}
              <Link to="/refund-policy">Refund Policy,</Link> and{" "}
              <Link to="/disclaimer">Disclaimer</Link>
            </Checkbox>
          </Form.Group>
          <Button
            onClick={handleJoinUsClick}
            className="purpleButton"
            style={{ marginTop: "15px" }}
            loading={joinUsLoading || loading}
            disabled={joinUsDisabled || joinUsLoading || loading}
            size="large"
            block
          >
            Pay
          </Button>
          {!noMargin && (
            <AlreadyAccountText onClick={() => handleLinkClick("/login")}>
              Already have an account? <LoginText>Login Now</LoginText>
            </AlreadyAccountText>
          )}
        </form>
        {isMobile && (
          <CheckoutContainer>
            <Button
              onClick={handleJoinUsClick}
              className="purpleButton mobile-fixed-button"
              style={{ marginTop: "15px" }}
              loading={joinUsLoading || loading}
              disabled={joinUsDisabled || joinUsLoading || loading}
              size="large"
              block
            >
              Pay
            </Button>
          </CheckoutContainer>
        )}
      </SignUpStepOneParent>
      <PayContainer>
        <PayByRazorPay
          ref={childRef}
          description={`${paymentDetails.description}-${
            localStorage.getItem("language") ?? "English"
          }`}
          amount={paymentDetails.amount}
          handlePaymentSuccess={handlePaymentSuccess}
          name={name}
          contact={contact}
          email={email}
          setLoading={setLoading}
        />
        <Row>
          <Container>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SafeCheckoutImg style={{ maxWidth: "100%" }} src={gsc} />
            </div>
          </Container>
        </Row>
      </PayContainer>
    </SignUpStepOneContainer>
  );
  return (
    <div ref={parentDivRef}>
      {transactionLoading && (
        <BlackLoader>
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: isMobileOnly ? 30 : 50,
                  color: "#f9ae00",
                  marginBottom: "15px",
                }}
                spin
              />
            }
          />
          <TransactionProcess>
            Please wait while your transaction is in process.
            <br />
            Do not refresh your browser or press the back button.
          </TransactionProcess>
        </BlackLoader>
      )}
      {getActivePageFields()}
      <Spacer />
    </div>
  );
};

export default Checkout;
