import React from 'react';
import styled from 'styled-components';
import Experts from '../OurExperts';
import { StyledResultText } from './AboutCourse';
import { ColoredContainer } from './Common';

const OurExperts = () => (
  <WhiteColoredContainer>
    <WhiteStyledResultText>
      About The Creators
    </WhiteStyledResultText>
    <Experts hideHeading />
  </WhiteColoredContainer>
);

export default OurExperts;

const WhiteColoredContainer = styled(ColoredContainer)`
  background: white;
  min-height: inherit;
`;

const WhiteStyledResultText = styled(StyledResultText)`
    color: black;
`;
