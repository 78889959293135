import { Row, Spin } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { CommentOutlined, LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { SignUpNavItem } from '../Telegram/TelegramButton';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { axiosAuth } from '../../api';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: 'black' }} spin />;

const EnrollButtonElement = styled(SignUpNavItem)`
  background: ${({ theme }) => theme.backgrounds.main};
  border: ${({ isButton, theme }) => isButton && `1px solid ${theme.backgrounds.main}`};
  display: flex;
  color: black !important;
  justify-content: space-evenly;
  align-items: center;
  max-width: 300px;
  height: 42px;
`;

const EnrollButton = ({ customStyle, justify }) => {
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state) => state.common.user);

  const handleClick = () => {
    if (profile?.email) {
      setLoading(true);
      axiosAuth.post(API_ENDPOINTS.LIVE_QA_ENROLL, { email: profile?.email }).then((res) => {
        if (res?.data?.data) {
          toast(res?.data?.message, {
            type: toast.TYPE.SUCCESS,
          });
        }
        setLoading(false);
      }).catch(() => {
        toast('Action Failed. Please try again or contact admin!', {
          type: toast.TYPE.ERROR,
        });
        setLoading(false);
      });
    }
  };

  return (
    <>
      <Row justify={justify || 'center'} style={{ ...customStyle }}>
        <EnrollButtonElement onClick={handleClick} className="pulse-coupon" isButton>
          {loading ? <Spin indicator={antIcon} /> : (
            <>
              <CommentOutlined style={{ fontSize: '22px', marginRight: '10px' }} />
              {' '}
              <span>Enroll for Q&A Session</span>
            </>
          )}
        </EnrollButtonElement>
      </Row>
    </>
  );
};

export default EnrollButton;
