import { Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

const BlogsHeading = styled.span`
  font-family: Poppins;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  margin-bottom: 10px;
  text-align: center;
  color: ${({ whitetext }) => (whitetext ? 'white' : 'black')};
  @media (max-width: 767px) {
    font-size: 25px;
    margin-bottom: 5px;
  }
`;

const BlogsHeadingUnderline = styled.div`
  height: 5px;
  width: 80px;
  border-radius: 2.5px;
  background: #f9ae00;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0px' : '30px')};
`;

const HeadingUnderline = ({ title, whitetext, noMargin }) => (
  <>
    <Row justify="center">
      <BlogsHeading whitetext={whitetext}>{title}</BlogsHeading>
    </Row>
    <Row justify="center">
      <BlogsHeadingUnderline noMargin={noMargin} />
    </Row>
  </>
);

export default HeadingUnderline;
