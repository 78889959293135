import React, { useEffect } from "react";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";
import "./App.scss";
import { useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Routes from "./routes";
import Auth from "./components/Auth";
import ScrollToTop from "./utils/scrollToTop";
import { SET_USER_DATA } from "./actions/common";
import HamburgerMenu from "./components/HamburgerMenu";
import ExitIntentModal from "./components/ExitIntentModal";
import { axiosAuth } from "./api";
import { isUserDetailsUpdate } from "./utils/helper";
import { setToCart } from "./actions/cart";
import API_ENDPOINTS from "./api/apiEndpoints";
import { authSetToCart } from "./actions/authcart";
import { BLOCKED_PATHNAME, ExcludedURLS } from "./constant";

function AppContainer() {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const userData = localStorage.getItem("user");
    const token = localStorage.getItem("authToken") || "";

    // For setting the updated user data
    if (userData) {
      const user = JSON.parse(userData);
      dispatch({
        type: SET_USER_DATA,
        payload: {
          user: userData
            ? {
                ...user,
              }
            : null,
          token,
        },
      });

      axiosAuth.get(API_ENDPOINTS.GET_USER_PROFILE).then((res) => {
        if (res?.data?.data?._id) {
          const result = isUserDetailsUpdate(user, res.data.data);
          if (!result) {
            localStorage.setItem("user", JSON.stringify(res.data.data));
            dispatch({
              type: SET_USER_DATA,
              payload: { user: res?.data?.data ?? null },
            });
          }
        }
      });
    }

    axiosAuth.get(API_ENDPOINTS.GET_CART).then((res) => {
      if (res?.data?.data?.length) {
        dispatch(authSetToCart(res?.data?.data[0]?.courses ?? []));
      }
    });
  }, []);

  useEffect(() => {
    if (!ExcludedURLS.includes(window.location.origin + location.pathname)) {
      ReactGA.set({ page: window.location.origin + location.pathname });
      ReactGA.pageview(window.location.origin + location.pathname);
      ReactPixel.pageView();
    }
  }, [location]);

  const params = new URLSearchParams(location.search);
  const headerParam = params.get("header");

  return (
    <div className="App">
      <ScrollToTop>
        {/* {!BLOCKED_PATHNAME.includes(location?.pathname) && !headerParam && (
          <ExitIntentModal />
        )} */}
        <Auth />
        {isMobile &&
          !BLOCKED_PATHNAME.includes(location?.pathname) &&
          !headerParam && <HamburgerMenu />}
        {!BLOCKED_PATHNAME.includes(location?.pathname) && !headerParam && (
          <Header />
        )}
        <div className="margin-app">
          <Routes />
        </div>
        {!BLOCKED_PATHNAME.includes(location?.pathname) &&
          location?.pathname !== "/" &&
          !headerParam && <Footer />}
      </ScrollToTop>
    </div>
  );
}

export default AppContainer;
