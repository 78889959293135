import { useEffect, useState } from 'react';

const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime(),
  );

  const getReturnValues = (remianingCountDown) => {
    if (remianingCountDown < 0) {
      return null;
    }
    // calculate time left
    const days = Math.floor(remianingCountDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (remianingCountDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor(
      (remianingCountDown % (1000 * 60 * 60)) / (1000 * 60),
    );
    const seconds = Math.floor((remianingCountDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds, remianingCountDown];
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

export { useCountdown };
