import React, { useCallback, useRef, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Row, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';
import RupeeBag from '../../assets/rupee-bag.png';
import { BlackLoader, TransactionProcess } from '../checkout';

export const SocialMediaButton = styled.a`
  height: 40px;
  line-height: 40px;
  min-width: 40px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  backface-visibility: hidden;
  transition: box-shadow 0.2s ease, border-color 0.2s ease, opacity 0.3s;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px;
  font-size: 0;
  border-radius: 4px;
  opacity: 1;
  margin: 6px;
  background-color: ${({ color }) => color || 'white'};
  &:hover {
    box-shadow: none;
    border-color: transparent;
    background-color: black;
  }
`;

export const SocialMediaIcon = styled.span`
  background: rgba(255, 255, 255, 0.1);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 12px;
  transition: all 0.2s ease;
  z-index: 2;
`;

export const SocialMediaText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  padding: 0 10px 0 44px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  box-sizing: border-box;
  transition: all 0.2s ease;
  backface-visibility: hidden;
  position: relative;
  z-index: 2;
  color: #fff;
`;

export const SocialMediaIconsContainer = styled.div`
  display: flex;
  margin: 10px 0px;
  a:first-child {
    margin-left: 0
  }
`;

const ParentContainer = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;

const GreyText = styled.h4`
  color: grey;
  text-align: center;
  margin: 10px 0px 15px 0px;
`;

const ContentContainer = styled.div`
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 350px;
  }
`;

const RupeeImage = styled.img`
  height: 150px;
  width: 150px;
  margin-bottom: 10px;
`;

const WebinarRegistered = () => {
  const goButton = useRef(null);
  const history = useHistory();
  const handleLinkClick = useCallback(() => {
    history.push('/webinar-register-success');
  }, []);

  useEffect(() => {
    setTimeout(() => {
      goButton.current.click();
    }, 1500);
  }, []);

  return (
    <Container>
      <BlackLoader>
        <Spin
          indicator={(
            <LoadingOutlined
              style={{ fontSize: isMobileOnly ? 30 : 50, color: '#f9ae00', marginBottom: '15px' }}
              spin
            />
          )}
        />
        <TransactionProcess>
          Please wait....
        </TransactionProcess>
      </BlackLoader>
      <ParentContainer>
        <ContentContainer>
          <RupeeImage src={RupeeBag} />
          <Row justify="center">
            <GreyText>
              Webiinar Registered Successfully.
            </GreyText>
          </Row>
          <Row>
            <button ref={goButton} onClick={handleLinkClick} type="button">
              Webinar Registered
            </button>
          </Row>
        </ContentContainer>
      </ParentContainer>
    </Container>

  );
};

export default WebinarRegistered;
