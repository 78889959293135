import {
  Card, Col, Row, Button, Modal, Upload, Table,
} from 'antd';
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Container, Form } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import ImgCrop from 'antd-img-crop';
import { useDispatch } from 'react-redux';
import Search from 'antd/lib/input/Search';
import { LoadingOutlined } from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { MdOutlineUnsubscribe } from 'react-icons/md';
import { axiosAuth } from '../../api';
import {
  numberWithCommas,
  getEnvValue,
  checkImageSize,
  setFileListBySize,
} from '../../utils/helper';
import S3Upload from '../../utils/s3Uploader';
import { SET_USER_DATA_PATCH } from '../../actions/common';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { Spacer } from '../about';
import TelegramButton from '../../components/Telegram/TelegramButton';
import YoutubeButton from '../../components/YoutubeButton';
import CouponButton from '../../components/CouponButton';
import { CenteredDiv } from '../courses/styled';
import EnrollButton from '../../components/EnrollButton';
import QuantityCalculator from '../../components/QuantityCalculator';
import { AppIconsContainer, AppIconsImage, IconDiv } from '../thankYou';
import gPlay from '../../assets/google-play.png';
import appStore from '../../assets/app-store.png';

const MyProfile = () => {
  const dispatch = useDispatch();
  const childRef = useRef();
  const [loading, setLoading] = useState(false);
  const [subscribing, setSubscribing] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const [showProfileModal, toggleProfileModal] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [email, setEmail] = useState('');
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [loadingChangeProfilePic, setLoadingChangeProfilePic] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [fileList, setFileList] = useState([]);
  const [errors, setErrors] = useState({
    oldPassword: null,
    newPassword: null,
  });

  useEffect(() => {
    setLoading(true);
    axiosAuth
      .get(API_ENDPOINTS.GET_USER_PROFILE)
      .then((res) => {
        if (res?.data?.data) {
          setProfileData(res.data.data);
          setEmail(res?.data?.data?.email ?? '');
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const validateError = useCallback(
    (name, value) => {
      if (name === 'newPassword' || name === 'oldPassword') {
        let modifiedErrors = {
          ...errors,
          [name]: !value,
        };
        if (name == 'newPassword' && value) {
          if (value === oldPassword) {
            modifiedErrors = {
              ...errors,
              [name]: 2,
            };
          } else {
            const passwordRegex = /^[a-zA-Z1-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~]{8,20}$/;
            modifiedErrors = {
              ...errors,
              [name]: passwordRegex.test(value) ? false : 1,
            };
          }
        }
        setErrors(modifiedErrors);
      }
    },
    [newPassword, errors],
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (name === 'oldPassword') {
        setOldPassword(value);
      } else if (name === 'newPassword') {
        setNewPassword(value);
      }
      validateError(name, value);
    },
    [validateError],
  );

  const handleBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      validateError(name, value);
    },
    [validateError],
  );

  const handleChangePassword = () => {
    setLoadingChangePassword(true);
    axiosAuth
      .post(API_ENDPOINTS.CHANGE_PASSWORD, {
        oldPassword,
        newPassword,
      })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          toast('Password changed successfully!!', {
            type: toast.TYPE.SUCCESS,
          });
          toggleModal(false);
          setOldPassword('');
          setNewPassword('');
          setErrors({
            oldPassword: null,
            newPassword: null,
          });
        } else {
          toast('Incorrect old password', {
            type: toast.TYPE.ERROR,
          });
        }
        setLoadingChangePassword(false);
      })
      .catch((err) => {
        setLoadingChangePassword(false);
      });
  };

  const uploadPicture = (imageData) => {
    const { key } = imageData;
    axiosAuth
      .patch(API_ENDPOINTS.USER_UPDATE, {
        profileImg: `${getEnvValue('CLOUDFRONT_BASE_IMAGE_URL')}${key}` ?? '',
      })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          toast('Profile image changed successfully!!', {
            type: toast.TYPE.SUCCESS,
          });
          dispatch({
            type: SET_USER_DATA_PATCH,
            payload: { profileImg: res?.data?.data?.profileImg ?? '' },
          });
          let userDataSaved = localStorage.getItem('user');
          userDataSaved = userDataSaved ? JSON.parse(userDataSaved) : {};
          localStorage.setItem(
            'user',
            JSON.stringify({
              ...userDataSaved,
              profileImg: res?.data?.data?.profileImg ?? '',
            }),
          );
          toggleProfileModal(false);
          setFileList([]);
        } else {
          toast('Failed to change profile picture. Please try again!!', {
            type: toast.TYPE.ERROR,
          });
        }
        setLoadingChangeProfilePic(false);
      })
      .catch((err) => {
        setLoadingChangeProfilePic(false);
      });
  };

  const handleChangeProfilePic = () => {
    const [{ originFileObj }] = fileList;
    setLoadingChangeProfilePic(true);
    S3Upload(originFileObj, uploadPicture);
  };

  const isSaveDisabled = useMemo(() => {
    if (!oldPassword || !newPassword) {
      return true;
    }
    if (!errors.oldPassword && !errors.newPassword) {
      return false;
    }
    return true;
  }, [errors, oldPassword, newPassword]);

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);

    if (imgWindow) {
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const purchasedCoursesColumns = [
    {
      title: 'Course Name',
      dataIndex: 'course_name',
      key: 'course_name',
    },
    {
      title: 'Paid Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => <div>{numberWithCommas(amount)}</div>,
    },
    {
      title: 'Transaction Id',
      dataIndex: 'txnId',
      key: 'txnId',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate) => (
        <div>{moment(startDate).format('DD MMM YYYY')}</div>
      ),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate) => <div>{moment(endDate).format('DD MMM YYYY')}</div>,
    },
    {
      title: 'Refunded',
      dataIndex: 'isRefunded',
      key: 'isRefunded',
      render: (isRefunded) => <div>{isRefunded ? 'Yes' : 'No'}</div>,
    },
    {
      title: 'Expired',
      dataIndex: 'expired',
      key: 'expired',
      render: (expired) => <div>{expired ? 'Yes' : 'No'}</div>,
    },
  ];

  const handleClick = () => {
    const payload = {
      email,
    };
    setSubscribing(true);
    axiosAuth
      .post(API_ENDPOINTS.NEWSLETTER_SUBSCRIBE, payload)
      .then((res) => {
        if (res?.data?.data) {
          toast(res?.data?.message, {
            type: toast.TYPE.SUCCESS,
          });
          localStorage.setItem('neverShowSubscribe', 'true');
        }
        setSubscribing(false);
      })
      .catch(() => {
        setSubscribing(false);
        toast('Failed to subscribe. Please try again!!', {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const isCourseUnlocked = profileData?.purchasedCourses?.some(
    (item) => item?.isRefundPeriodOver === true,
  );
  const isExpired = moment(profileData?.coupon?.expiry).isBefore(moment());
  if (loading) {
    return (
      <Row style={{ marginTop: '25px' }}>
        <Col span={24}>
          <CenteredDiv>
            <LoadingOutlined style={{ fontSize: 30, color: '#f9ae00' }} spin />
          </CenteredDiv>
        </Col>
      </Row>
    );
  }
  return (
    <Container style={{ marginTop: '50px' }}>
      <QuantityCalculator ref={childRef} />
      <Modal
        visible={showModal}
        onCancel={() => {
          setOldPassword('');
          setNewPassword('');
          setErrors({ oldPassword: null, newPassword: null });
          toggleModal(false);
        }}
        maskClosable={false}
        footer={[
          <Button
            type="primary"
            onClick={handleChangePassword}
            loading={loadingChangePassword}
            disabled={isSaveDisabled}
          >
            Save
          </Button>,
        ]}
      >
        <div>
          <Form.Group>
            <Form.Label className="required">Old Password</Form.Label>
            <Form.Control
              value={oldPassword}
              name="oldPassword"
              className="custom-field-focus"
              onBlur={handleBlur}
              onChange={handleInputChange}
              type="password"
              placeholder="Enter old password"
              isInvalid={errors.oldPassword}
            />
            <Form.Control.Feedback type="invalid">
              Old password is required!!
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label className="required">New Password</Form.Label>
            <Form.Control
              value={newPassword}
              name="newPassword"
              className="custom-field-focus"
              onBlur={handleBlur}
              onChange={handleInputChange}
              type="password"
              placeholder="Enter new password"
              isInvalid={errors.newPassword}
            />
            <Form.Control.Feedback type="invalid">
              {errors.newPassword === true
                ? 'New password is required!!'
                : errors.newPassword === 1
                  ? 'Please use a strong password'
                  : 'Old password & new password cannot be same'}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </Modal>
      <Modal
        title="Change Profile Picture"
        visible={showProfileModal}
        onCancel={() => {
          setFileList([]);
          toggleProfileModal(false);
        }}
        maskClosable={false}
        footer={[
          <Button
            type="primary"
            onClick={handleChangeProfilePic}
            loading={loadingChangeProfilePic}
            disabled={!fileList?.length}
          >
            Save
          </Button>,
        ]}
      >
        <div className="custom-upload-box">
          <ImgCrop grid quality="0.8">
            <Upload
              listType="picture-card"
              fileList={fileList}
              onPreview={onPreview}
              beforeUpload={(file) => checkImageSize(file)}
              onChange={({ fileList }) => {
                setFileListBySize(fileList, setFileList);
              }}
            >
              {fileList.length == 0 && (
                <p>
                  Click to Choose
                  <br />
                  or
                  <br />
                  {' '}
                  Drag Image
                </p>
              )}
            </Upload>
          </ImgCrop>
        </div>
      </Modal>
      <Card title="My Profile">
        <Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <h6>Name</h6>
            <p>{profileData?.name}</p>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <h6>Email</h6>
            <p>{profileData?.email}</p>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <h6>User Status</h6>
            <p>{profileData?.active ? 'Active' : 'Deactivated'}</p>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <h6>Banned from Commenting</h6>
            <p>{profileData?.isBanned ? 'Yes' : 'No'}</p>
          </Col>
        </Row>
        <Spacer />
        <Spacer />
        <Row>
          <h5>Purchased Courses</h5>
        </Row>
        <Row style={{ marginTop: '10px', overflowY: 'scroll' }}>
          <Col span={24}>
            <Table
              dataSource={profileData?.purchasedCourses ?? []}
              columns={purchasedCoursesColumns}
              pagination={false}
            />
          </Col>
        </Row>
      </Card>
      <Card style={{ marginTop: '20px' }} title="Mobile Apps">
        <Row>
          <Col span={24}>
            <Heading>
              Download our mobile app to watch our courses and get access to The
              Logical Traders Hub (Only available on mobile). Download our
              mobile app by clicking on the following link:
            </Heading>
          </Col>
          <AppIconsContainer>
            <a
              href="https://play.google.com/store/apps/details?id=com.tltcourseapp"
              target="_blank"
              rel="noreferrer"
            >
              <IconDiv>
                <p style={{ fontWeight: 'bold' }}>Android App</p>
                <AppIconsImage src={gPlay} />
              </IconDiv>
            </a>
            <a
              href="https://apps.apple.com/us/app/the-logical-traders-course/id1663487740"
              target="_blank"
              rel="noreferrer"
            >
              <IconDiv>
                <p style={{ fontWeight: 'bold' }}>iOS App</p>
                <AppIconsImage src={appStore} />
              </IconDiv>
            </a>
          </AppIconsContainer>
        </Row>
      </Card>
      <Card style={{ marginTop: '20px' }} title="Action Corner">
        <Row>
          <Col
            className={isMobileOnly ? 'mb-30' : 'mb-20'}
            sm={24}
            md={24}
            lg={24}
            xs={24}
          >
            <Heading>Profile updates</Heading>
            <div>
              <Button
                style={{ marginRight: '10px' }}
                type="primary"
                onClick={() => toggleProfileModal(true)}
              >
                Change Profile Picture
              </Button>
              <Button type="primary" onClick={() => toggleModal(true)}>
                Change Password
              </Button>
              ,
            </div>
          </Col>
          {/* <Col
            className={isMobileOnly ? 'mb-30' : 'mb-20'}
            sm={12}
            md={12}
            lg={12}
            xs={24}
          >
            <Heading>Subscribe to our Telegram channel</Heading>
            <TelegramButton justify="left" />
          </Col> */}
          <Col
            className={isMobileOnly ? 'mb-30' : 'mb-20'}
            sm={12}
            md={12}
            lg={12}
            xs={24}
          >
            <Heading>Subscribe to our Youtube channel</Heading>
            <YoutubeButton justify="left" />
          </Col>
          {/* {isCourseUnlocked && (
            <>
              <Col
                className={isMobileOnly ? 'mb-30' : 'mb-20'}
                sm={12}
                md={12}
                lg={12}
                xs={24}
              >
                <Heading>
                  Do you want to enroll for the live q&a session?
                </Heading>
                <EnrollButton justify="left" />
              </Col>
              {!isExpired && (
                <Col
                  className={isMobileOnly ? 'mb-30' : 'mb-20'}
                  sm={12}
                  md={12}
                  lg={12}
                  xs={24}
                >
                  <Heading>Coupon Code</Heading>
                  <CouponButton justify="left" />
                </Col>
              )}
            </>
          )} */}
          {/* <Col
            className={isMobileOnly ? 'mb-30' : 'mb-20'}
            sm={12}
            md={12}
            lg={12}
            xs={24}
          >
            <Heading>Subscribe to our newsletter</Heading>
            <Search
              className="coupon-code"
              onSearch={handleClick}
              value={email}
              placeholder="Enter email"
              enterButton={(
                <span>
                  <MdOutlineUnsubscribe
                    style={{ fontSize: '22px', marginRight: '10px' }}
                  />
                  <span>Subscribe</span>
                </span>
              )}
              size="large"
              onChange={(e) => setEmail(e.target.value)}
              loading={subscribing}
              disabled={subscribing}
            />
          </Col> */}
        </Row>
        <Row>
          <Col span={24}>
            <Heading>Risk Calculator</Heading>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              onClick={() => childRef.current.toggleCalcModal(true)}
            >
              Show Risk Calculator
            </Button>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default MyProfile;

const Heading = styled.h5`
  @media (max-width: 767px) {
    text-align: center;
  }
`;
