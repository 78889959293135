export const SET_MODAL_TYPE = 'SET_MODAL_TYPE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_DATA_PATCH = 'SET_USER_DATA_PATCH';
export const LOGOUT = 'LOGOUT';
export const TOGGLE_REFUND_CONFIRMATION_MODAL = 'TOGGLE_REFUND_CONFIRMATION_MODAL';
export const TOGGLE_STAR_RATING_MODAL = 'TOGGLE_STAR_RATING_MODAL';

export const setModalType = (type) => ({
  payload: type,
  type: SET_MODAL_TYPE,
});

export const setUserData = (userData) => ({
  payload: userData,
  type: SET_USER_DATA,
});

export const logout = () => ({
  type: LOGOUT,
});

export const toggleRefundConfirmationModal = (value) => ({
  payload: value,
  type: TOGGLE_REFUND_CONFIRMATION_MODAL,
});

export const toggleStarRatingModal = (value) => ({
  payload: value,
  type: TOGGLE_STAR_RATING_MODAL,
});
