export const theme = {
  backgrounds: {
    primary: '#48c9b0',
    light: '#f8f9fa',
    main: '#F9AE00',
    black: 'black',
    facebook: '#4267B2',
    whatsapp: '#4AC959',
    linkedin: '#2867B2',
    twitter: '#1DA1F2',
    telegram: '#0088cc',
    darkorchid: 'darkorchid',
    youtube: '#c00',
  },
  colors: {
    white: 'white',
    main: '#F9AE00',
    black: 'black',
    lightgrey: '#979197',
  },
  borders: {
    light: '#C1C1C1',
    main: '#F9AE00',
  },
  black: 'black',
};
