import { Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { YoutubeOutlined } from '@ant-design/icons';
import { SignUpNavItem } from '../Telegram/TelegramButton';
import youtubeIcon from '../../assets/youtube-icon.svg';

const SubscribeYoutube = styled(SignUpNavItem)`
  background: ${({ theme }) => theme.backgrounds.youtube};
  border: ${({ isButton, theme }) => isButton && `1px solid ${theme.backgrounds.youtube}`};
  a {
      color: white !important;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      max-width: 170px;
  }
`;

const YoutubeButton = ({ customStyle, justify }) => (
  <Row justify={justify || 'center'} style={{ ...customStyle }}>
    <SubscribeYoutube
      className="pulse-youtube"
      isButton
    >
      <a href="https://www.youtube.com/channel/UCmwDOFPi3K6FgSavxBIWZ1Q?sub_confirmation=1" target="_blank" rel="noreferrer">
        <YoutubeOutlined style={{ fontSize: '22px', marginRight: '10px' }} />
        {' '}
        <span>SUBSCRIBE</span>
      </a>
    </SubscribeYoutube>
  </Row>

);

export default YoutubeButton;
