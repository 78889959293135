/* eslint-disable camelcase */
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { axiosAuth } from '../../api';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { BlackLoader, TransactionProcess } from '../checkout';
import { triggerGAEvent } from '../../utils/helper';
import WebinarFooter from '../../components/Webinar/WebinarFooter';

const PaymentStatusWebinar = () => {
  const [isProcessOver, setIsProcessOver] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(null);
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const status = searchParams.get('status');
  const razorpay_payment_id = searchParams.get('razorpay_payment_id');
  const email = searchParams.get('email');
  const amount = searchParams.get('amount');
  const emailAddress = (email ?? '').split('@');

  const clearUpData = () => {
    localStorage.removeItem('tlt::course::webinar::temp::user');
    setIsProcessOver(true);
  };

  const handleLinkClick = useCallback((path) => {
    history.push(path);
  }, []);

  useEffect(() => {
    let tempUserData = localStorage.getItem('tlt::course::webinar::temp::user');
    if (tempUserData) {
      tempUserData = JSON.parse(tempUserData);
    }
    if (status === 'success' && razorpay_payment_id && tempUserData?.email) {
      // create account as it doesn't exists
      const signUpPayload = {
        email: tempUserData?.email,
        contact: tempUserData?.contact,
        txnId: razorpay_payment_id,
        attended: false,
        slotDate: tempUserData?.slotDate,
        source: tempUserData?.source,
        name: tempUserData?.name,
        city: tempUserData?.city,
      };
      axiosAuth
        .post(API_ENDPOINTS.WEBINAR_SIGNUP, signUpPayload)
        .then((res) => {
          if (
            res.data
            && res.data.status
            && res.data.data
            && res.data.data._id
          ) {
            localStorage.removeItem('tlt::course::webinar::temp::user');
            localStorage.removeItem('utmSource');
            localStorage.removeItem('utmCampaign');

            // GA event trigger
            const data = {
              category: 'Payment',
              action: 'Payment Success',
              label: `${emailAddress[0] || ''} - ${
                tempUserData?.name || ''
              } - ${tempUserData?.contact || ''} - ${emailAddress[1] || ''}`,
              value: Number(amount),
            };
            triggerGAEvent(data);
            const conversionData = {
              category: 'Conversion',
              action: 'Webinar Registration',
              label: 'Webinar Registration',
              value: Number(amount),
            };
            triggerGAEvent(conversionData);
            handleLinkClick('/webinar-registered');
          } else {
            setIsErrorMessage(
              'Failed to register. Please contact admin via Whatsapp/Email for futher support!',
            );
            setIsProcessOver(true);
            toast(
              'Failed to register. Please contact admin via Whatsapp/Email for futher support!',
              { type: toast.TYPE.ERROR },
            );
          }
        })
        .catch(() => {
          setIsErrorMessage(
            'Failed to register. Please contact admin via Whatsapp/Email for futher support!',
          );
          setIsProcessOver(true);
          clearUpData();
          toast(
            'Failed to register. Please contact admin via Whatsapp/Email for futher support!',
            { type: toast.TYPE.ERROR },
          );
        });
    } else {
      const data = {
        category: 'Payment',
        action: 'Payment Failed',
        label: `${emailAddress[0] || ''} - ${tempUserData?.name || ''} - ${
          tempUserData?.contactNo || ''
        } - ${emailAddress[1] || ''}`,
        value: Number(amount),
      };
      clearUpData();
      triggerGAEvent(data);
      setIsProcessOver(true);
    }
  }, [search]);

  const handleTryAgainClick = () => {
    history.push('/webinar-registration');
  };

  return (
    <>
      <Container>
        <div style={{ minHeight: 'calc(100vh - 200px)' }}>
          {!isProcessOver ? (
            <BlackLoader>
              <Spin
                indicator={(
                  <LoadingOutlined
                    style={{
                      fontSize: isMobileOnly ? 30 : 50,
                      color: '#f9ae00',
                      marginBottom: '15px',
                    }}
                    spin
                  />
            )}
              />
              <TransactionProcess>
                Please wait while your transaction is in process.
                <br />
                Do not refresh your browser or press the back button.
              </TransactionProcess>
            </BlackLoader>
          ) : (
            <ContentDiv>
              {status !== 'success' && !isErrorMessage ? (
                <>
                  <RedText>Transaction Failed</RedText>
                  <p>Please try again</p>
                  <Button
                    onClick={handleTryAgainClick}
                    className="signUpBtn"
                    size="large"
                  >
                    Try Again
                  </Button>
                </>
              ) : (
                <>
                  <RedText>{isErrorMessage}</RedText>
                </>
              )}
            </ContentDiv>
          )}
        </div>

      </Container>
      <WebinarFooter />
    </>
  );
};

export default PaymentStatusWebinar;

const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;

const RedText = styled.h3`
  color: red;
`;
