import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const StyledDesc = styled.p`
  text-align: center;
  width: 60%;
  margin: 0 auto;
  font-family: Poppins !important;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const PerformanceSection = ({ image, heading, description }) => {
  return (
    <StyledContainer>
      <img
        src={image}
        height='100px'
        width='100px'
        style={{ marginBottom: "10px" }}
      />
      <h4 style={{ textAlign: "center" }}>{heading}</h4>
      <StyledDesc>{description}</StyledDesc>
    </StyledContainer>
  );
};

export default PerformanceSection;
