const ALL_TESTIMONIALS = [
  {
    content: 'Enjoyed the course and Learned a Lot. Knowledge gained > Price paid for the course. A++',
    name: 'Saurav Bittle',
    image: '',
    position: '',
  },
  {
    content: 'One of the best courses I have ever watched',
    name: 'Nageswar Reddy',
    image: '',
    position: '',
  },
  {
    content: 'After knowing the way you are trading using both fundamental view and technical analysis, the way you are teaching the concepts it was amazing thanks alot Ayush',
    name: 'Shanmukh Karri',
    image: '',
    position: '',
  },
  {
    content: 'Superb content',
    name: 'Sudip Banerjee',
    image: '',
    position: '',
  },
  {
    content: 'Very good points, I have learned alot of new things in this course. Thank you very much for your efforts in this course. Keep going. All the best',
    name: 'Sriman',
    image: '',
    position: '',
  },
  {
    content: 'This course has many examples from the actual stock market which helped me to understand the concepts clearly. The way Ayush had cautioned regarding losing streaks is commendable.',
    name: 'Satya Venkatesh Sundaraneedi',
    image: '',
    position: '',
  },
  {
    content: 'Its a game changer. Understood what is what!!!',
    name: 'Karthik Palanivelu',
    image: '',
    position: '',
  },
  {
    content: 'Well organized course content and excellent explanation',
    name: 'Jerald Praveen',
    image: '',
    position: '',
  },
  {
    content: 'Secrets are worth knowing. Knowledge and insights shared are valuable. Strategies are truly advanced. This course should be recommended to profitable traders too, to gain more trading edge over markets.',
    name: 'Dhiraj Dilip Chaudhari',
    image: '',
    position: '',
  },
];

export default ALL_TESTIMONIALS;
