import React, { useEffect, useMemo, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as R from "ramda";
import { Button, Col, Divider, List, Radio, Row, Select } from "antd";
import { toast } from "react-toastify";
import styled, { useTheme } from "styled-components";
import { ArrowRightOutlined, CloseCircleOutlined } from "@ant-design/icons";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { useHistory } from "react-router-dom";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import HeadingUnderline from "../../components/HeadingUnderline";
import { Spacer } from "../about";
import {
  addToCart,
  removeFromCart,
  resetCart,
  setCouponCode,
  setToCart,
} from "../../actions/cart";
import CouponCode from "../../components/CouponCode";
import BuyCourseButton, {
  COURSE_PLAN_DETAILS,
} from "../../components/BuyCourseButton";
import { FREE_COURSE, HUB } from "../../constant";
import Stepper from "../../components/Stepper";
import Testimonials from "../../components/Testimonials";
import { courseAddedToCart } from "../../utils/helper";
import "./cart.scss";
import { useCountdown } from "../dashboard/useCountdown";
import WebinarFooter from "../../components/Webinar/WebinarFooter";

export const CheckoutContainer = styled.div`
  width: 100%;
  position: fixed;
  border: 10px;
  display: flex;
  bottom: 0px;
  left: 0;
  z-index: 1;
  button {
    width: 100%;
    border-radius: 0;
  }
`;

const GrayRow = styled(Row)`
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
  position: relative;
  margin-bottom: 10px;
`;

const FullWidthImage = styled.img`
  width: 100%;
  border-radius: 8px;
  max-height: 146px;
`;

export const CourseDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 10px;
`;

export const CourseDetailText = styled.div`
  font-size: 17px;
  font-weight: 600;
`;

export const CourseDetailTextStrike = styled(CourseDetailText)`
  text-decoration: line-through;
`;

export const CourseDetailSubText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #f9ae00;
`;

export const TelegramColor = styled.span`
  color: ${({ theme }) => theme.backgrounds.telegram};
`;

export const CoursePrice = styled(CourseDetails)`
  align-items: flex-end;
`;

export const NoPaddedCoursePrice = styled(CoursePrice)`
  padding: 0 10px;
`;

export const InclusiveText = styled(CoursePrice)`
  padding: 0 10px;
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.backgrounds.telegram};
`;

export const CloseCircleOutlinedIcon = styled(CloseCircleOutlined)`
  font-size: 25px;
  position: absolute;
  right: ${isMobileOnly ? "20px" : "10px"};
  top: ${isMobileOnly ? "20px" : "10px"};
  z-index: 999;
  &:hover {
    cursor: pointer;
  }
`;

export const CloseCircleOutlinedWhiteIcon = styled(CloseCircleOutlinedIcon)`
  color: white;
`;

export const NoItemsFoundText = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PaddedDiv = styled.div`
  padding-top: 10px;
`;

const Cart = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const headerParam = params.get("header");
  const codeParam = params.get("coupon-code");
  const childRef = useRef();
  const { width, height } = useWindowSize();
  const coursesInCart = useSelector((state) => state.cart.cart);
  const couponData = useSelector((state) => state.cart.coupon);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isConfettiRunning, toggleConfetti] = useState(false);
  const [showCouponCode, setShowCouponCode] = useState(false);
  const [language, setLanguage] = useState("english");

  const theme = useTheme();
  useEffect(() => {
    if (isConfettiRunning) {
      setTimeout(() => {
        toggleConfetti(false);
      }, 3000);
    }
  }, [isConfettiRunning]);

  const handleCourseRemove = (courseData, index) => {
    if (courseData.slug === COURSE_PLAN_DETAILS.slug) {
      localStorage.removeItem("cartData");
      dispatch(resetCart());
    } else if (courseData.slug === FREE_COURSE.slug) {
      let localCartData = localStorage.getItem("cartData");
      localCartData = localCartData ? JSON.parse(localCartData) : [];
      const validCartData = localCartData.filter(
        (x) => x.slug !== courseData.slug
      );
      if (validCartData?.length) {
        localStorage.setItem("cartData", JSON.stringify(validCartData));
      } else {
        localStorage.removeItem("cartData");
      }
      dispatch(removeFromCart(index));
    }

    if (couponData?._id) {
      dispatch(setCouponCode(null));
      childRef.current.resetCoupon();
    }
  };

  const totalPrice = useMemo(() => {
    const price = R.pipe(R.map(R.prop("course_amount")), R.sum)(coursesInCart);
    // + (HUB.base_amount, 10);

    if (couponData?._id) {
      return {
        price,
        discountPrice:
          couponData.courseOne +
          couponData.courseTwo +
          couponData.courseThree -
          couponData.courseFour,
        discount:
          price -
          (couponData.courseOne +
            couponData.courseTwo +
            couponData.courseThree),
        webinarDiscount: couponData.courseFour,
      };
    }
    return {
      price,
      discountPrice: null,
      discount: null,
      webinarDiscount: null,
    };
  }, [coursesInCart, couponData]);

  const handleCheckoutClick = () => {
    let queryParams = {};
    if (headerParam) {
      queryParams = { ...queryParams, header: false };
    }
    if (codeParam) {
      queryParams = { ...queryParams, "coupon-code": codeParam };
    }
    const queryString = new URLSearchParams(queryParams).toString();
    setShowCouponCode(false);
    history.push(`/checkout${queryString ? `?${queryString}` : ""}`);
  };

  const handleSecondCourseClick = (courseData, isCourseInCart) => {
    if (isCourseInCart < 0) {
      const localCartData = localStorage.getItem("cartData");
      const validCartData = localCartData ? JSON.parse(localCartData) : [];
      const localCartDataIndex = validCartData.findIndex(
        (x) => x._id === courseData._id
      );
      if (localCartDataIndex < 0) {
        validCartData.push(courseData);
      }
      localStorage.setItem("cartData", JSON.stringify(validCartData));
      dispatch(addToCart(courseData));
      if (isCourseInCart === -1) {
        courseAddedToCart(courseData.course_name);
      }
    }
  };

  const isSecondCourseInCart = coursesInCart.find(
    (x) => x._id === FREE_COURSE._id
  );

  useEffect(() => {
    dispatch(resetCart());
    const courseData = COURSE_PLAN_DETAILS;
    const validCartData = [];
    localStorage.removeItem("cartData");
    validCartData.push(courseData);
    localStorage.setItem("cartData", JSON.stringify(validCartData));
    dispatch(addToCart(courseData));
    handleSecondCourseClick(FREE_COURSE, -1);
    setShowCouponCode(true);
  }, []);

  const onLanguageChange = (e) => {
    setLanguage(e.target.value);
    if (e.target.value === "hindi") {
      handleCourseRemove(FREE_COURSE, 1);
    } else {
      handleSecondCourseClick(FREE_COURSE, -1);
    }
    localStorage.setItem("language", e.target.value);
  };

  if (coursesInCart.length === 0) {
    return (
      <Container>
        <Stepper />
        <Divider />
        <HeadingUnderline title="Cart" />
        <GrayRow key="1st-course">
          <Col md={6} xs={24}>
            <FullWidthImage src={COURSE_PLAN_DETAILS.course_image} />
          </Col>
          <Col md={12} xs={24}>
            <CourseDetails>
              <CourseDetailText>
                {COURSE_PLAN_DETAILS.course_name ?? ""}
              </CourseDetailText>
              <CourseDetailSubText>&nbsp;</CourseDetailSubText>
            </CourseDetails>
          </Col>
          <Col md={6} xs={24}>
            <CoursePrice>
              <div style={{ display: "flex" }}>
                {/* <CourseDetailTextStrike>
                  &#8377;
                  {COURSE_PLAN_DETAILS.base_amount || '3125'}
                </CourseDetailTextStrike> */}
                {/* &nbsp;&nbsp; */}
                <CourseDetailText>
                  &#8377;
                  {COURSE_PLAN_DETAILS.base_amount || "3125"}
                </CourseDetailText>
              </div>
              <CourseDetailSubText>
                {/* <TelegramColor>
                  20% Off - &#8377;
                  {Number(COURSE_PLAN_DETAILS.base_amount || '2999')
                    - Number(COURSE_PLAN_DETAILS.course_amount)}
                </TelegramColor> */}
              </CourseDetailSubText>
              <div style={{ marginTop: "10px" }}>
                <ArrowRightOutlined
                  className="arrow1"
                  style={{
                    color: "red",
                    fontSize: "22px",
                    marginRight: "15px",
                  }}
                />
                <BuyCourseButton
                  customClass="no-margin-right"
                  squareButton
                  text="Add to Cart"
                />
              </div>
            </CoursePrice>
          </Col>
        </GrayRow>
      </Container>
    );
  }

  const languageOptions = [
    { label: "English", value: "english" },
    { label: "Hindi", value: "hindi" },
  ];

  return (
    <>
      <Container>
        {isConfettiRunning && (
          <Confetti gravity={0.3} width={width - 15} height={height + 500} />
        )}
        <Stepper />
        <Divider />
        {!isMobileOnly && <HeadingUnderline title="Cart" />}
        {(language === "hindi" ? coursesInCart : coursesInCart)?.length ? (
          <Row>
            <Col xs={24} sm={24} md={24} lg={15}>
              <>
                <Row style={{ marginBottom: "10px" }}>
                  <h5>Language:&nbsp;&nbsp;&nbsp;</h5>
                  <Radio.Group
                    options={languageOptions}
                    onChange={onLanguageChange}
                    value={language}
                    optionType="button"
                    buttonStyle="solid"
                    size="large"
                  />
                  {/* <Select value={language} onChange={onLanguageChange}>
                    <Select.Option value="english">English</Select.Option>
                    <Select.Option value="hindi">Hindi</Select.Option>
                  </Select> */}
                </Row>
                <List
                  itemLayout="horizontal"
                  dataSource={coursesInCart}
                  renderItem={(item, index) => (
                    <>
                      {/* {index === 0 && (
                        <>
                          <YellowRow key={index}>
                            <Col md={6} xs={24}>
                              <FullWidthImage src={HUB.course_image} />
                            </Col>
                            <Col md={12} xs={24}>
                              <CourseDetails>
                                <CourseDetailText>
                                  {HUB.course_name ?? ''}
                                </CourseDetailText>
                              </CourseDetails>
                            </Col>
                            <Col md={6} xs={24}>
                              <CoursePrice>
                                <div style={{ display: 'flex' }}>
                                  <>
                                    {couponData?.courseThree ? (
                                      <>
                                        <CourseDetailTextStrike>
                                          &#8377;
                                          {HUB.base_amount || HUB.base_amount}
                                        </CourseDetailTextStrike>
                                        &nbsp;&nbsp;
                                        <CourseDetailText>
                                          &#8377;
                                          {couponData?.courseThree
                                            || HUB.base_amount}
                                        </CourseDetailText>
                                      </>
                                    ) : (
                                      <CourseDetailText>
                                        &#8377;
                                        {HUB.base_amount || HUB.base_amount}
                                      </CourseDetailText>
                                    )}
                                  </>
                                </div>
                              </CoursePrice>
                            </Col>
                          </YellowRow>
                        </>
                      )} */}
                      <GrayRow key={index}>
                        {codeParam ? (
                          <></>
                        ) : isMobileOnly ? (
                          index !== 0 ? (
                            <CloseCircleOutlinedWhiteIcon
                              onClick={() => handleCourseRemove(item, index)}
                            />
                          ) : (
                            <></>
                          )
                        ) : index !== 0 ? (
                          <CloseCircleOutlinedIcon
                            onClick={() => handleCourseRemove(item, index)}
                          />
                        ) : (
                          <></>
                        )}
                        <Col md={6} xs={24}>
                          <FullWidthImage src={item.course_image} />
                        </Col>
                        <Col md={12} xs={24}>
                          <CourseDetails>
                            <CourseDetailText>
                              {item.course_name ?? ""}
                            </CourseDetailText>
                            {/* {FREE_COURSE.slug === item.slug ? (
                        <CourseDetailSubText>
                          Congrats, You&apos;ve got an additional off worth
                          &#8377;301
                        </CourseDetailSubText>
                      ) : (
                        <CourseDetailSubText>&nbsp;</CourseDetailSubText>
                      )} */}
                          </CourseDetails>
                        </Col>
                        <Col md={6} xs={24}>
                          <CoursePrice>
                            <div style={{ display: "flex" }}>
                              {item.slug === COURSE_PLAN_DETAILS.slug ? (
                                <>
                                  {couponData?.courseOne ? (
                                    <>
                                      <CourseDetailTextStrike>
                                        &#8377;
                                        {item.base_amount ||
                                          COURSE_PLAN_DETAILS.base_amount}
                                      </CourseDetailTextStrike>
                                      &nbsp;&nbsp;
                                      <CourseDetailText>
                                        &#8377;
                                        {couponData?.courseOne ||
                                          COURSE_PLAN_DETAILS.base_amount}
                                      </CourseDetailText>
                                    </>
                                  ) : (
                                    <>
                                      <CourseDetailTextStrike>
                                        &#8377;
                                        {item.base_amount ||
                                          COURSE_PLAN_DETAILS.base_amount}
                                      </CourseDetailTextStrike>
                                      &nbsp;&nbsp;
                                      <CourseDetailText>
                                        &#8377;
                                        {item.course_amount ||
                                          COURSE_PLAN_DETAILS.course_amount}
                                      </CourseDetailText>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {couponData?.courseTwo ||
                                  couponData?.courseTwo === 0 ? (
                                    <>
                                      <CourseDetailTextStrike>
                                        &#8377;
                                        {item.base_amount ||
                                          FREE_COURSE.base_amount}
                                      </CourseDetailTextStrike>
                                      &nbsp;&nbsp;
                                      <CourseDetailText>
                                        {couponData?.courseTwo === 0 ? (
                                          "FREE"
                                        ) : (
                                          <span>
                                            &#8377;
                                            {couponData?.courseTwo ??
                                              FREE_COURSE.base_amount}
                                          </span>
                                        )}
                                      </CourseDetailText>
                                    </>
                                  ) : (
                                    <>
                                      <CourseDetailTextStrike>
                                        &#8377;
                                        {item.base_amount ||
                                          FREE_COURSE.base_amount}
                                      </CourseDetailTextStrike>
                                      &nbsp;&nbsp;
                                      <CourseDetailText>
                                        &#8377;
                                        {item.course_amount ||
                                          FREE_COURSE.course_amount}
                                      </CourseDetailText>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <CourseDetailSubText>
                              {/* <TelegramColor>
                          {FREE_COURSE.slug === item.slug ? '84%' : '20%'}
                          {' '}
                          Off -
                          &#8377;
                          {Number(item.base_amount || '3125')
                            - Number(item.course_amount)
                            + Number(FREE_COURSE.slug === item.slug ? 301 : 0)}
                        </TelegramColor> */}
                            </CourseDetailSubText>
                          </CoursePrice>
                        </Col>
                      </GrayRow>

                      {!isSecondCourseInCart && language !== "hindi" && (
                        <BlueRow key="free">
                          {/* <h6 className="vc">Limited-Time Offer</h6> */}
                          <Col md={6} xs={24}>
                            <FullWidthImage src={FREE_COURSE.course_image} />
                          </Col>
                          <Col md={12} xs={24}>
                            <CourseDetails>
                              <CourseDetailText>
                                {FREE_COURSE.course_name ?? ""}
                              </CourseDetailText>
                              {/* <CourseDetailSubText>
                          Get Trading Psychology Masterclass for just &#8377;800
                          and unlock a coupon code of &#8377;301
                        </CourseDetailSubText> */}
                            </CourseDetails>
                          </Col>
                          <Col md={6} xs={24}>
                            <CoursePrice>
                              <div style={{ display: "flex" }}>
                                <CourseDetailTextStrike>
                                  &#8377;
                                  {FREE_COURSE.base_amount || "3125"}
                                </CourseDetailTextStrike>
                                &nbsp;&nbsp;
                                <CourseDetailText>
                                  &#8377;
                                  {FREE_COURSE.course_amount || "4000"}
                                </CourseDetailText>
                              </div>
                              <CourseDetailSubText>
                                {/* <TelegramColor>
                            73% Off - &#8377;
                            {Number(FREE_COURSE.base_amount || '2999')
                              - Number(FREE_COURSE.course_amount)}
                          </TelegramColor> */}
                              </CourseDetailSubText>
                              <div>
                                <ArrowRightOutlined
                                  className="arrow1"
                                  style={{
                                    color: "red",
                                    fontSize: "22px",
                                    marginRight: "15px",
                                  }}
                                />
                                <Button
                                  onClick={() =>
                                    handleSecondCourseClick(FREE_COURSE, -1)
                                  }
                                  className="signUpBtn"
                                  size="large"
                                >
                                  Yes, I will take it
                                </Button>
                              </div>
                            </CoursePrice>
                          </Col>
                        </BlueRow>
                      )}
                    </>
                  )}
                />
              </>
            </Col>
            <Col xs={0} sm={0} md={0} lg={1} />
            <Col xs={24} sm={24} md={24} lg={8}>
              <div
                style={{
                  border: "2px solid #f4f4f4",
                  margin: "0px 5px 5px",
                  borderRadius: "5px",
                  padding: "5px",
                  height: "100%",
                }}
              >
                <Row>
                  <Col span={24}>
                    <p
                      style={{
                        marginBottom: 0,
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "17px",
                      }}
                    >
                      Cart Summary
                    </p>
                  </Col>
                  <Divider />
                  {couponData && (
                    <>
                      <Col md={10} xs={10}>
                        <PaddedDiv>
                          <TotalText>Original Price</TotalText>
                        </PaddedDiv>
                      </Col>
                      <Col md={4} xs={4} />
                      <Col md={10} xs={10}>
                        <NoPaddedCoursePrice>
                          <CourseDetailText>
                            &#8377;
                            <span className="course-price">
                              {totalPrice?.price}
                            </span>
                          </CourseDetailText>
                        </NoPaddedCoursePrice>
                      </Col>
                      <Col md={10} xs={10}>
                        <PaddedDiv>
                          <TotalText>Coupon Discount</TotalText>
                        </PaddedDiv>
                      </Col>
                      <Col md={4} xs={4} />
                      <Col md={10} xs={10}>
                        <NoPaddedCoursePrice>
                          <CourseDetailText>
                            <span style={{ color: "green" }}>
                              &#8377;
                              <span className="course-price">
                                {totalPrice?.discount}
                              </span>
                            </span>
                          </CourseDetailText>
                        </NoPaddedCoursePrice>
                      </Col>
                      {totalPrice?.webinarDiscount > 0 && (
                        <>
                          <Col md={10} xs={10}>
                            <PaddedDiv>
                              <TotalText>Webinar Discount</TotalText>
                            </PaddedDiv>
                          </Col>
                          <Col md={4} xs={4} />
                          <Col md={10} xs={10}>
                            <NoPaddedCoursePrice>
                              <CourseDetailText>
                                <span style={{ color: "green" }}>
                                  &#8377;
                                  <span className="course-price">
                                    {totalPrice?.webinarDiscount}
                                  </span>
                                </span>
                              </CourseDetailText>
                            </NoPaddedCoursePrice>
                          </Col>
                        </>
                      )}
                      <Divider />
                    </>
                  )}
                  <Col md={6} xs={6}>
                    <div>
                      <TotalText>Total</TotalText>
                    </div>
                  </Col>
                  <Col md={12} xs={0} />
                  <Col md={6} xs={18}>
                    <NoPaddedCoursePrice>
                      {totalPrice?.discountPrice ? (
                        <div style={{ display: "flex" }}>
                          <CourseDetailTextStrike>
                            &#8377;
                            {totalPrice?.price}
                          </CourseDetailTextStrike>
                          &nbsp; &nbsp;
                          <CourseDetailText>
                            <span style={{ color: theme.backgrounds.telegram }}>
                              &#8377;
                              <span className="course-price">
                                {totalPrice?.discountPrice}
                              </span>
                            </span>
                          </CourseDetailText>
                        </div>
                      ) : (
                        <CourseDetailText>
                          <span
                            style={{ color: theme.backgrounds.telegram }}
                            className="course-price"
                          >
                            &#8377;
                            {totalPrice?.price}
                          </span>
                        </CourseDetailText>
                      )}
                    </NoPaddedCoursePrice>
                  </Col>

                  <Col md={10} xs={0} />
                  <Col md={14} xs={24}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <InclusiveText>Inclusive of all charges</InclusiveText>
                    </div>
                  </Col>
                </Row>
                <Divider />
                <ActionButtonContainerStyled>
                  {showCouponCode ? (
                    <CouponCode
                      externalCoupon={isSecondCourseInCart ? "tlt300" : null}
                      ref={childRef}
                      toggleConfetti={toggleConfetti}
                    />
                  ) : (
                    <></>
                  )}
                  <Button
                    onClick={handleCheckoutClick}
                    className="purpleButton"
                    size="large"
                    style={{ width: "100%", marginTop: "5px" }}
                  >
                    Checkout
                  </Button>
                </ActionButtonContainerStyled>
              </div>
            </Col>
          </Row>
        ) : (
          <NoItemsFoundText>No items in the cart</NoItemsFoundText>
        )}
        {isMobile && (
          <CheckoutContainer>
            <Button
              onClick={handleCheckoutClick}
              className="purpleButton mobile-fixed-button"
              size="large"
            >
              Checkout
            </Button>
          </CheckoutContainer>
        )}
        <Divider />
        <Row className="grey-bg testimonials-brief-bg">
          <Col span={24}>
            <Testimonials hideButton showGreyBg />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Spacer />
            <Spacer />
            <Spacer />
          </Col>
        </Row>
      </Container>
      {headerParam && <WebinarFooter />}
    </>
  );
};

export default Cart;

const ActionButtonContainerStyled = styled(ActionButtonContainer)`
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

const BlueRow = styled(GrayRow)`
  background-color: aliceBlue;
`;

const TotalText = styled(CourseDetailText)`
  font-size: 16px;
`;

const YellowRow = styled(GrayRow)`
  background-color: #f9ae00;
`;
