import { Col, Row } from 'antd';
import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import HeadingUnderline from '../HeadingUnderline';
import flipboard from '../../assets/Flipboard.png';
import aol from '../../assets/aol.png';
import bl from '../../assets/bi.png';
import ccn from '../../assets/ccn.png';
import fox from '../../assets/foxx.png';
import investing from '../../assets/Investing.png';
import investorplace from '../../assets/ibt.png';
import fool from '../../assets/fool.svg';
import msn from '../../assets/msn.png';
import nasdaq from '../../assets/nasdaq.png';
import seeking from '../../assets/seekingalpha.png';
import street from '../../assets/thestreet.png';
import valueWalk from '../../assets/vwalk.png';
import yf from '../../assets/yf.png';

export const ImageDiv = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FeaturedImage = styled.img`
  width: 120px;
  @media (max-width: 767px) {
    width: 90px;
  }
`;

const FeaturedOn = () => (
  <Container>
    <Row justify="center" className="blogs-brief">
      <Col>
        <HeadingUnderline title="Our Work Has Featured On" />
      </Col>
    </Row>
    <Row>
      <Col lg={4} md={6} xs={8}>
        <ImageDiv>
          <FeaturedImage src={fox} />
        </ImageDiv>
      </Col>
      <Col lg={4} md={6} xs={8}>
        <ImageDiv>
          <FeaturedImage src={yf} />
        </ImageDiv>
      </Col>
      <Col lg={4} md={6} xs={8}>
        <ImageDiv>
          <FeaturedImage src={flipboard} />
        </ImageDiv>
      </Col>
      <Col lg={4} md={6} xs={8}>
        <ImageDiv>
          <FeaturedImage src={bl} />
        </ImageDiv>
      </Col>
      <Col lg={4} md={6} xs={8}>
        <ImageDiv>
          <FeaturedImage src={nasdaq} />
        </ImageDiv>
      </Col>

      <Col lg={4} md={6} xs={8}>
        <ImageDiv>
          <FeaturedImage src={investing} />
        </ImageDiv>
      </Col>

      <Col lg={4} md={6} xs={8}>
        <ImageDiv>
          <FeaturedImage src={msn} />
        </ImageDiv>
      </Col>
      <Col lg={4} md={6} xs={8}>
        <ImageDiv>
          <FeaturedImage src={aol} />
        </ImageDiv>
      </Col>

      <Col lg={4} md={6} xs={8}>
        <ImageDiv>
          <FeaturedImage src={fool} />
        </ImageDiv>
      </Col>
      <Col lg={4} md={6} xs={8}>
        <ImageDiv>
          <FeaturedImage src={seeking} />
        </ImageDiv>
      </Col>
      <Col lg={4} md={6} xs={8}>
        <ImageDiv>
          <FeaturedImage src={street} />
        </ImageDiv>
      </Col>
      <Col lg={4} md={6} xs={8}>
        <ImageDiv>
          <FeaturedImage src={investorplace} />
        </ImageDiv>
      </Col>
      {!isMobileOnly && (
      <>
        <Col lg={4} md={0} xs={0} />
        <Col lg={4} md={6} xs={4} />
        <Col lg={4} md={6} xs={8}>
          <ImageDiv>
            <FeaturedImage src={valueWalk} />
          </ImageDiv>
        </Col>
        <Col lg={4} md={6} xs={8}>
          <ImageDiv>
            <FeaturedImage src={ccn} />
          </ImageDiv>
        </Col>
        <Col lg={4} md={6} xs={4} />
        <Col lg={4} md={0} xs={0} />
      </>
      )}
    </Row>
  </Container>
);

export default FeaturedOn;
