import React from 'react';
import { Container } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';

const Heading = styled.h2`
  color: white;
  font-weight: bold;
  text-align: center;
  padding-top: 15px;
`;

const SubHeading = styled.h3`
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
`;

const SubHeadingmobile = styled.h4`
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
`;

const YellowSpan = styled.span`
  color: #F9AE00;
`;

const UnderMaintenanceContent = () => (
  <Container>
    <Heading>
      The&nbsp;
      <YellowSpan>Logical</YellowSpan>
      &nbsp;
      Traders
    </Heading>
    {!isMobileOnly ? (
      <SubHeading>
        We are currently working on
        <br />
        something awesome. Stay tuned.
      </SubHeading>
    )
      : (
        <SubHeadingmobile>
          We are currently working on
          <br />
          something awesome and will be back soon.
        </SubHeadingmobile>
      )}
  </Container>
);

export default UnderMaintenanceContent;
