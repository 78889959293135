import React, { Suspense, useRef } from 'react';
import { Col, Row } from 'antd';
import { isMobile, isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import ScrollArrow from '../../components/ScrollTop';
import Share from '../../components/ShareContainer';
import Counter from '../../components/Counter';
import HomeBg from '../../components/HomeBg';

import homeImage from '../../assets/bannerImage.png';
import mobileImage from '../../assets/banner-m.png';

import VideoSection from '../../components/HomeBg/VideoSection';
import HeadingUnderline from '../../components/HeadingUnderline';
import BuyCourseButton from '../../components/BuyCourseButton';
import { Spacer } from '../about';
import FixedBuyButton from '../../components/FixedBuyButton';

const WhyOurCourse = React.lazy(() => import('../../components/WhyOurCourse'));
const WhatYouGet = React.lazy(() => import('../../components/WhatYouGet'));
const HowYouBenefit = React.lazy(() => import('../../components/HowYouBenefit'));
const Experts = React.lazy(() => import('../../components/OurExperts'));
const FeaturedOn = React.lazy(() => import('../../components/FeaturedOnSection'));
const Testimonials = React.lazy(() => import('../../components/Testimonials'));
const Subscribe = React.lazy(() => import('../../components/Subscribe'));
const LandingPage = React.lazy(() => import('../landingPage'));

const Home = () => {
  const performanceRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo({ top: ref.current.offsetTop - 80, behavior: 'smooth' });
  const executeScroll = () => scrollToRef(performanceRef);

  const HomeBackground = styled.div`
    background-image: url(${!isMobileOnly ? homeImage : mobileImage});
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: relative;
  `;

  const BlackSpacer = styled(Spacer)`
    background: black;
  `;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Helmet>
        <meta
          name="description"
          content="How to become a trader, can you become a full time trader, best Indian stock market service, which stocks to buy, daily stock tips, best shares to buy, best Indian share market course, how to trade commodities, how to invest in shares, how to trade futures and options, how to trade forex, intraday calls, free stock market tips, best trading websites in India, commodity market trading in India, how to make money in stock market, how to become a profitable trader, can you make money regularly in the stock market, bank nifty options trading, nifty options trading, free stock tips, daily intraday calls free"
        />
      </Helmet>
      <div className="clearfix">
        <Share />
        <LandingPage />
        {/* <HomeBackground className="black-bg">
          <HomeBg executeScroll={executeScroll} />
        </HomeBackground>
        {isMobile && (
          <div style={{ background: 'black' }}>
            <div className="clearfix" />
            <div
              style={{ paddingBottom: '0px' }}
              className="black-bg padding-30"
            >
              <Row justify="center" className="blogs-brief">
                <Col>
                  <HeadingUnderline title="About The Course" whitetext />
                </Col>
              </Row>
            </div>
            <div className="black-bg padding-30">
              <VideoSection
                heading1=""
                url="https://vimeo.com/670118315"
                type={1}
                showBottomMargin
              />
            </div>
            <BlackSpacer />
            <BlackSpacer />
          </div>
        )}
        <div className="clearfix" />
        <div className="clearfix" />
        <div style={{ padding: '15px 0' }} className="black-bg">
          <Counter />
        </div>
        <div className="clearfix" />
        <div className="clearfix" />
        <Row className="grey-bg padding-30">
          <Col span={24}>
            <Experts />
          </Col>
        </Row>
        <div className="clearfix" />
        <div
          ref={performanceRef}
          style={{ paddingBottom: '0px' }}
          className="white-bg padding-30"
        >
          <Row justify="center" className="blogs-brief">
            <Col>
              <HeadingUnderline title="Our Performance" />
            </Col>
          </Row>
        </div>
        <div style={{ paddingTop: '5px' }} className="white-bg padding-5">
          <VideoSection
            heading1="1497.74% Returns in 2020"
            url="https://youtu.be/9J90XAL-LUU"
            showBottomMargin
            showTopMargin
            type={1}
          />
        </div>
        <div className="white-bg padding-5">
          <VideoSection
            heading1="119.20% Returns in 2021"
            url="https://youtu.be/5naFLpEJy7M"
            videoRight
            showBottomMargin
            showTopMargin
            type={1}
          />
        </div>
        <div className="white-bg padding-5">
          <VideoSection
            heading1="15+ Profitable Years on"
            heading2="Wall Street"
            url="https://youtu.be/ZdAS2vdugPM"
            showBottomMargin
            showTopMargin
            type={1}
          />
        </div>
        {!isMobile && (
          <Row
            justify="center"
            style={{ marginBottom: isMobile ? '45px' : '40px' }}
          >
            <BuyCourseButton flatButton />
          </Row>
        )}
        <div className="clearfix" />
        <div className="black-bg padding-30">
          <HowYouBenefit />
        </div>
        <div className="clearfix" />
        <Row className="testimonials-brief-bg">
          <Col span={24}>
            <WhyOurCourse />
          </Col>
        </Row>
        <div className="clearfix" />
        <Row className="grey-bg testimonials-brief-bg">
          <FeaturedOn />
        </Row>
        <Row justify="center" className="padding-30">
          <Container className="subscribe-section">
            <Subscribe />
          </Container>
        </Row>
        {isMobile && <FixedBuyButton />}
        <div className="clearfix" />
        <Row className="grey-bg testimonials-brief-bg">
          <Col span={24}>
            <Testimonials showGreyBg hideButton={isMobile} />
          </Col>
        </Row>
        <div className="clearfix" /> */}
        {!isMobileOnly && <ScrollArrow />}
        <div className="clearfix" />
      </div>
    </Suspense>
  );
};

export default Home;
