import { Container } from 'react-bootstrap';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import { FiActivity, FiStar } from 'react-icons/fi';
import { FaComments, FaMagic } from 'react-icons/fa';
import { theme } from '../../utils/theme';
import ActionButton from './ActionButton';
import { ActionButtonContainer } from './BannerSection';
import { SectionHeadingText } from './Common';

const ContentList = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

const ContentListText = styled.span`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
`;

const ContentSubText = styled.span`
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 5px;
`;

const ContentBorderDiv = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 7px -2px #000000;
  border-radius: 8px;
  width: 80%;
  @media (max-width: 991px) {
    width: 95%;
  }
`;

const WhatYouGet = () => (
  <Container>
    <div style={{ padding: '20px 0' }}>
      <Row justify="center">
        <Col>
          <SectionHeadingText>
            You Won&apos;t Believe What You&apos;ll Get For Just &#8377;499 In The Webinar
          </SectionHeadingText>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={8}>
          <ContentList>
            <ContentBorderDiv>
              <IconDiv>
                <BankOutlined
                  style={{ color: theme.colors.main, fontSize: 30 }}
                  size={30}
                />
              </IconDiv>
              <ContentListText>
                My Custom Nifty &
                <br />
                Bank Nifty Indicator
              </ContentListText>
              <ContentSubText>
                My custom made, highly advanced,
                {' '}
                <br />
                indicator for
                <br />
                Nifty & Bank Nifty Traders.
              </ContentSubText>
            </ContentBorderDiv>
          </ContentList>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <ContentList>
            <ContentBorderDiv>
              <IconDiv>
                <FiActivity
                  style={{ color: theme.colors.main, fontSize: 30 }}
                  size={30}
                />
              </IconDiv>
              <ContentListText>
                1 Bank Nifty
                <br />
                Strategy
              </ContentListText>
              <ContentSubText>
                A strategy you can
                <br />
                use for
                <br />
                positional and intraday trading
              </ContentSubText>
            </ContentBorderDiv>
          </ContentList>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <ContentList>
            <ContentBorderDiv>
              <IconDiv>
                <FaMagic
                  style={{ color: theme.colors.main, fontSize: 30 }}
                  size={30}
                />
              </IconDiv>
              <ContentListText>
                1 Unique
                <br />
                Trading Indicator
                {' '}
              </ContentListText>
              <ContentSubText>
                A very
                <br />
                {' '}
                underrated
                <br />
                trading indicator.
              </ContentSubText>
            </ContentBorderDiv>
          </ContentList>
        </Col>
        <Col xs={24} sm={24} md={4} />
        <Col
          xs={24}
          sm={24}
          md={8}
          style={{ marginTop: !isMobileOnly && '40px' }}
        >
          <ContentList>
            <ContentBorderDiv>
              <IconDiv>
                <FaComments
                  style={{ color: theme.colors.main, fontSize: 30 }}
                  size={30}
                />
              </IconDiv>
              <ContentListText>
                A Q&A
                <br />
                Session
              </ContentListText>
              <ContentSubText>
                A small Q&A session
                <br />
                after the webinar
                <br />
                to clear your doubts.
              </ContentSubText>
            </ContentBorderDiv>
          </ContentList>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          style={{ marginTop: !isMobileOnly && '40px' }}
        >
          <ContentList>
            <ContentBorderDiv>
              <IconDiv>
                <FiStar
                  style={{ color: theme.colors.main, fontSize: 30 }}
                  size={30}
                />
              </IconDiv>
              <ContentListText>
                My Favorite
                <br />
                Indicator
              </ContentListText>
              <ContentSubText>
                RSI & MACD maybe good
                <br />
                but this indicator
                <br />
                is way better.
              </ContentSubText>
            </ContentBorderDiv>
          </ContentList>
        </Col>
        <Col xs={24} sm={24} md={4} />
      </Row>
    </div>
    <ButtonText>
      Seriously, for &#8377;499, this is the best Risk/Reward ratio you can find
    </ButtonText>
    <SpacedActionButtonContainer>
      <ActionButton />
    </SpacedActionButtonContainer>
  </Container>
);

export default WhatYouGet;

const IconDiv = styled.div`
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  border-radius: 50%;
  margin-bottom: 10px;
`;

export const ButtonText = styled.p`
  text-align: center;
  margin-top: 15px;
  font-weight: bold;
  color: ${theme.colors.main};
  margin-bottom: 0;
`;

export const SpacedActionButtonContainer = styled(ActionButtonContainer)`
  margin-bottom: 30px;
`;
