import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import API_ENDPOINTS from '../api/apiEndpoints';
import { checkIsLocalhost, getEnvValue, triggerGAEvent } from '../utils/helper';

const PayByRazorPay = forwardRef((props, ref) => {
  const {
    description,
    amount,
    handlePaymentSuccess,
    name,
    contact,
    email,
    setLoading,
    isWebinar,
  } = props;
  const localUtmMedium = localStorage.getItem('utmSource');
  const localUtmCampaign = localStorage.getItem('utmCampaign');
  const localUtmCoversionAd = localStorage.getItem('localUtmCoversionAd');
  const isUtmMediumFB = localUtmMedium && localUtmMedium === 'fb';
  const isLocalhost = checkIsLocalhost();
  const emailAddress = email.split('@');
  let options = {
    key: getEnvValue(isLocalhost ? 'RAZORPAY_TEST_KEY' : 'RAZORPAY_PROD_KEY'),
    amount, //  = INR 1
    name: 'The Logical Traders',
    description,
    notes: {
      conversion_source: localUtmMedium ? 'Facebook' : 'Google',
      conversion_campaign: localUtmCampaign || 'Google',
      conversion_ad: localUtmCoversionAd || 'NA',
    },
    handler(response) {
      const data = {
        category: 'Payment',
        action: 'Payment Success',
        label: `${emailAddress[0] || ''} - ${name || ''} - ${contact || ''} - ${
          emailAddress[1] || ''
        }`,
        value: Number(amount) / 100,
      };
      triggerGAEvent(data);
      const conversionData = {
        category: 'Conversion',
        action: isWebinar ? 'Webinar Registration' : 'Course Purchased',
        label: isWebinar ? 'Webinar Registration' : 'Course Purchased',
        value: Number(amount) / 100,
      };
      triggerGAEvent(conversionData);
      handlePaymentSuccess(response);
    },
    modal: {
      ondismiss() {
        const data = {
          category: 'Payment',
          action: 'Payment Cancelled',
          label: `${emailAddress[0] || ''} - ${name || ''} - ${
            contact || ''
          } - ${emailAddress[1] || ''}`,
          value: Number(amount) / 100,
        };
        triggerGAEvent(data);
        setLoading(false);
      },
      confirm_close: true,
    },
    prefill: {
      name,
      contact,
      email,
    },
    theme: {
      color: '#F9AE00',
      hide_topbar: false,
    },
  };

  if (isUtmMediumFB) {
    options = {
      ...options,
      callback_url: `${getEnvValue('API_BASE_URL')}${isWebinar ? API_ENDPOINTS.PAYMENT_STATUS_WEBINAR : API_ENDPOINTS.PAYMENT_STATUS}?host_url=${window.location.origin}&email=${email}&amount=${Number(amount) / 100}`,
      redirect: true,
    };
  }

  const openPayModal = (orderId) => {
    const rzp1 = new window.Razorpay({
      ...options, order_id: orderId || '',
    });
    rzp1.on('payment.failed', () => {
      const data = {
        category: 'Payment',
        action: 'Payment Failed',
        label: `${emailAddress[0] || ''} - ${name || ''} - ${contact || ''} - ${
          emailAddress[1] || ''
        }`,
        value: Number(amount) / 100,
      };
      triggerGAEvent(data);
      setLoading(false);
    });
    rzp1.open();
  };

  useImperativeHandle(ref, () => ({
    handlePayment(orderId) {
      openPayModal(orderId);
    },
  }));

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return <></>;
});

export default PayByRazorPay;
