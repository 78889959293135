import React, { useEffect, useState } from 'react';
import {
  Button, Col, Row, Table,
} from 'antd';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import ReactExport from 'react-export-excel';
import Search from 'antd/lib/input/Search';
import { axiosAuth } from '../../api';
import API_ENDPOINTS from '../../api/apiEndpoints';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const UsersContainer = styled.div`
  margin-top: 40px;
  padding: 0 20px;
`;

const AllLiveQaUsers = ({ common }) => {
  const user = common?.user?.userType || '';
  const [allUsersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const getUsers = () => {
    axiosAuth
      .get(`${API_ENDPOINTS.ALL_LIVE_QA_SUBS}?limit=2000&offset=0`)
      .then((res) => {
        if (res?.data?.data) {
          const finalData = res?.data?.data.sort((a, b) => moment(b.createdAt).diff(a.createdAt));
          setUsersList(finalData);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user === 'admin') {
      setLoading(true);
      getUsers();
    }
  }, [user]);

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      key: 'sno',
      render: (sno, data, index) => index + 1,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ];

  const filteredUsers = allUsersList.filter((item) => (
    (item.email || '').toLowerCase().includes(search.toLowerCase())
  ));
  return (
    <UsersContainer>
      <ExcelFile
        filename="All Users"
        element={(
          <Button type="primary" className="export-users">
            Export Users Excel
          </Button>
        )}
      >
        <ExcelSheet data={allUsersList} name="subscribers">
          <ExcelColumn label="Email" value="email" />
        </ExcelSheet>
      </ExcelFile>
      {user === 'admin' ? (
        <>
          <Row className="mb-10">
            <Col span={8}>
              <Search
                placeholder="input search text"
                onChange={({ target }) => setSearch(target.value)}
              />
            </Col>
          </Row>

          <h4>All Subscribers</h4>
          <Table
            loading={loading}
            dataSource={filteredUsers}
            columns={columns}
            pagination={{
              pageSizeOptions: [100, 200, 500],
              defaultPageSize: 500,
              showSizeChanger: true,
            }}
          />
        </>
      ) : (
        <></>
      )}
    </UsersContainer>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

export default connect(mapStateToProps, null)(AllLiveQaUsers);
