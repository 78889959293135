/* eslint-disable no-underscore-dangle */
import { Button } from "antd";
import styled from "styled-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToCart } from "../../actions/cart";
import { courseAddedToCart, getEnvValue } from "../../utils/helper";

export const PrimaryButton = styled(Button)`
  background: #9932cc;
  border: 2.5px solid #9932cc;
  box-sizing: border-box;
  box-shadow: 0px 4.42006px 3.86756px rgba(0, 0, 0, 0.35);
  border-radius: 30px;
  margin-right: 25px;
  color: white;
  font-weight: bold;
  &:hover,
  &:active,
  &:focus {
    background: #9932cc !important;
    border: 2.5px solid #9932cc !important;
    color: white !important;
  }
`;

const BuyButton = styled(PrimaryButton)`
  border-radius: ${({ squareButton }) => (squareButton ? "8px" : "30px")};
  margin-right: ${({ flatButton }) => (flatButton ? 0 : "25px")};
  height: 44px;
  font-size: 16px;
  width: 200px;
  @media (max-width: 767px) {
    width: 250px;
  }
`;

export const COURSE_PLAN_DETAILS = {
  base_amount: "8000",
  course_amount: "3999",
  course_description: "Hidden secrets to making money in the stock market",
  course_duration: "10+ hrs",
  course_id: "168",
  course_image:
    "https://elearning.thelogicaltraders.com/wp-content/uploads/2020/10/Thumbnail-Revised-scaled.jpg",
  course_lessons: "104",
  course_name: "Profitable Trading Masterclass",
  course_validity: "12 Months",
  duration: 0,
  no_of_lessons: 0,
  slug: "technical-analysis",
  _id: getEnvValue("MAIN_COURSE_ID"),
};

const BuyCourseButton = ({
  flatButton,
  squareButton,
  showPrice,
  customClass,
  text,
}) => {
  const coursesInCart = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCourseClick = (courseData, isCourseInCart) => {
    history.push("/cart");
  };

  const isCourseInCart = coursesInCart.findIndex(
    (x) => x._id === COURSE_PLAN_DETAILS?._id
  );

  return (
    <BuyButton
      onClick={() => handleCourseClick(COURSE_PLAN_DETAILS, isCourseInCart)}
      flatButton={flatButton}
      squareButton={squareButton}
      className={`pulse ${customClass}`}
    >
      {text ?? "BUY COURSE"}
      {showPrice && (
        <span>
          &nbsp; &#8377;
          {COURSE_PLAN_DETAILS.course_amount}
        </span>
      )}
    </BuyButton>
  );
};

export default BuyCourseButton;
