import { Button } from 'antd';
import styled from 'styled-components';

export const CourseImage = styled.img`
  margin-left: -1px;
  margin-top: -1px;
  max-height: 156px;
`;

export const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;
