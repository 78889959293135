/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import { Container } from 'react-bootstrap';
import {
  Col, Row, notification, Spin,
} from 'antd';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { setNotificationCount } from '../../actions/notification';
import NotificationBox from './notificationBox';
import { axiosAuth } from '../../api';
import HeadingUnderline from '../../components/HeadingUnderline';
import { Spacer } from '../about';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { CenteredDiv } from '../courses/styled';

const Notifications = () => {
  const hasMore = useRef(true);
  const [allNotifications, setAllNotifications] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [loadingMore, setloadingMore] = useState(false);
  const dispatch = useDispatch();

  const fetchMore = useCallback((paging) => {
    if (!hasMore.current) {
      return;
    }
    const endpoint = `${API_ENDPOINTS.GET_ALL_NOTIFICATION}?offset=${paging * 50}&limit=50`;
    axiosAuth
      .get(endpoint)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          if (res?.data?.data.length > 0) {
            setAllNotifications(res?.data?.data);
            setCurrentPage(paging);
          }
          if (res?.data?.data?.length < 50) {
            hasMore.current = false;
          }
        }
        setloadingMore(false);
        setLoading(false);
      })
      .catch(() => {
        setloadingMore(false);
        setLoading(false);
      });
  }, []);

  const handleScroll = useCallback(
    (e) => {
      const windowHeight = 'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
      const { body } = document;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom + 200 >= docHeight && !loadingMore && hasMore.current) {
        setloadingMore(true);
        fetchMore(currentPage + 1);
      }
    },
    [currentPage, loadingMore, hasMore],
  );

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('scroll', handleScroll);
    }, 100);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    setLoading(true);
    const endpoint = `${API_ENDPOINTS.GET_ALL_NOTIFICATION}?offset=${currentPage * 50}&limit=50`;
    axiosAuth
      .get(endpoint)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          if (res?.data?.data.length > 0) {
            setAllNotifications(res?.data?.data);
            axiosAuth.post(API_ENDPOINTS.MARK_AS_READ, { notificationIds: [] });
            dispatch(setNotificationCount(0));
          }
          if (res?.data?.data?.length < 50) {
            hasMore.current = false;
          }
        } else {
          notification.error({
            message: 'Error',
            description:
              'Failed to load notifications. Please try again later!!',
          });
          hasMore.current = false;
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        hasMore.current = false;
      });
  }, []);

  useEffect(() => {
    const finalDataNew = R.uniqBy(R.prop('_id'), [
      ...finalData,
      ...allNotifications,
    ]);
    setFinalData(finalDataNew);
  }, [allNotifications]);

  return (
    <Container>
      <Spacer />
      <HeadingUnderline title="Notifications" />
      <Row>
        <Col span={24}>
          {loading ? (
            <Col span={24}>
              <CenteredDiv>
                <LoadingOutlined
                  style={{ fontSize: 30, color: '#f9ae00' }}
                  spin
                />
              </CenteredDiv>
            </Col>
          ) : (
            finalData?.map((item) => <NotificationBox key={item._id} data={item} />)
          )}
        </Col>
        <Col span={24}>
          <Row className="mb-20" justify="center">
            {loadingMore ? (
              <Spin />
            ) : !hasMore.current ? (
              finalData?.length ? (
                <p className="mb-20">That's all folks</p>
              ) : (
                <p className="mb-20 mt-25"><strong>No Notifications Found</strong></p>
              )
            ) : (
              ''
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Notifications;
