import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Row } from 'antd';
import Blink from 'react-blink-text';
import THNX from '../../assets/thnx.png';
import { axiosAuth } from '../../api';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { Spacer } from '../about';

export const SocialMediaButton = styled.a`
  height: 40px;
  line-height: 40px;
  min-width: 40px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  backface-visibility: hidden;
  transition: box-shadow 0.2s ease, border-color 0.2s ease, opacity 0.3s;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px;
  font-size: 0;
  border-radius: 4px;
  opacity: 1;
  margin: 6px;
  background-color: ${({ color }) => color || 'white'};
  &:hover {
    box-shadow: none;
    border-color: transparent;
    background-color: black;
  }
`;

export const SocialMediaIcon = styled.span`
  background: rgba(255, 255, 255, 0.1);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 12px;
  transition: all 0.2s ease;
  z-index: 2;
`;

export const SocialMediaText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  padding: 0 10px 0 44px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  box-sizing: border-box;
  transition: all 0.2s ease;
  backface-visibility: hidden;
  position: relative;
  z-index: 2;
  color: #fff;
`;

export const SocialMediaIconsContainer = styled.div`
  display: flex;
  margin: 10px 0px;
  a:first-child {
    margin-left: 0;
  }
`;

const ParentContainer = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;

const GreyText = styled.h4`
  color: grey;
  text-align: center;
  margin: 10px 0px 15px 0px;
`;

const ContentContainer = styled.div`
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 350px;
  }
`;

const RegisterThankYou = () => {
  const [webinarConfig, setWebinarConfig] = useState(null);

  const getWebinarConfig = async () => {
    const webinarConfigData = await axiosAuth.get(API_ENDPOINTS.WEBINAR_CONFIG);
    if (webinarConfigData?.data?.whatsappLink) {
      setWebinarConfig(webinarConfigData?.data);
    }
  };

  useEffect(() => {
    getWebinarConfig();
  }, []);

  return (
    <Container>
      <ParentContainer>
        <ContentContainer>
          <Row justify="center">
            <img width="250px" src={THNX} alt="thank-you" />
          </Row>
          <Row justify="center">
            <GreyText>You&apos;ve successfully registered.</GreyText>
          </Row>
          <Row justify="center">
            <Blink color="red" text="IMPORTANT" fontSize="40px" />
            {' '}
            <GreyText>
              You will get the ID & password to join the webinar in our WhatsApp
              group.
            </GreyText>
          </Row>
          <Row>
            <GreyText>Please click the following button to join it:</GreyText>
          </Row>
          <Row>
            <LinkButton
              href={
                webinarConfig?.whatsappLink
                || 'https://chat.whatsapp.com/EdcX8owcesHKL6vLmF8kWq'
              }
              target="_blank"
              rel="noreferrer"
            >
              Join Whatsapp Group
            </LinkButton>
          </Row>
          <Row>
            <Spacer />
            <GreyLittleText>
              <strong>Note:</strong>
              {' '}
              In case you&apos;re not able to join the WhatsApp group using this
              button, please text us on WhatsApp on 6267990054 or email us at
              support@thelogicaltraders.com and share your active WhatsApp
              number that you&apos;d like for us to add to the group.
            </GreyLittleText>
          </Row>
        </ContentContainer>
      </ParentContainer>
    </Container>
  );
};

export default RegisterThankYou;

const LinkButton = styled.a`
  background: #25d366;
  padding: 20px;
  border-radius: 8px;
  font-weight: bold;
  color: white;
  font-size: 20px;
  margin-top: 10px;
  &:hover {
    color: white;
  }
`;

const GreyLittleText = styled(GreyText)`
  font-size: 14px;
`;
