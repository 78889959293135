import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import HeadingUnderline from "../../components/HeadingUnderline";

const Spacer = styled.div`
  height: 20px;
`;

const TermsConditions = () => (
  <Container>
    <Spacer />
    <HeadingUnderline title="Terms & Conditions" />
    <h5>
      <strong>UNAUTHORIZED DISCLOSURE</strong>
    </h5>
    <p>
      The information provided in this course is private, privileged, and
      confidential, licensed for your sole individual use as a buyer. The
      Logical Traders reserves all rights to the content of the courses of our
      website <a href={window.location.origin}>course.TheLogicalTraders.com</a>.
      Forwarding, copying, disseminating, or distributing the contents of
      courses on our website in whole or in part, including substantial video
      clips of any portion of the course, is strictly prohibited and will result
      in the termination of your account. The Logical Traders may decide to
      pursue legal action against someone who forwards, copies, disseminates or
      distributes contents of our course in whole or in part.
    </p>
    <h5>
      <strong>MULTI USER ACCESS</strong>
    </h5>
    <p>
      The information provided in this course is private, privileged, and
      confidential, licensed for your sole individual use as a buyer. If a user
      is found to be sharing his/her account with multiple people, we reserve
      the right to suspend the account of the said user.
    </p>
    <h5>
      <strong>COURSE DURATION</strong>
    </h5>
    <p>
      When you buy our course, you get access to our entire course for a
      duration of 6 months, unless we have to disable our course for legal or
      policy reasons.
    </p>
    <h5>
      <strong>ACCOUNT</strong>
    </h5>
    <p>
      You need to create an account on our website course.thelogicaltraders.com
      to access our courses. Keep your password safe as you're solely
      responsible for all activities related to your account. Our platform
      offers users a comment section that lets them ask questions or doubts to
      us. Your rights to post comments can be disabled if you're found to be
      spamming, advertising, being hostile or abusive . We reserve the rights to
      suspend your commenting privileges temporarily or permanently as our sole
      discretion. If you think that your account has been hacked, you can
      contact us on email or WhatsApp.
      <br />
      <br />
      We use WhatsApp business to offer support to our users. So by regis tering
      on our website & creating an account, you are agreeing to receive messages
      from the business on WhatsApp.
    </p>
    <h5>
      <strong>PRICING & PAYMENT</strong>
    </h5>
    <p>
      When you make a payment on our website, you agree to use a valid payment
      method. You also agree to pay the fees for the course that you decide to
      buy. You also authorize us to charge your debit or credit card or process
      other means of payment. We work with RazorPay to offer you convenient
      payment methods and to also keep your payment information secure. When you
      make a purchase on our website, you agree not to use an invalid or
      unauthorized payment method. We reserve the right to disable access to any
      particular course for which we have not received adequate payments.
      <br />
      <br />
      We also run promotions and sales for our courses from time to time. The
      course may be available at a discounted price for a set period of time.
      The price applicable to the course will be the price that you see on the
      screen at the time of checkout. Any price offered for the course may also
      be different when you are logged into your account from the price
      available to users who aren’t registered or logged in, because some of our
      promotions are available to new users only.
      <br />
      <br />
      We reserve the right to change, suspend access to, or remove any or all of
      the content on the website at any time, for any reason that we see fit. We
      also reserves the right to discontinue the website at any time, either
      temporarily or permanently without any prior notice. In the event of the
      removal of any content from the website or the termination of the website,
      you will not be entitled to any refund or payment.
    </p>
    <h5>
      <strong>MONEY-BACK GUARANTEE</strong>
    </h5>
    <p>
      We offer money-back guarantee to people who take the “Profitable Trading
      Masterclass” course under either of the two following conditions:
    </p>
    <p>
      1) If any user can prove that all the advanced secrets and four strategies
      that we’ve discussed in the section 8 of our course are not “new”, meaning
      they were already available on the Internet (or in published books) prior
      to 15th June, then the user will be entitled to get 100% of their
      money-back. The money-back guarantee will not apply if the resources
      shared (YouTube videos, prominent website links, books) to prove the claim
      that our strategies and secrets are not new, do not have a verifiable date
      of publishing or if they were published after 15th June 2022.
    </p>
    <p>
      2) We claim that we’ve made a gross profit of over Rs. 80 lakhs on a
      capital of less than Rs. 2 lakhs in 30 months & we will prove our claims
      by providing the user with the following:
    </p>
    <ul>
      <li>
        We will share the Profit & Loss statement of our Zerodha trading account
        (XL2413) from 1st January 2020 to 31 Dec 2023 of the futures & options
        and commodities segment in the course.{" "}
      </li>
      <li>
        Users will also be able to download our Account Statement Excel file
        from 1st January 2020 to 31 Dec 2023.
      </li>
      <li>We will provide 360-day Zerodha verified Profit & Loss link</li>
    </ul>
    <p>
      If we don’t provide the aforementioned proof, the user will be entitled to
      the money back guarantee.
    </p>
    <p>
      The money-back guarantee will be valid for 3 months after the date of
      purchase even after the expiry of the refund period as long as one of the
      two aforementioned conditions are met.
    </p>
    <p>
      The money-back guarantee will be valid for 3 months after the date of
      purchase even after the expiry of the refund period as long as one of the
      two aforementioned conditions are met.
    </p>
    <Spacer />
  </Container>
);

export default TermsConditions;
