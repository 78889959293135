import React from "react";

export const FB = () => (
  <svg
    className='share-icon-class'
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='24px'
    height='20px'
    viewBox='0 0 24 24'
    enable-background='new 0 0 24 24'
    fill='#fff'
  >
    {" "}
    <path
      fill='%233E68C0'
      d='M5.677,12.998V8.123h3.575V6.224C9.252,2.949,11.712,0,14.736,0h3.94v4.874h-3.94 c-0.432,0-0.934,0.524-0.934,1.308v1.942h4.874v4.874h-4.874V24H9.252V12.998H5.677z'
    ></path>{" "}
  </svg>
);

export const TWITTER = () => (
  <svg
    className='share-icon-class'
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    enable-background='new 0 0 24 24'
    fill='#fff'
  >
    {" "}
    <path d='M21.535,7.063c0.311,6.923-4.852,14.642-13.99,14.642 c-2.78,0-5.368-0.815-7.545-2.213c2.611,0.308,5.217-0.415,7.287-2.038c-2.154-0.039-3.972-1.462-4.599-3.418 c0.771,0.148,1.53,0.105,2.223-0.084c-2.367-0.475-4.002-2.608-3.948-4.888c0.664,0.369,1.423,0.59,2.229,0.615 C1.001,8.215,0.38,5.32,1.67,3.108c2.428,2.978,6.055,4.938,10.145,5.143c-0.717-3.079,1.618-6.044,4.796-6.044 c1.415,0,2.694,0.598,3.592,1.554c1.121-0.221,2.174-0.631,3.126-1.195c-0.368,1.149-1.149,2.114-2.164,2.724 c0.995-0.119,1.944-0.384,2.826-0.776C23.331,5.503,22.497,6.37,21.535,7.063z'></path>{" "}
  </svg>
);

export const WHATSAPP = () => (
  <svg
    version='1.1'
    className='share-icon-class'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    enable-background='new 0 0 24 24'
    fill='#fff'
  >
    {" "}
    <path d='M12.003,0h-0.007l0,0l0,0C5.381,0,0,5.383,0,12c0,2.624,0.846,5.058,2.285,7.034L0.79,23.492l4.612-1.474 C7.299,23.274,9.563,24,12.003,24c6.615,0,11.996-5.383,11.996-12C23.999,5.383,18.618,0,12.003,0z M18.852,16.842 c-0.289,0.818-1.439,1.496-2.356,1.693c-0.628,0.134-1.446,0.24-4.203-0.903c-3.526-1.461-5.797-5.045-5.974-5.277 c-0.169-0.233-1.425-1.898-1.425-3.619s0.875-2.561,1.227-2.92c0.289-0.296,0.769-0.43,1.227-0.43c0.148,0,0.282,0.007,0.402,0.014 c0.352,0.014,0.529,0.035,0.762,0.593C8.8,6.691,9.506,8.413,9.59,8.589c0.085,0.176,0.169,0.416,0.049,0.649 c-0.113,0.24-0.212,0.346-0.388,0.55c-0.176,0.205-0.345,0.36-0.522,0.579c-0.162,0.19-0.346,0.395-0.141,0.748 c0.204,0.346,0.91,1.495,1.946,2.419c1.34,1.192,2.426,1.573,2.814,1.735c0.289,0.12,0.635,0.092,0.846-0.134 c0.268-0.289,0.599-0.769,0.938-1.242c0.24-0.339,0.543-0.381,0.86-0.261c0.324,0.113,2.038,0.959,2.391,1.136 c0.352,0.177,0.585,0.261,0.67,0.409C19.141,15.325,19.141,16.023,18.852,16.842z'></path>{" "}
  </svg>
);

export const TELEGRAM = () => (
  <svg
    className='share-icon-class'
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    enable-background='new 0 0 24 24'
    fill='#fff'
  >
    {" "}
    <path d='M20.562,2.011c2.346-0.215,1.191,2.728,0.876,3.897c-0.312,1.171-1.826,5.546-5.01,14.397 c-0.412,1.147-0.853,2.533-1.545,3.24c-0.229,0.236-0.641,0.577-1.251,0.41c-0.988-0.27-1.48-1.34-1.92-2.173 c-0.592-1.119-1.199-2.335-1.713-3.487c-0.337-0.759-0.622-1.563-1.084-2.216c-0.563-0.793-1.433-1.217-2.381-1.641 c-1.28-0.572-4.863-2.204-5.259-2.46c-0.852-0.554-1.81-1.587-0.918-2.747C0.725,8.754,1.673,8.432,2.736,8.04 C3.801,7.651,15.13,3.625,19.31,2.257C19.621,2.157,20.256,2.003,20.562,2.011L20.562,2.011z'></path>{" "}
  </svg>
);

export const HOME = () => (
  <svg
    className='share-icon-class'
    version='1.1'
    id='Capa_1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='24px'
    height='24px'
    viewBox='0 0 59.465 59.465'
    enable-background='new 0 0 24 24'
    fill='#fff'
  >
    <g>
      <g>
        <path
          d='M58.862,33.886L45.045,20.069v-9.112c0-1.136-0.921-2.056-2.056-2.056c-1.137,0-2.057,0.92-2.057,2.056v5.001L31.185,6.21
			c-0.801-0.803-2.104-0.803-2.905,0L0.603,33.886c-0.803,0.804-0.803,2.104,0,2.907c0.802,0.803,2.104,0.803,2.907,0L29.732,10.57
			l26.223,26.223c0.401,0.398,0.93,0.604,1.455,0.604c0.522,0,1.051-0.201,1.452-0.604C59.665,35.988,59.665,34.689,58.862,33.886z'
        />
        <path
          d='M52.979,36.245L31.185,14.449c-0.385-0.385-0.908-0.602-1.454-0.602c-0.545,0-1.068,0.217-1.453,0.602L6.484,36.245
			c-0.291,0.288-0.487,0.659-0.565,1.062c-0.061,0.314-0.091,0.633-0.091,0.942v10.638c0,2.739,2.229,4.971,4.969,4.971h10.638
			c1.378,0,2.707-0.582,3.645-1.599c0.854-0.923,1.324-2.12,1.324-3.373v-7.812c0-1.896,1.453-3.48,3.33-3.658
			c1.878,0.178,3.331,1.762,3.331,3.658v7.812c0,1.252,0.472,2.45,1.324,3.373c0.938,1.017,2.269,1.599,3.646,1.599h10.638
			c2.74,0,4.971-2.229,4.971-4.972V38.252c0-0.312-0.031-0.63-0.092-0.941C53.471,36.904,53.271,36.533,52.979,36.245z'
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);
