import { Checkbox, Col, Row } from "antd";
import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import Testimonials from "../Testimonials";
import { ColoredContainer, SectionHeadingText } from "./Common";
import { Spacer } from "../../routes/about";
import ActionButton from "./ActionButton";
import { StyledResultText } from "./AboutCourse";

const ChecklistItems = [
  "I am tired of people who sell courses but never show their PnL statement.",
  "I want strategies that make money in bull & bear markets.",
  "I want to make money trading commodities & currencies.",
  "I am sick of people who show fake or selective screenshots of big profits.",
  "I am a complete beginner with no knowledge of the stock market.",
  "I am an intermediate level trader & want Nifty & Bank Nifty strategy.",
  "I have taken other courses but I’m still losing money & want to change that.",
];

export const CheckboxContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  background-image: linear-gradient(to right, #141e30, #243b55);
  padding: 15px 10px;
  border-radius: 5px;
  margin: 10px;
`;

export const ResultText = styled.div`
  font-size: 20px;
  width: ${isMobile ? "100%" : "70%"};
  font-size: 20px;
  margin: 0 auto;
  text-align: center;
  margin-top: ${isMobile ? "20px" : 0};
`;

export const BigText = styled.span`
  font-size: 30px;
  color: #f9ae00;
  font-weight: bold;
`;

const Checklist = () => (
  <WhiteColoredContainer>
    <Container>
      {/* <SectionHeadingText>
        Please Check All Boxes Where Your Answer Is YES!
      </SectionHeadingText>
      <ChecklistContainer>
        <CheckboxRow>
          <CheckboxContainer>
            <StyledCheckbox>{ChecklistItems[0]}</StyledCheckbox>
          </CheckboxContainer>
          <CheckboxContainer>
            <StyledCheckbox>{ChecklistItems[1]}</StyledCheckbox>
          </CheckboxContainer>
        </CheckboxRow>
        <CheckboxRow>
          <CheckboxContainer>
            <StyledCheckbox>{ChecklistItems[2]}</StyledCheckbox>
          </CheckboxContainer>
          <CheckboxContainer>
            <StyledCheckbox>{ChecklistItems[3]}</StyledCheckbox>
          </CheckboxContainer>
        </CheckboxRow>
        <CheckboxRow>
          <CheckboxContainer>
            <StyledCheckbox>{ChecklistItems[4]}</StyledCheckbox>
          </CheckboxContainer>
          <CheckboxContainer>
            <StyledCheckbox>{ChecklistItems[5]}</StyledCheckbox>
          </CheckboxContainer>
        </CheckboxRow>
      </ChecklistContainer> */}
      <StyledResultText>
        {/* If you checked ANY of the boxes above, then you’re invited to join the
        <br />
        <BigText>Profitable Trading Masterclass</BigText>
        <br /> */}
        See What People Are Saying About Our Course
      </StyledResultText>
      <Testimonials hideHeading hideButton />
      {!isMobile && (
        <Row>
          <Col span={2} />
          <Col span={20}>
            <Spacer />
            <Spacer />
            <ActionButton />
          </Col>
          <Col span={2} />
        </Row>
      )}
    </Container>
  </WhiteColoredContainer>
);

export default Checklist;

const WhiteColoredContainer = styled(ColoredContainer)`
  background: #fafafa;
`;

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f9ae00;
    border-color: #f9ae00;
  }
  .ant-checkbox-checked::after {
    border-color: #f9ae00;
  }
  span {
    color: white;
    font-family: GilroyRegular;
    font-size: 16px;
    font-weight: 600;
  }
`;

const ChecklistContainer = styled.div`
  width: ${isMobile ? "100%" : "80%"};
  margin: 0 auto;
`;

const CheckboxRow = styled.div`
  display: flex;
  margin-bottom: ${isMobile ? 0 : "15px"};
  flex-direction: ${isMobile ? "column" : "row"};
`;
