import { Row, Spin } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RiCoupon2Line } from 'react-icons/all';

import { SignUpNavItem } from '../Telegram/TelegramButton';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { axiosAuth } from '../../api';
import { SET_USER_DATA_PATCH } from '../../actions/common';
import CouponModal from '../CouponModal';

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: 'black' }} spin />
);

const ShowCouponButton = styled(SignUpNavItem)`
  background: ${({ theme }) => theme.backgrounds.main};
  border: ${({ isButton, theme }) => isButton && `1px solid ${theme.backgrounds.main}`};
  display: flex;
  color: black !important;
  justify-content: space-evenly;
  align-items: center;
  max-width: 270px;
  height: 42px;
`;

const CouponButton = ({ customStyle, justify }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.common.user);

  const isExpired = moment(profile?.coupon?.expiry).isBefore(moment());
  const handleGenerateCouponClick = () => {
    if (profile?.coupon?.code) {
      setShowModal(true);
    } else {
      setLoading(true);
      axiosAuth
        .post(API_ENDPOINTS.GENERATE_COUPON_CODE)
        .then((res) => {
          const localUserData = localStorage.getItem('user');
          if (res?.data?.data?.code) {
            dispatch({
              type: SET_USER_DATA_PATCH,
              payload: { coupon: res?.data?.data ?? {} },
            });
            localStorage.setItem(
              'user',
              JSON.stringify({
                ...JSON.parse(localUserData ?? '{}'),
                coupon: res?.data?.data,
              }),
            );
          }
          setLoading(false);
          setShowModal(true);
        })
        .catch(() => {
          toast(
            'Failed to generate coupon code. Please try again or contact admin!',
            {
              type: toast.TYPE.ERROR,
            },
          );
          setLoading(false);
        });
    }
  };

  if (isExpired) {
    return <></>;
  }

  return (
    <>
      <CouponModal
        isVisible={showModal}
        handleClose={() => setShowModal(false)}
      />
      <Row justify={justify || 'center'} style={{ ...customStyle }}>
        <ShowCouponButton
          onClick={handleGenerateCouponClick}
          className="pulse-coupon"
          isButton
        >
          {loading ? (
            <Spin indicator={antIcon} />
          ) : (
            <>
              <RiCoupon2Line
                style={{ fontSize: '22px', marginRight: '10px' }}
              />
              {' '}
              <span>Show my Coupon</span>
            </>
          )}
        </ShowCouponButton>
      </Row>
    </>
  );
};

export default CouponButton;
