import { Button } from 'antd';
import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { Form } from 'react-bootstrap';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetCart } from '../../actions/cart';
import { setModalType, setUserData } from '../../actions/common';
import { axiosAuth } from '../../api';
import API_ENDPOINTS from '../../api/apiEndpoints';
import {
  AuthActionsContainer,
  MultiPurposeText,
  LoginFormWrapper,
  LoginContainer,
} from '../../styledComponents';
import HeadingUnderline from '../HeadingUnderline';

const Login = ({ setAuthModalType, setLoggedInUserData, history }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ email: null, password: null });
  const tokenIsExist = useSelector((state) => state.common.authToken);

  useEffect(() => {
    const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
    const { email = '', password = '' } = JSON.parse(localStorage.getItem('loggedInUser')) || {};
    if (rememberMeValue) {
      setEmail(email);
      setPassword(password);
      setRememberMe(rememberMeValue);
    }
  }, []);

  useEffect(() => {
    if (tokenIsExist) {
      history.push('/dashboard');
    }
  }, [tokenIsExist]);

  const validateError = useCallback(
    (name, value) => {
      if (name === 'email' || name === 'password') {
        const modifiedErrors = {
          ...errors,
          [name]: !value,
        };
        setErrors(modifiedErrors);
      }
    },
    [email, password, errors],
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value, checked } = e.target;
      if (name === 'email') {
        setEmail(value);
      } else if (name === 'password') {
        setPassword(value);
      } else {
        setRememberMe(checked);
      }
      validateError(name, value);
    },
    [email, password, rememberMe, errors],
  );

  const handleBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      validateError(name, value);
    },
    [email, password, errors],
  );

  const handleForgotPassClick = useCallback(() => {
    setAuthModalType('fp');
  }, []);

  const isLoginDisabled = useMemo(() => !email || !password, [email, password]);

  const handleLogin = useCallback(async () => {
    if (isLoginDisabled) {
      return;
    }
    setLoading(true);
    localStorage.setItem('rememberMe', rememberMe);
    if (rememberMe) {
      localStorage.setItem('loggedInUser', JSON.stringify({ email, password }));
    } else {
      localStorage.removeItem('loggedInUser');
    }

    axiosAuth
      .post(API_ENDPOINTS.LOGIN, {
        email,
        password,
      })
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.token) {
          if (res?.data?.data?.user?.active) {
            setLoggedInUserData(res.data.data);
            localStorage.setItem('authToken', res.data.data.token);
            localStorage.setItem('user', JSON.stringify(res.data.data.user));
            localStorage.removeItem('cartData');
            localStorage.removeItem('utmSource');
            localStorage.removeItem('utmCampaign');
            localStorage.removeItem('tlt::course::temp::user');
            dispatch(resetCart());
          } else {
            toast(
              'Your account is suspended. Please contact admin for further support',
              { type: toast.TYPE.ERROR },
            );
          }
        } else {
          toast('Email or Password incorrect!!', { type: toast.TYPE.ERROR });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast('Login failed. Please try again or contact admin!!', {
          type: toast.TYPE.ERROR,
        });
      });
  }, [isLoginDisabled, email, password, rememberMe]);

  const handleSignUpClick = useCallback(() => {
    setAuthModalType(null);
    // history.push('/cart?header=false');
    history.push('/enroll');
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleLogin();
      }
    },
    [isLoginDisabled, email, password, handleLogin],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <LoginContainer>
      <HeadingUnderline title="Login" />
      <LoginFormWrapper>
        <Form.Group>
          <Form.Label className="required">Email address</Form.Label>
          <Form.Control
            value={email}
            name="email"
            className="custom-field-focus"
            onChange={handleInputChange}
            onBlur={handleBlur}
            type="email"
            placeholder="Enter email"
            isInvalid={errors.email}
          />
          <Form.Control.Feedback type="invalid">
            Email is required!!
          </Form.Control.Feedback>
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label className="required">Password</Form.Label>
          <Form.Control
            value={password}
            name="password"
            className="custom-field-focus"
            onChange={handleInputChange}
            onBlur={handleBlur}
            type="password"
            placeholder="Enter password"
            isInvalid={errors.password}
          />
          <Form.Control.Feedback type="invalid">
            Password is required!!
          </Form.Control.Feedback>
        </Form.Group>
        <AuthActionsContainer>
          <Form.Group>
            <Form.Check
              name="rememberMe"
              checked={rememberMe}
              onChange={handleInputChange}
              type="checkbox"
              label="Remember me"
            />
          </Form.Group>
          <MultiPurposeText hoverOnParent onClick={handleForgotPassClick}>
            Forgot password?
          </MultiPurposeText>
        </AuthActionsContainer>
        <Button
          disabled={isLoginDisabled}
          onClick={handleLogin}
          loading={loading}
          className="signUpBtn"
          size="large"
          block
        >
          Login
        </Button>
        <MultiPurposeText>
          Don't have an account?
          <span onClick={handleSignUpClick}> Join us now</span>
        </MultiPurposeText>
      </LoginFormWrapper>
    </LoginContainer>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthModalType: (type) => dispatch(setModalType(type)),
  setLoggedInUserData: (userData) => dispatch(setUserData(userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
