import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useCountdown } from './useCountdown';
import './dashboard.scss';

export const getExpiryDate = (date) => {
  if (!date) {
    return null;
  }
  const createdAt = new Date(date);
  let finalDate = new Date(createdAt.setDate(createdAt.getDate() + 10));
  finalDate = new Date(
    finalDate.getFullYear(),
    finalDate.getMonth(),
    finalDate.getDate(),
    23,
    59,
    59,
  );
  return finalDate;
};

const CourseCounter = () => {
  const userState = useSelector((state) => state.common.user);
  const offerExpiryDate = userState?.createdAt
    ? getExpiryDate(userState?.createdAt)
    : null;
  const data = useCountdown(offerExpiryDate);

  useEffect(() => {
    if (data?.length && data[4] < 1200) {
      window.location.reload();
    }
  }, [data]);

  if (!offerExpiryDate) {
    return null;
  }

  if (!data) {
    return null;
  }
  return (
    <div style={{ marginBottom: '50px' }}>
      <Parent>
        <OfferText>Limited time offer</OfferText>
        <div style={{ marginBottom: '10px' }}>
          Get Trading Psychology Masterclass worth &#8377;2500 for just
          &#8377;499
        </div>
        <TimerBox>
          <TimeWrapper>
            <TimeBox>{data[0]}</TimeBox>
            <DayContent>Days</DayContent>
          </TimeWrapper>
          <TimeWrapper>
            <TimeBox>{data[1]}</TimeBox>
            <DayContent>Hours</DayContent>
          </TimeWrapper>
          <TimeWrapper>
            <TimeBox>{data[2]}</TimeBox>
            <DayContent>Mins</DayContent>
          </TimeWrapper>
          <TimeWrapper>
            <TimeBox>{data[3]}</TimeBox>
            <DayContent>Secs</DayContent>
          </TimeWrapper>
        </TimerBox>
      </Parent>
    </div>
  );
};

export default CourseCounter;

const OfferText = styled.div`
  font-size: 18px;
  margin-bottom: 5px;
  color: #f9ae00;
  font-weight: bold;
`;

const TimeBox = styled.div`
  padding: 10px;
  color: #f9ae00;
  min-width: 50px;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 10px;
  border-radius: 5px; ;
`;

const DayContent = styled.span`
  color: white;
`;

const TimerBox = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Parent = styled.div`
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border: ${({ theme }) => `5px solid ${theme.borders.light}`};
  width: 400px;
  margin: 0 auto 35px auto;
  font-weight: bold;
  height: 200px;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    width: 350px;
  }
`;
