import { Button, Input, Row } from 'antd';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
import { axiosAuth } from '../../api';
import API_ENDPOINTS from '../../api/apiEndpoints';
import { Spacer } from '../about';

const WebinarRecording = () => {
  const [data, setData] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      const recordingData = await axiosAuth.get(
        `${API_ENDPOINTS.GET_RECORDING}?username=${username}&password=${password}`,
      );
      if (recordingData?.data?.blocked) {
        setData(null);
        toast('Recording not available', {
          type: toast.TYPE.ERROR,
        });
      } else if (recordingData?.data?.link) {
        setData(recordingData?.data);
      } else {
        setData(null);
        toast(recordingData?.data?.message, {
          type: toast.TYPE.ERROR,
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast('Something went wrong. Please contact admin for further support', {
        type: toast.TYPE.ERROR,
      });
    }
  };

  return (
    <Container>
      <Spacer />
      {data?.link ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ReactPlayer
            url={data.link}
            controls
            pip
            loop
            playsinline
          />
        </div>
      ) : (
        <div>
          <h4>
            Please enter the username and password to watch webinar recording
          </h4>
          <Row>
            <p style={{ marginBottom: '5px' }}>
              <strong>Username*</strong>
            </p>
          </Row>
          <Row>
            <Input
              value={username}
              style={{ width: '200px' }}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
            />
          </Row>
          <Spacer />
          <Row>
            <p style={{ marginBottom: '5px' }}>
              <strong>Password*</strong>
            </p>
          </Row>
          <Row>
            <Input
              value={password}
              style={{ width: '200px' }}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              type="password"
            />
          </Row>
          <Spacer />
          <Row>
            <Button
              loading={loading}
              disabled={loading || !username || !password}
              type="primary"
              onClick={handleClick}
            >
              Show recording
            </Button>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default WebinarRecording;
