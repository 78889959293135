import { Col, Row } from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { Spacer } from '../../routes/about';
import Counter from '../Counter';
import { StyledResultText } from './AboutCourse';
import ActionButton from './ActionButton';
import { ColoredContainer } from './Common';

const BetterThanOther = () => (
  <ColoredContainer>
    <WhiteStyledResultText>
      Here’s Why Profitable Trading Masterclass Is Better Than Other Courses & Seminars
    </WhiteStyledResultText>
    <Counter hideButton showWhiteBox />
    {!isMobile && (
      <Row>
        <Col span={4} />
        <Col span={16}>
          <Spacer />
          <Spacer />
          <ActionButton />
        </Col>
        <Col span={4} />
      </Row>
    )}
  </ColoredContainer>
);

export default BetterThanOther;

const WhiteStyledResultText = styled(StyledResultText)`
    color: white;
`;
