import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import HeadingUnderline from '../../components/HeadingUnderline';

const Spacer = styled.div`
  height: 20px;
`;

const Disclaimer = () => (
  <>
    <Helmet>
      <title>Disclaimer</title>
      <meta
        name="description"
        content="The Logical Traders tips, The Logical Traders calls, The Logical Traders free tips, The Logical Traders predictions, The Logical Traders market calls, Do The Logical Traders give market tips, The Logical Traders stock recommendation, The Logical Traders stock picks, The Logical Traders recommendations,"
      />
    </Helmet>
    <Container>
      <Spacer />
      <HeadingUnderline title="Disclaimer" />
      <p className="text-center">
        While the content of this course is intended to aid in the selection of sound financial investments,
        it should be noted that it is possible to lose money in the stock market.
        The Logical Traders is not a SEBI registered investment advisory and
        neither are it's founding members. No one at The Logical Traders is
        a SEBI registered research analyst. In addition, whatever content is published
        on our newsletter, YouTube channel or Free Telegram channel
        should not be constructed as investment advice as it
        is provided for the sole purpose of education. Information
        provided within the course
        is the opinion of the course creators and is for educational purposes only.
        The Logical Traders make no claims, promises, or guarantees
        about the accuracy, completeness, or adequacy of the contents of this
        course and expressly disclaims liability for errors & omissions in the
        contents of this course.
      </p>
      <Spacer />
    </Container>
  </>
);

export default Disclaimer;
