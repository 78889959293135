export const RESET_CART = 'RESET_CART';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const SET_TO_CART = 'SET_TO_CART';
export const SET_COUPON_CODE = 'SET_COUPON_CODE';

export const resetCart = () => ({
  type: RESET_CART,
});

export const addToCart = (courseData) => ({
  payload: courseData,
  type: ADD_TO_CART,
});

export const removeFromCart = (index) => ({
  payload: index,
  type: REMOVE_FROM_CART,
});

export const setToCart = (courseData) => ({
  payload: courseData,
  type: SET_TO_CART,
});

export const setCouponCode = (couponData) => ({
  payload: couponData,
  type: SET_COUPON_CODE,
});
