import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from 'styled-components';
import BuyCourseButton from "../BuyCourseButton";

const FixedBuyButton = () => {
  const [scrollPosition, setScrollPosition] = useState(null);

  const listenToScroll = useCallback(() => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    setScrollPosition(scrolled);
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  const showWButton = useMemo(() => {
    return scrollPosition > 0.07;
  }, [scrollPosition]);

  if (showWButton) {
    return (
      <FixedBuyButtonContainer>
        <BuyCourseButton flatButton customClass="fixed-button" />
      </FixedBuyButtonContainer>
    );
  }

  return <></>;
};

export default FixedBuyButton;

const FixedBuyButtonContainer = styled.div`
  display: flex;
  position: fixed;
  z-index: 9;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  bottom: 25px;
`;
