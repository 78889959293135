import { Container } from 'react-bootstrap';
import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import HeadingUnderline from '../HeadingUnderline';
import hub from '../../assets/hubapp.jpg';
import calc from '../../assets/tradeCalc.png';
import newsletter from '../../assets/newsletter.jpeg';
import { Spacer } from '../../routes/about';
import ActionButton from '../LandingPage/ActionButton';
import { SectionHeadingText } from '../LandingPage/Common';

const WhatYouGet = () => {
  if (!isMobileOnly) {
    return (
      <Container>
        <Row justify="center">
          <SectionHeadingText>
            <span style={{ color: 'white' }}>What You Get</span>
          </SectionHeadingText>
        </Row>
        <Row>
          <Col sm={0} md={0} lg={2} />
          <Col sm={12} md={12} lg={10}>
            <ContentDiv>
              <Image35 src={hub} alt="platform" />
            </ContentDiv>
          </Col>
          <Col sm={12} md={12} lg={10}>
            <TextContent>
              <BlogsHeading>The Logical Traders Hub</BlogsHeading>
              <BlogsHeadingUnderline />
              <Text>
                Get access to The Logical Traders Hub where you will be getting
                <br />
                <BoldText>1) Our trades watchlist:</BoldText>
                {' '}
                Get the trades
                that we&apos;re eyeing in all segments; cash, futures & options,
                currencies & commodities.
                <br />
                <BoldText>2) Educational content:</BoldText>
                {' '}
                We post about
                macroeconomics, geopolitics, technical & fundamental analysis.
                <br />
                <BoldText>3) Our Newsletter:</BoldText>
                {' '}
                A highly detailed
                analysis of the things impacting the Indian & global financial
                markets
              </Text>
            </TextContent>
          </Col>
          <Col sm={0} md={0} lg={2} />
        </Row>
        <SectionSpacer />
        <Row>
          <Col sm={0} md={0} lg={2} />
          <Col sm={12} md={12} lg={10}>
            <TextContent>
              <BlogsHeading>Profitable Trading Masterclass Course</BlogsHeading>
              <BlogsHeadingUnderline />
              <Text>
                Our trading course that consists of
                {' '}
                <BoldText>
                  35 of our key trading secrets and 4 advanced trading
                  strategies
                </BoldText>
                . You&apos;re guaranteed to find new content and concepts in
                this course that you won&apos;t find anywhere else. And the best
                part is that our course is cheaper than other courses that you
                will find online.
              </Text>
            </TextContent>
          </Col>
          <Col sm={12} md={12} lg={10}>
            <ContentDiv>
              <Image85
                src="https://elearning.thelogicaltraders.com/wp-content/uploads/2020/10/Thumbnail-Revised-scaled.jpg"
                alt="course"
              />
            </ContentDiv>
          </Col>
          <Col sm={0} md={0} lg={2} />
        </Row>
        <SectionSpacer />
        <Row>
          <Col sm={0} md={0} lg={2} />
          <Col sm={12} md={12} lg={10}>
            <ContentDiv>
              <Image75 src={newsletter} alt="newsletter" />
            </ContentDiv>
          </Col>
          <Col sm={12} md={12} lg={10}>
            <TextContent>
              <BlogsHeading>The Great Awakening Newsletter</BlogsHeading>
              <BlogsHeadingUnderline />
              <Text>
                Get our in-depth coverage of
                {' '}
                <BoldText>unique analysis</BoldText>
                {' '}
                of things ranging from
                geopolitics to macroeconomics to technical analysis. We&apos;ll
                be covering events that impact the Indian & global financial
                markets and giving our insights on it.
              </Text>
            </TextContent>
          </Col>
          <Col sm={0} md={0} lg={2} />
        </Row>
        <SectionSpacer />
        <Row>
          <Col sm={0} md={0} lg={2} />
          <Col sm={12} md={12} lg={10}>
            <TextContent>
              <BlogsHeading>Trade Allocation Calculator</BlogsHeading>
              <BlogsHeadingUnderline />
              <Text>
                Use the trade allocation calculator in our mobile app to
                {' '}
                <BoldText>manage risk</BoldText>
                {' '}
                and determine how much quantity
                you need to
                {' '}
                <BoldText> buy or sell </BoldText>
                depending on the value of your portfolio.
              </Text>
            </TextContent>
          </Col>
          <Col sm={12} md={12} lg={10}>
            <ContentDiv>
              <Image85 src={calc} alt="course" />
            </ContentDiv>
          </Col>
          <Col sm={0} md={0} lg={2} />
        </Row>
        <SectionSpacer />
        <Row>
          <Col sm={0} md={0} lg={2} />
          <Col sm={12} md={12} lg={10}>
            <ContentDiv>
              <Image75
                src="https://elearning.thelogicaltraders.com/wp-content/uploads/2021/11/WhatsApp-Image-2022-08-23-at-9.19.45-PM.jpeg"
                alt="platform"
              />
            </ContentDiv>
          </Col>
          <Col sm={12} md={12} lg={10}>
            <TextContent>
              <BlogsHeading>Trading Psychology Masterclass Course</BlogsHeading>
              <BlogsHeadingUnderline />
              <Text>
                More Secrets. More Indicators. More Strategies. More Learning.
                More Profits. You get access to Trading Psychology Masterclass
                with some unique psychology tips to take your trading to the
                next level. That’s right,&nbsp;
                <BoldText>
                  you get two courses for less than the price of one.
                </BoldText>
              </Text>
            </TextContent>
          </Col>
          <Col sm={0} md={0} lg={2} />
        </Row>
        <Row>
          <Col span={2} />
          <Col span={20}>
            <Spacer />
            <Spacer />
            <ActionButton />
          </Col>
          <Col span={2} />
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <HeadingUnderline title="What You Get" whitetext />
      <Row>
        <Col span={24}>
          <MobileCentered>
            <BlogsHeading>The Logical Traders Hub</BlogsHeading>
            <BlogsHeadingUnderline />
          </MobileCentered>
        </Col>
        <Col span={24}>
          <ContentDiv>
            <Image35 src={hub} alt="platform" />
          </ContentDiv>
        </Col>
        <Col span={24}>
          <TextContent>
            <Text>
              Get access to The Logical Traders Hub where you will be getting
              <br />
              <BoldText>1) Our trades watchlist:</BoldText>
              {' '}
              Get the trades that
              we&apos;re eyeing in all segments; cash, futures & options,
              currencies & commodities.
              <br />
              <BoldText>2) Educational content:</BoldText>
              {' '}
              We post about
              macroeconomics, geopolitics, technical & fundamental analysis.
              <br />
              <BoldText>3) Our Newsletter:</BoldText>
              {' '}
              A highly detailed analysis
              of the things impacting the Indian & global financial markets
            </Text>
          </TextContent>
        </Col>
      </Row>
      <SectionSpacer />
      <Row>
        <Col span={24}>
          <MobileCentered>
            <BlogsHeading>Profitable Trading Masterclass Course</BlogsHeading>
            <BlogsHeadingUnderline />
          </MobileCentered>
        </Col>
        <Col span={24}>
          <ContentDiv>
            <Image85
              src="https://elearning.thelogicaltraders.com/wp-content/uploads/2020/10/Thumbnail-Revised-scaled.jpg"
              alt="course"
            />
          </ContentDiv>
        </Col>
        <Col span={24}>
          <TextContent>
            <Text>
              Our trading course that consists of
              {' '}
              <BoldText>
                35 of our key trading secrets and 4 advanced trading strategies
              </BoldText>
              . You&apos;re guaranteed to find new content and concepts in this
              course that you won&apos;t find anywhere else. And the best part
              is that our course is cheaper than other courses that you will
              find online.
            </Text>
          </TextContent>
        </Col>
      </Row>
      <SectionSpacer />
      <Row>
        <Col span={24}>
          <MobileCentered>
            <BlogsHeading>The Great Awakening Newsletter</BlogsHeading>
            <BlogsHeadingUnderline />
          </MobileCentered>
        </Col>
        <Col span={24}>
          <ContentDiv>
            <Image75 src={newsletter} alt="newsletter" />
          </ContentDiv>
        </Col>
        <Col span={24}>
          <Text>
            Get our in-depth coverage of
            {' '}
            <BoldText>unique analysis</BoldText>
            {' '}
            of
            things ranging from geopolitics to macroeconomics to technical
            analysis. We&apos;ll be covering events that impact the Indian &
            global financial markets and giving our insights on it.
          </Text>
        </Col>
        <Col sm={0} md={0} lg={2} />
      </Row>
      <SectionSpacer />
      <Row>
        <Col span={24}>
          <MobileCentered>
            <BlogsHeading>Trade Allocation Calculator</BlogsHeading>
            <BlogsHeadingUnderline />
          </MobileCentered>
        </Col>
        <Col span={24}>
          <ContentDiv>
            <Image75 src={calc} alt="platform" />
          </ContentDiv>
        </Col>
        <Col span={24}>
          <TextContent>
            <Text>
              Use the trade allocation calculator in our mobile app to
              {' '}
              <BoldText>manage risk</BoldText>
              {' '}
              and determine how much quantity
              you need to
              {' '}
              <BoldText> buy or sell </BoldText>
              depending on the value of your portfolio.
            </Text>
          </TextContent>
        </Col>
        <Col sm={0} md={0} lg={2} />
      </Row>
      <SectionSpacer />
      <Row>
        <Col span={24}>
          <MobileCentered>
            <BlogsHeading>Trading Psychology Masterclass Course</BlogsHeading>
            <BlogsHeadingUnderline />
          </MobileCentered>
        </Col>
        <Col span={24}>
          <ContentDiv>
            <Image75
              src="https://elearning.thelogicaltraders.com/wp-content/uploads/2021/11/WhatsApp-Image-2022-08-23-at-9.19.45-PM.jpeg"
              alt="platform"
            />
          </ContentDiv>
        </Col>
        <Col span={24}>
          <TextContent>
            <Text>
              More Secrets. More Indicators. More Strategies. More Learning.
              More Profits. You get access to Trading Psychology Masterclass
              with some unique psychology tips to take your trading to the next
              level. That’s right,&nbsp;
              <BoldText>
                you get two courses for less than the price of one.
              </BoldText>
            </Text>
          </TextContent>
        </Col>
        <Col sm={0} md={0} lg={2} />
      </Row>
    </Container>
  );
};

export default WhatYouGet;

const ContentDiv = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TextContent = styled(ContentDiv)`
  padding: 0 20px;
`;

const Text = styled.div`
  color: white;
  text-align: center;
  padding: 0 10px;
`;

const MobileCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
`;

const BlogsHeading = styled.div`
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  margin-bottom: 5px;
  text-align: center;
  color: white;
  width: 100%;
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

const BlogsHeadingUnderline = styled.div`
  height: 5px;
  width: 80px;
  border-radius: 2.5px;
  background: #f9ae00;
  margin-bottom: 10px;
`;

const BoldText = styled.span`
  color: #f9ae00;
  font-weight: bold;
`;

const ImageDiv = styled.img`
  width: 100%;
  border-radius: 8px;
  max-height: 200px;
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const Image75 = styled(ImageDiv)`
  width: 75%;
  @media (max-width: 767px) {
    max-height: inherit;
  }
`;

const Image35 = styled(ImageDiv)`
  width: 35%;
  max-height: 310px;
  @media (max-width: 767px) {
    width: 55%;
    max-height: inherit;
  }
`;

const Image85 = styled(ImageDiv)`
  width: 85%;
`;

const Image60 = styled(ImageDiv)`
  width: 60%;
`;

const SectionSpacer = styled.div`
  height: 50px;
`;
