import {
  SET_MODAL_TYPE,
  SET_USER_DATA,
  LOGOUT,
  SET_USER_DATA_PATCH,
  TOGGLE_REFUND_CONFIRMATION_MODAL,
  TOGGLE_STAR_RATING_MODAL,
} from '../actions/common';

const initialState = {
  authModalType: null,
  user: {},
  authToken: '',
  showRefundOverConfirmationModal: false,
  showRatingModal: false,
  counter: 0,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MODAL_TYPE:
      return {
        ...state,
        authModalType: action.payload || null,
      };
    case SET_USER_DATA:
      return {
        ...state,
        user: action.payload.user || {},
        authToken: action.payload.token || state.authToken || '',
      };
    case TOGGLE_REFUND_CONFIRMATION_MODAL:
      return {
        ...state,
        showRefundOverConfirmationModal: action.payload,
      };
    case TOGGLE_STAR_RATING_MODAL:
      return {
        ...state,
        showRatingModal: action.payload,
      };
    case SET_USER_DATA_PATCH:
      return {
        ...state,
        user: { ...state.user, ...(action.payload || {}) },
      };
    case LOGOUT:
      return {
        ...state,
        user: {},
        authToken: '',
      };
    default:
      return state;
  }
}

export default reducer;
