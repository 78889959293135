export const AUTH_RESET_CART = "AUTH_RESET_CART";
export const AUTH_ADD_TO_CART = "AUTH_ADD_TO_CART";
export const AUTH_REMOVE_FROM_CART = "AUTH_REMOVE_FROM_CART";
export const AUTH_SET_TO_CART = "AUTH_SET_TO_CART";

export const authResetCart = () => {
  return {
    type: AUTH_RESET_CART,
  };
};

export const authAddToCart = (courseData) => {
  return {
    payload: courseData,
    type: AUTH_ADD_TO_CART,
  };
};

export const authRemoveFromCart = (index) => {
  return {
    payload: index,
    type: AUTH_REMOVE_FROM_CART,
  };
};

export const authSetToCart = (courseData) => {
  return {
    payload: courseData,
    type: AUTH_SET_TO_CART,
  };
};
