import React, { useCallback, useMemo, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setModalType } from '../../actions/common';
import { axiosAuth } from '../../api';
import API_ENDPOINTS from '../../api/apiEndpoints';
import HeadingUnderline from '../HeadingUnderline';

const ForgotPassword = ({ setAuthModalType }) => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: null, password: null });
  const [loading, setLoading] = useState(false);
  const [isSuceess, setIsSuccess] = useState(false);

  const validateError = useCallback(
    (name, value) => {
      if (name === 'email' || name === 'password') {
        const modifiedErrors = {
          ...errors,
          [name]: !value,
        };
        setErrors(modifiedErrors);
      }
    },
    [email, errors],
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value, checked } = e.target;
      if (name === 'email') {
        setEmail(value);
      }
      validateError(name, value);
    },
    [email, errors],
  );

  const handleBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      validateError(name, value);
    },
    [email, errors],
  );

  const isSubmitDisabled = useMemo(() => !email, [email]);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    axiosAuth
      .post(API_ENDPOINTS.FORGOT_PASSWORD, { email })
      .then((res) => {
        if (res.status == 200 && res.data && res.data.code == 200) {
          toast('Reset link sent.', {
            type: toast.TYPE.SUCCESS,
          });
          setEmail('');
          setIsSuccess(true);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast('Failed to send email. Please try again or contact admin!', {
          type: toast.TYPE.ERROR,
        });
      });
  }, [email]);

  return (
    <Modal.Body>
      {isSuceess ? (
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
        }}
        >
          <HeadingUnderline title="Reset link sent" />
          <h5>
            Please also check the
            {' '}
            <strong>"Promotions"</strong>
            {' '}
            tab of your Gmail account as the email may end up there.
            <br />
            You can drag and drop the email to the
            {' '}
            <strong>"Primary"</strong>
            {' '}
            tab and then click on "Yes" to ensure all the future
            emails from us land in the main tab.
          </h5>
          <Button type="primary" onClick={() => setAuthModalType(null)}>Ok</Button>
        </div>
      )
        : (
          <>
            <Form.Group>
              <Form.Label className="required">Email address</Form.Label>
              <Form.Control
                value={email}
                name="email"
                className="custom-field-focus"
                onChange={handleInputChange}
                onBlur={handleBlur}
                type="email"
                placeholder="Enter email"
                isInvalid={errors.email}
              />
              <Form.Control.Feedback type="invalid">
                Email is required!!
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Button
              className="signUpBtn"
              disabled={isSubmitDisabled}
              onClick={handleSubmit}
              loading={loading}
            >
              Submit
            </Button>
          </>
        )}
    </Modal.Body>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthModalType: (type) => dispatch(setModalType(type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ForgotPassword));
