import React, { memo } from 'react';
import './notification.scss';
import styled from 'styled-components';
import { getDateTextFromDate } from '../../utils/helper';

const getBody = (data) => {
  if (data?.data?.type === 'reply') {
    return `Course: ${data?.data?.course} | Section: ${data?.data?.section} | Lesson: ${data?.data?.lesson}`;
  }
  return data?.body;
};
const NotificationBox = ({ data }) => (
  <div
    aria-hidden
    className={`${!data?.read ? 'unread-notification' : ''} notification-box`}
  >
    <NotificationContainer>
      <ContentContainer>
        <div>
          <NotificationContentDiv>
            <strong>{data?.title ?? ''}</strong>
          </NotificationContentDiv>
        </div>
        <div className="mt-10">
          <NotificationContentDiv>{getBody(data)}</NotificationContentDiv>
        </div>
      </ContentContainer>
      <TimeContainer>{getDateTextFromDate(data?.createdAt)}</TimeContainer>
    </NotificationContainer>

  </div>
);

export default memo(NotificationBox);

const NotificationContainer = styled.div`
  display: flex;
  flex: 1;
`;

const ContentContainer = styled.div`
  flex: 8;
`;

const TimeContainer = styled.div`
  flex: 2;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  padding-top: 5px;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const NotificationContentDiv = styled.div`
  margin: 0 16px;
  display: flex;
  align-items: center;
`;
