import { Container } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { isMobileOnly, isMobile, isTablet } from 'react-device-detect';
import {
  CommentOutlined,
  FieldTimeOutlined,
  OrderedListOutlined,
  FormOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { theme } from '../../utils/theme';
import './index.scss';
import { ColoredContainer } from './Common';
import ActionButton from './ActionButton';
import { Spacer } from '../../routes/about';
import { useCountdown } from '../../routes/dashboard/useCountdown';
import { axiosAuth } from '../../api';
import API_ENDPOINTS from '../../api/apiEndpoints';

export const getNextWebinarDate = (customDate = '') => {
  const currentDay = moment().day();
  const currentHour = moment().hours();
  return {
    formatDate: moment()
      .add(
        currentDay === 0 && currentHour > 16
          ? 7
          : currentDay === 0
            ? 0
            : 7 - currentDay,
        'days',
      )
      .format('DD MMM YYYY'),
    slotDate: moment().add(
      currentDay === 0 && currentHour > 16
        ? 7
        : currentDay === 0
          ? 0
          : 7 - currentDay,
      'days',
    ),
    hardcoded: customDate,
  };
};

export const getExpiryDate = (date) => {
  if (!date) {
    return null;
  }
  const createdAt = new Date(date);
  let finalDate = new Date(createdAt.setDate(createdAt.getDate() + 10));
  finalDate = new Date(
    finalDate.getFullYear(),
    finalDate.getMonth(),
    finalDate.getDate(),
    23,
    59,
    59,
  );
  return finalDate;
};

const BannerSection = () => {
  const [webinarConfig, setWebinarConfig] = useState(null);
  const end = new Date();
  end.setHours(23, 59, 59, 999);
  const data = useCountdown(end);

  const getWebinarConfig = async () => {
    const webinarConfigData = await axiosAuth.get(API_ENDPOINTS.WEBINAR_CONFIG);
    if (webinarConfigData?.data?.customWebinarDate) {
      setWebinarConfig(webinarConfigData?.data);
    }
  };

  useEffect(() => {
    getWebinarConfig();
  }, []);

  useEffect(() => {
    if (data?.length && data[4] < 1200) {
      window.location.reload();
    }
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <ColoredContainer>
      <Container>
        <TimerContainer>
          <p style={{ fontSize: '20px', marginBottom: 0, fontWeight: 'bold' }}>
            OFFER ENDS IN
            {' '}
            {data[1] < 10 ? `0${data[1]}` : data[1]}
            :
            {data[2] < 10 ? `0${data[2]}` : data[2]}
            :
            {data[3] < 10 ? `0${data[3]}` : data[3]}
          </p>
        </TimerContainer>
        <MainContainer>
          <HeadingContainer>
            <BoldHeadingBlackText>
              Know How I Turned
              {' '}
              <BoldHeadingYellowText>
                &lt;&#8377;2 Lakhs to &#8377;45.87 Lakhs
              </BoldHeadingYellowText>
              {' '}
              in 3 Years
            </BoldHeadingBlackText>
            <SubHeadingWhiteText>
              I Will Show My
              {' '}
              <SubHeadingYellowText>
                3 Year Profit & Loss Statement&nbsp;
              </SubHeadingYellowText>
              Live During the Webinar &&nbsp;
              <SubHeadingYellowText>
                Share the Tricks&nbsp;
              </SubHeadingYellowText>
              I Used to Generate Massive Profits
            </SubHeadingWhiteText>
          </HeadingContainer>
          {isMobile ? (
            <>
              <div
                className={`react-video-player-custom item-center ${
                  isTablet ? 'video-margin' : ''
                }`}
              >
                <ReactPlayer
                  url="https://vimeo.com/787033852"
                  controls
                  width={isMobileOnly ? '350px' : '480px'}
                  height={isMobileOnly ? '197px' : '270px'}
                  pip
                  loop
                  playsinline
                />
              </div>
              <Spacer />
              <InfoBox>
                <BlackInfoAnalyticsContainer>
                  <Flex>
                    <InfoAnalyticsBoxContainer>
                      <TransparentInfoAnalyticsBox>
                        <IconTextContainer>
                          <FormOutlined
                            style={{
                              color: theme.backgrounds.main,
                              fontSize: '30px',
                            }}
                          />
                          <TextContainer>
                            <Text>1 Bank Nifty Strategy</Text>
                          </TextContainer>
                        </IconTextContainer>
                      </TransparentInfoAnalyticsBox>
                    </InfoAnalyticsBoxContainer>
                    <InfoAnalyticsBoxContainer>
                      <TransparentInfoAnalyticsBox>
                        <IconTextContainer>
                          <OrderedListOutlined
                            style={{
                              color: theme.backgrounds.main,
                              fontSize: '30px',
                            }}
                          />
                          <TextContainer>
                            <Text>3 Custom Indicators</Text>
                          </TextContainer>
                        </IconTextContainer>
                      </TransparentInfoAnalyticsBox>
                    </InfoAnalyticsBoxContainer>
                  </Flex>
                  <Flex>
                    <InfoAnalyticsBoxContainer>
                      <TransparentInfoAnalyticsBox>
                        <IconTextContainer>
                          <FieldTimeOutlined
                            style={{
                              color: theme.backgrounds.main,
                              fontSize: '30px',
                            }}
                          />
                          <TextContainer>
                            <Text>90 Min English Webinar</Text>
                          </TextContainer>
                        </IconTextContainer>
                      </TransparentInfoAnalyticsBox>
                    </InfoAnalyticsBoxContainer>
                    <InfoAnalyticsBoxContainer>
                      <TransparentInfoAnalyticsBox>
                        <IconTextContainer>
                          <CommentOutlined
                            style={{
                              color: theme.backgrounds.main,
                              fontSize: '30px',
                            }}
                          />
                          <TextContainer>
                            <Text>Q&A Session</Text>
                          </TextContainer>
                        </IconTextContainer>
                      </TransparentInfoAnalyticsBox>
                    </InfoAnalyticsBoxContainer>
                  </Flex>
                </BlackInfoAnalyticsContainer>
                <InfoAnalyticsContainer>
                  <InfoAnalyticsBoxContainer>
                    <InfoAnalyticsBox>
                      <IconTextContainerOne>
                        <FormOutlined
                          style={{
                            color: theme.backgrounds.main,
                            fontSize: '30px',
                          }}
                        />
                        <TextContainer>
                          <Text>
                            {webinarConfig?.customWebinarDate
                              ? getNextWebinarDate(
                                webinarConfig?.customWebinarDate,
                              ).hardcoded
                              : `7 PM, Sunday, ${
                                getNextWebinarDate().formatDate
                              }`}
                          </Text>
                        </TextContainer>
                      </IconTextContainerOne>
                    </InfoAnalyticsBox>
                  </InfoAnalyticsBoxContainer>
                </InfoAnalyticsContainer>
              </InfoBox>
            </>
          ) : (
            <>
              <InfoVideoContainer>
                <InfoBox>
                  <BlackInfoAnalyticsContainer>
                    <Flex>
                      <InfoAnalyticsBoxContainer>
                        <TransparentInfoAnalyticsBox>
                          <IconTextContainer>
                            <FormOutlined
                              style={{
                                color: theme.backgrounds.main,
                                fontSize: '30px',
                              }}
                            />
                            <TextContainer>
                              <Text>1 Bank Nifty Strategy</Text>
                            </TextContainer>
                          </IconTextContainer>
                        </TransparentInfoAnalyticsBox>
                      </InfoAnalyticsBoxContainer>
                      <InfoAnalyticsBoxContainer>
                        <TransparentInfoAnalyticsBox>
                          <IconTextContainer>
                            <OrderedListOutlined
                              style={{
                                color: theme.backgrounds.main,
                                fontSize: '30px',
                              }}
                            />
                            <TextContainer>
                              <Text>3 Custom Indicators</Text>
                            </TextContainer>
                          </IconTextContainer>
                        </TransparentInfoAnalyticsBox>
                      </InfoAnalyticsBoxContainer>
                    </Flex>
                    <Flex>
                      <InfoAnalyticsBoxContainer>
                        <TransparentInfoAnalyticsBox>
                          <IconTextContainer>
                            <FieldTimeOutlined
                              style={{
                                color: theme.backgrounds.main,
                                fontSize: '30px',
                              }}
                            />
                            <TextContainer>
                              <Text>90 Min English Webinar</Text>
                            </TextContainer>
                          </IconTextContainer>
                        </TransparentInfoAnalyticsBox>
                      </InfoAnalyticsBoxContainer>
                      <InfoAnalyticsBoxContainer>
                        <TransparentInfoAnalyticsBox>
                          <IconTextContainer>
                            <CommentOutlined
                              style={{
                                color: theme.backgrounds.main,
                                fontSize: '30px',
                              }}
                            />
                            <TextContainer>
                              <Text>Q&A Session</Text>
                            </TextContainer>
                          </IconTextContainer>
                        </TransparentInfoAnalyticsBox>
                      </InfoAnalyticsBoxContainer>
                    </Flex>
                  </BlackInfoAnalyticsContainer>

                  <InfoAnalyticsContainer>
                    <InfoAnalyticsBoxContainer>
                      <InfoAnalyticsBox>
                        <IconTextContainerOne>
                          <CalendarOutlined
                            style={{
                              color: theme.backgrounds.main,
                              fontSize: '30px',
                            }}
                          />
                          <TextContainer>
                            <Text>
                              {webinarConfig?.customWebinarDate
                                ? getNextWebinarDate(
                                  webinarConfig?.customWebinarDate,
                                ).hardcoded
                                : `7 PM, Sunday, ${
                                  getNextWebinarDate().formatDate
                                }`}
                            </Text>
                          </TextContainer>
                        </IconTextContainerOne>
                      </InfoAnalyticsBox>
                    </InfoAnalyticsBoxContainer>
                  </InfoAnalyticsContainer>
                </InfoBox>
                <VideoBox>
                  <div className="react-video-player-custom">
                    <ReactPlayer
                      url="https://vimeo.com/787033852"
                      controls
                      width={isMobileOnly ? '350px' : '480px'}
                      height={isMobileOnly ? '197px' : '270px'}
                      pip
                      loop
                      playsinline
                    />
                  </div>
                </VideoBox>
              </InfoVideoContainer>
              <ActionButtonContainer>
                <ActionButton />
              </ActionButtonContainer>
              <EmptyDiv />
            </>
          )}
        </MainContainer>
      </Container>
    </ColoredContainer>
  );
};

export default BannerSection;

const BoldHeadingBlackText = styled.span`
  color: white;
  font-size: ${isMobile ? '25px' : '35px'};
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const BoldHeadingYellowText = styled(BoldHeadingBlackText)`
  color: ${theme.backgrounds.main};
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${isMobile ? '30px' : '40px'}; ;
`;

const HeadingContainer = styled.div`
  margin-top: ${isMobile ? 0 : '30px'};
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${isMobile ? 0 : '0 50px'};
`;

export const SubHeadingWhiteText = styled.span`
  font-size: 20px;
  color: white;
  text-align: center;
`;

export const SubHeadingYellowText = styled(SubHeadingWhiteText)`
  color: ${theme.backgrounds.main};
  font-weight: bold;
`;

const InfoVideoContainer = styled.div`
  display: flex;
  min-height: 300px;
  padding: ${isMobile ? '10px 0' : '10px 40px'};
  justify-content: center;
  align-items: center;
  margin-top: ${isMobile ? 0 : '20px'};
`;

export const InfoBox = styled.div`
  flex: 1;
  background: transparent;
  min-height: 100%;
  border-radius: 5px;
  padding: ${isMobile ? '5px 0' : '0 10px'};
`;

export const InfoAnalyticsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const InfoAnalyticsBoxContainer = styled.div`
  padding: 0 10px;
  flex: 1;
`;

export const InfoAnalyticsBox = styled.div`
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  flex: 1;
  min-height: 80px;
  margin-bottom: 10px;
  padding: ${isMobile ? '10px' : '0 10px'};
`;

const VideoBox = styled.div`
  flex: 1;
  min-height: 100%;
`;

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 80px;
`;

export const IconTextContainerOne = styled(IconTextContainer)`
  padding: 0 10px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: 10px;
`;

export const MainNumber = styled.span`
  font-size: 25px;
  font-weight: bold;
`;

export const MainNumberYellow = styled(MainNumber)`
  color: ${theme.backgrounds.main};
`;

const BlackInfoAnalyticsContainer = styled(InfoAnalyticsContainer)`
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  padding: 10px 0;
  margin: 10px;
  flex-direction: column;
`;

const TransparentInfoAnalyticsBox = styled(InfoAnalyticsBox)`
  background-color: transparent;
`;

const Flex = styled.div`
  display: flex;
`;

export const Text = styled.span`
  font-size: 15px;
  font-weight: bold;
`;

export const ActionButtonContainer = styled.div`
  margin-top: 30px;
  padding: ${isMobile ? 0 : '0 60px'};
  margin-bottom: ${isMobile ? '30px' : 0};
`;

const EmptyDiv = styled.div`
  height: 150px;
  width: 1px;
`;

const TimerContainer = styled.div`
  background-color: ${theme.colors.main};
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;
