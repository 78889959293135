import React from 'react';
import { Col, Row } from 'antd';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile, isMobileOnly } from 'react-device-detect';
import chart from '../../assets/piechart.png';
import desktop from '../../assets/whyOurCourse.svg';
import mobile from '../../assets/whyOurCourseMob.svg';
import { Spacer } from '../../routes/about';
import HeadingUnderline from '../HeadingUnderline';
import finger from '../../assets/finger.png';
import BuyCourseButton from '../BuyCourseButton';

export const MainDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  height: 100%;
`;

export const SecondDiv = styled(MainDiv)`
  flex-direction: row;
  height: 100%;
`;

const BlackBox = styled.div`
  width: 100%;
  background-color: ${({ showWhiteBox }) => (showWhiteBox ? 'transparent' : 'rgb(0, 0, 0)')};
  border-radius: 8px;
  padding: 10px 0px;
`;

const Counter = ({ hideButton, showWhiteBox }) => (
  <Container>
    <BlackBox showWhiteBox={showWhiteBox}>
      {!hideButton && (
      <Row justify="center" className="blogs-brief">
        <Col>
          <HeadingUnderline title="Why Our Course?" whitetext />
        </Col>
      </Row>
      )}
      <Row
        justify="center"
        className="blogs-brief"
        style={{ marginBottom: isMobileOnly ? '20px' : '40px' }}
      >
        {isMobileOnly ? (
          <img
            width="100%"
            alt="why our course?"
            src={mobile}
            style={{ marginBottom: '30px' }}
          />
        ) : (
          <img
            width="100%"
            alt="why our course?"
            src={desktop}
            className="better-for-you"
          />
        )}
      </Row>
      <Row>
        <LossWhiteText>
          There’s only 1 reason to hide stock market performance
        </LossWhiteText>
      </Row>
      <Row justify="center">
        <img alt="one-reason" width="60px" src={finger} />
      </Row>
      <Row justify="center">
        <LargeRedText>LOSSES!</LargeRedText>
      </Row>
      <Spacer />
      <Row>
        <Col xs={24} sm={9} md={9} lg={9}>
          <MainDiv>
            <Text>
              Over a
              {' '}
              <BigText>3 year period</BigText>
              <br />
              <LargeText>ONLY 1%</LargeText>
              {' '}
              of traders
              <br />
              make more money than a bank
              {' '}
              <br />
              <BigText>Fixed Deposit</BigText>
            </Text>
          </MainDiv>
        </Col>
        <Col xs={24} sm={6} md={6} lg={6}>
          <ImageContainer>
            <img alt="chart" src={chart} width="200px" />
          </ImageContainer>
        </Col>
        <Col xs={24} sm={9} md={9} lg={9}>
          <SecondDiv>
            <Text>
              You
              {' '}
              <BigText>can&apos;t learn</BigText>
              <br />
              to make money in the markets from
              <br />
              <BigText>people who</BigText>
              &nbsp;
              <LargeRedText>LOSE MONEY</LargeRedText>
            </Text>
          </SecondDiv>
        </Col>
      </Row>
    </BlackBox>
    {!isMobile && !hideButton && (
      <Row
        justify="center"
        style={{ marginBottom: isMobile ? '40px' : '30px' }}
        className="mt-15"
      >
        <BuyCourseButton flatButton />
      </Row>
    )}
  </Container>
);

export default Counter;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LargeText = styled.span`
  font-size: 27px;
  color: white;
  font-weight: bold;
  color: #f9ae00;
`;

export const LargeRedText = styled(LargeText)`
  color: #e00101;
`;

export const BigText = styled.span`
  font-size: 22px;
  color: white;
  font-weight: bold;
`;

const Text = styled.p`
  color: white;
  margin-bottom: 0;
  text-align: center;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const LossWhiteText = styled.div`
  color: white;
  font-size: 25px;
  text-align: center;
  width: 100%;
  font-weight: bold;
`;
