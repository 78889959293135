import React, { useState } from 'react';
import { Row, Button, Modal } from 'antd';
import { Rating } from 'react-simple-star-rating';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import { CloseCircleOutlined } from '@ant-design/icons';
import { SET_USER_DATA, toggleStarRatingModal } from '../../actions/common';
import { axiosAuth } from '../../api';
import API_ENDPOINTS from '../../api/apiEndpoints';

const StarRatingModal = ({ hanldeAfterClose }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const showRefundOverModal = useSelector(
    (state) => state.common.showRatingModal,
  );
  const userData = useSelector((state) => state.common.user);
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');

  const handleClose = () => {
    dispatch(toggleStarRatingModal(false));
    hanldeAfterClose();
  };

  const handleSubmitClick = () => {
    if (rating === 0) {
      toast('Please select rating to proceed!!', {
        type: toast.TYPE.ERROR,
      });
      return;
    }
    if (rating < 80 && !feedback) {
      toast('Please enter feedback to proceed!!', {
        type: toast.TYPE.ERROR,
      });
      return;
    }
    setLoading(true);
    axiosAuth
      .post(API_ENDPOINTS.SUBMIT_RATING, {
        courseSlug: params?.slug,
        rating,
        review: feedback,
      })
      .then((res) => {
        if (res?.data?.status) {
          toast('Thank you for rating our course!!', {
            type: toast.TYPE.SUCCESS,
          });
          localStorage.setItem(
            'user',
            JSON.stringify({
              ...userData,
              ratings: userData?.ratings?.length
                ? [...userData.ratings, { ...res?.data?.data }]
                : [{ ...res?.data?.data }],
            }),
          );
          dispatch({
            type: SET_USER_DATA,
            payload: {
              user: {
                ...userData,
                ratings: userData?.ratings?.length
                  ? [...userData.ratings, { ...res?.data?.data }]
                  : [{ ...res?.data?.data }],
              },
            },
          });
          handleClose();
        } else {
          toast('Something went wrong. Please try again!!', {
            type: toast.TYPE.ERROR,
          });
        }
        setLoading(false);
      });
  };

  return (
    <Modal
      className="exit-intent-popup"
      visible={showRefundOverModal}
      closable={false}
      maskClosable={false}
    >
      <div style={{ padding: '0px 10px', position: 'relative' }}>
        <CloseCircleOutlined
          className="close-icon"
          onClick={handleClose}
          style={{ color: 'black', right: '-15px', top: '-25px' }}
        />
        <h4 className="text-center mt-10 leaving-already">
          <span style={{ borderBottom: '5px solid #f9ae00' }}>
            Rate our Course
          </span>
        </h4>
        <Row justify="center" className="mt-25">
          <Rating
            transition
            fillColorArray={[
              '#f17a45',
              '#f19745',
              '#f1a545',
              '#f1b345',
              '#f9ae00',
            ]}
            onClick={(newRating) => {
              if (rating === 20 && newRating < 11) {
                setRating(20);
              } else if (newRating < 11) {
                setRating(20);
              } else {
                setRating(newRating);
              }
            }}
            ratingValue={rating}
            allowHalfIcon
          />
        </Row>
        <Row justify="center" className="mt-10">
          <TextArea
            placeholder="Write your feedback here"
            rows={5}
            onChange={(e) => setFeedback(e.target.value)}
            value={feedback}
          />
        </Row>
        <Row justify="center" className="mt-10">
          <Button
            onClick={handleSubmitClick}
            loading={loading}
            disabled={loading}
            className="signUpBtn"
            size="large"
          >
            Submit
          </Button>
        </Row>
      </div>
    </Modal>
  );
};

export default StarRatingModal;
